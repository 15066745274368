import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Build } from "@material-ui/icons";
import React from "react";
import { formatDateShort } from "../../../utils/util";

const EquipmentTable = ({
  equipment = [],
  rowSelected,
  count,
  page,
  rowsPagePage,
  changePageHandler,
  changeRowsPerPageHandler,
}) => {
  return (
    <Table style={{ border: "1px solid #d3d3d3" }} size="small">
      <TableHead>
        <TableRow style={{ backgroundColor: "#d3d3d3" }}>
          <TableCell />
          <TableCell>
            <Typography>
              <strong>Name</strong>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              <strong>Unit #</strong>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              <strong>Last Checklist</strong>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              <strong>Date Created</strong>
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {equipment.map(({ id, name, unitNumber, lastChecklist, createdAt }) => {
          return (
            <EquipmentTableRow
              id={id}
              name={name}
              unitNumber={unitNumber}
              lastChecklist={lastChecklist?.createdAt}
              createdAt={createdAt}
              onViewClick={() => rowSelected(id)}
            />
          );
        })}
      </TableBody>
      {equipment?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          count={count}
          page={page}
          rowsPerPage={rowsPagePage}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
        />
      )}
    </Table>
  );
};

const EquipmentTableRow = ({
  id,
  name,
  unitNumber,
  lastChecklist,
  createdAt,
  onViewClick,
}) => {
  const viewClickHandler = () => onViewClick(id);

  return (
    <TableRow hover key={id}>
      <TableCell>
        <Build color="primary" />
      </TableCell>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{unitNumber}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{formatDateShort(lastChecklist)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{formatDateShort(createdAt)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Button color="primary" onClick={viewClickHandler}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default EquipmentTable;
