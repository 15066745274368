export default {
  checklistTypes: ["Weekly", "Monthly", "First-Aid"],
  STATE: ["In-Progress", "Submitted", "Completed"],
  FIRST_AID_TYPES: ["1-5 Workers", "6-14 Workers", "15+ Workers"],
  RESOURCE_TYPES: ["Health and Safety Policy", "Safety Talk Document", "SDS"],
  PROJECT_STATES: ["In-Progress", "Completed"],
  SAFETY_TALK_STATES: ["Awaiting Completion", "Completed"],
  WORKER_ORIENTATION: "Worker Orientation",
  INSPECTION_FINDING: "Inspection Finding",
  WEEKLY_CHECKLIST: "Weekly Checklist",
  MONTHLY_CHECKLIST: "Monthly Checklist",
  FIRST_AID_CHECKLIST: "First Aid Checklist",
  SAFETY_TALK: "Safety Talk",
  WORKER_CERTIFICATION: "Worker Certification",
  CORRECTIVE_ACTION: "Corrective Action",
  CONTRACTOR_CERTIFICATION: "Contractor Certification",

  CORRECTIVE_ACTIONS: {
    TRAINING: "Training/instruction of person involved",
    IMPROVE_PROCEDURES: "Improve work procedures",
    INFORM_STAFF_PROCEDURES: "Inform staff/managers of safe work procedures",
    PERFORM_ANALYSIS: "Perform job safety analysis",
    INFORM_STAFF_PROTECTION:
      "Inform staff/managers of hazard and how to protect themselves",
    NOTIFY_IDIVIDUALS: "Notify appropriate individuals",
    IMPROVE_DESIGN: "Improve engineering/design",
    IMPROVE_INSPECTIONS: "Improve inspection procedures",
    TOOLS_REPAIR: "Tools, equipment, furniture repair or replacement",
    REQUEST_ERGONOMIC: "Request ergonomic assessment",
    REQUEST_ENVIRONMENTAL: "Request environmental assessment",
    CORRECTION_WORK_AREA: "Correction of work area",
    RECOMMEND_DEVELOPMENT_TO_PROGRAM:
      "Recommend development/improvement to training/OH&S program",
    REASSESS_STANDARDS: "Reassess work standards",
    REASSIGN_PERSON: "Reassignment of person",
    IMPROVE_HOUSEKEEPING: "Improve housekeeping",
  },
  ERRORS: {
    DEFAULT_ERROR:
      "Something went wrong with this request. Please try again later.",
  },
};
