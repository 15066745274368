import React, { Fragment } from "react";
import Strings from "../../strings";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  TablePagination,
} from "@material-ui/core";
import { Check, HourglassFull } from "@material-ui/icons";

const InspectionChecklistsTable = (props) => {
  const checklistsCells = props.checklists.map((checklist) => {
    const createdDate = new Date(checklist.createdDate).toLocaleString();

    let stateIcon = null;
    if (checklist.state == 3) {
      stateIcon = <Check />;
    } else if (checklist.state == 2) {
      stateIcon = <HourglassFull />;
    }

    return (
      <TableRow hover key={checklist.id}>
        <TableCell component="th" scope="row">
          {checklist.name}
        </TableCell>
        <TableCell align="left">
          {Strings.checklistTypes[checklist.type - 1]}
        </TableCell>
        <TableCell align="left">{Strings.STATE[checklist.state - 1]}</TableCell>
        <TableCell align="left">{`${createdDate}`}</TableCell>
        <TableCell align="left">{checklist.assignedUser.name}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => props.onChecklistSelected(event, checklist.id)}
          >
            <Box px={2}>View</Box>
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  /*   return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Name</strong>
          </TableCell>
          <TableCell align="left">
            <strong>Type</strong>
          </TableCell>
          <TableCell align="left">
            <strong>State</strong>
          </TableCell>
          <TableCell align="left">
            <strong>Date Created</strong>
          </TableCell>
          <TableCell align="left">
            <strong>Assigned User</strong>
          </TableCell>
          <TableCell align="right">
            <strong>Actions</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{checklistsCells}</TableBody>
    </Table>
  ); */

  return (
    <Fragment>
      <Table style={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Type</strong>
            </TableCell>
            <TableCell align="left">
              <strong>State</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Date Created</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Assigned User</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{checklistsCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
    </Fragment>
  );
};

export default InspectionChecklistsTable;
