import axios from '../axios';

export const getResources = (offset, limit, params) => {
    let urlParams = new URLSearchParams();
    if (params.name) {
        urlParams.append("name", params.name);
    }
    
    for (const types of params.type) {
        urlParams.append("type", types);
    }
    //return axios.get(`/resources?offset=${offset}&limit=${limit}`);
    return axios.get(`/resources`, { params: urlParams });

}

export const getResourceById = (id) => {
    return axios.get('/resources/' + id);
}

export const editResource = (id, data) => {
    return axios.put('/resources/' + id, data);
}

export const createResource = (data) => {
    return axios.post('/resources', data);
}

export const deleteResource = (id) => {
    return axios.delete('/resources/' + id);
}