import React from "react";
import { Paper, Typography, Box, Grid, Link, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import strings from "../../../strings";
import EditButton from "../../UI/EditButton";
import DeleteButton from "../../UI/DeleteButton";
const ResourceDetail = ({resource, viewFileClick}) => {
  return (
    <Paper>
      <Box p={2}>
       

        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="caption">Name</Typography>
            <Typography variant="h6">{resource.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Type</Typography>
            <Typography variant="h6">
              {strings.RESOURCE_TYPES[resource.type - 1]}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Description</Typography>
            <Typography variant="h6">{resource.description}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Publish Date</Typography>
            <Typography variant="h6">{resource.publishDate}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Expire Date</Typography>
            <Typography variant="h6">{resource.expireDate}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">File Name</Typography>
            <Typography variant="h6">
              <Link
                href="#"
                onClick={event =>
                  viewFileClick(
                    event,
                    resource.resourceUrl,
                    resource.filename
                  )
                }
              >
                {resource.filename}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ResourceDetail;
