import React, { useState } from "react";

export const EquipmentWorkAssignmentContext = React.createContext({
  setAssignments: (assignments) => {},
  addAssignment: (assignment) => {},
  getAssignmentById: (id) => {},
  updateAssignment: (id, assignment) => {},
  workAssignments: null,
});

const EquipmentWorkAssignmentProvider = (props) => {
  const [assignments, setAssignments] = useState([]);

  const setAssignmentsHandler = (assignments) => {
    setAssignments(assignments);
  };

  const addAssignmentHandler = (assignment) => {
    setAssignments((assignments) => [...assignments, assignment]);
  };

  const getAssignmentByIdHandler = (id) => {
    const state = [...assignments];
    return state.find((assignment) => assignment.id === id);
  };

  const updateAssignmentHandler = (id, assignment) => {
    const state = [...assignments];
    state[state.findIndex((assignment) => assignment.id === id)] = {
      ...assignment,
      id,
    };
    setAssignments(state);
  };

  return (
    <EquipmentWorkAssignmentContext.Provider
      value={{
        addAssignment: addAssignmentHandler,
        setAssignments: setAssignmentsHandler,
        getAssignmentById: getAssignmentByIdHandler,
        updateAssignment: updateAssignmentHandler,
        workAssignments: assignments,
      }}
    >
      {props.children}
    </EquipmentWorkAssignmentContext.Provider>
  );
};

export default EquipmentWorkAssignmentProvider;
