import axios from "../axios";

export const getAllContractors = ({params = {}} = {}) => {
  return axios.get("/contractors", {params});
};

export const getContractorById = id => {
  return axios.get("/contractors/" + id);
};

export const createContractor = contractorData => {
  return axios.post("/contractors", {
    name: contractorData.name,
    email: contractorData.email,
    address: contractorData.address,
    officePhone: contractorData.officePhone,
    description: contractorData.description
  });
};

export const updateContractor = (id, requestData) => {
  return axios.put("/contractors/" + id, requestData);
};

export const deleteContractor = id => {
  return axios.delete("/contractors/" + id);
};
