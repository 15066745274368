import React, { useState, useContext } from "react";
import classes from "./Auth.module.css";
import { Redirect, useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import logo from "../../new_rebar_logo_transparent.png";
import { Paper, CircularProgress } from "@material-ui/core";
import { AuthContext } from "../../context/auth-context";
import { login } from "../../services";

import axios from "../../axios";

const LoginPage = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [state, setState] = useState({
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Email",
        },
        value: "",
        error: null,
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
        },
        value: "",
        error: null,

        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...state.controls,
      [controlName]: {
        ...state.controls[controlName],
        value: event.target.value,
        valid: true,
        error: null,
        touched: true,
      },
    };
    setState({ controls: updatedControls });
  };

  const forgotPasswordClickHandler = (event) => {
    event.preventDefault();
    history.push("/forgot-password");
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let hasError;
    const viewState = {
      ...state,
    };

    if (!viewState.controls.email.value) {
      viewState.controls.email.error = true;
      hasError = true;
    }

    if (!viewState.controls.password.value) {
      viewState.controls.password.error = true;
      hasError = true;
    }

    if (hasError) {
      setState(viewState);
      return;
    }

    setLoading(true);
    setError(null);

    const loginParams = {
      email: state.controls.email.value,
      password: state.controls.password.value,
    };

    login(loginParams.email, loginParams.password)
      .then((response) => {
        const { data } = response;

        if (data.type === 3) {
          setError(true);
          setLoading(false);
          return;
        }

        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        const { name, type, account, token, userId } = data;
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem(
          "userData",
          JSON.stringify({ name, type, accountName: account, userId })
        );

        authContext.login(name, type, account, token, expirationDate, userId);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response) {
          setError("Invalid login credentials");
        } else {
          setError(
            "Failed to connect to Rebar services. Please try again later"
          );
        }
      });
  };

  let authRedirect;

  if (authContext.authState) {
    authRedirect = <Redirect to="/dashboard" />;
  }

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      {authRedirect}

      <Grid item>
        <Paper>
          <Box p={2}>
            <Box justifyContent="center" display="flex" height={108}>
              <img src={logo} alt="logo" height="108" />
            </Box>

            <Typography component="h1" variant="h5">
              Rebar Login
            </Typography>
            <form className={classes.form} noValidate onSubmit={submitHandler}>
              <TextField
                id="emailTextField"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="email"
                label="Email"
                name="input"
                value={state.controls.email.value}
                error={state.controls.email.error}
                onChange={(event) => inputChangedHandler(event, "email")}
              />
              <TextField
                id="passwordTextField"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                label="Password"
                name="input"
                value={state.controls.password.value}
                error={state.controls.password.error}
                onChange={(event) => inputChangedHandler(event, "password")}
              />
              {error && (
                <Box py={1}>
                  <Typography color="error">{`${error}`}</Typography>
                </Box>
              )}
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  disabled={props.loading}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
              )}
            </form>
            <Box py={2}>
              <Link
                href="/forgot-password"
                onClick={forgotPasswordClickHandler}
              >
                Forgot Password
              </Link>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
