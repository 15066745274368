import React from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SaveButton from "../../UI/SaveButton";

const CertificationForm = ({
  create,
  certification,
  onChangedHandler,
  onFileChange,
  onCheckBoxChange,
  onOwnerTypeChange,
  onSelectOwnerClick,
  onSaveButtonClick,
  onDateChange,
}) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Box pb={1}>
              <Typography variant="h4">
                {create ? "New" : "Edit"} Certification
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id="nameTextField"
              label="Name"
              variant="outlined"
              value={certification.name.value}
              error={certification.name.error}
              onChange={(event) => onChangedHandler(event, "name")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={certification.required.value}
                  onChange={onCheckBoxChange("required")}
                  value="required"
                />
              }
              label="Required"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={certification.active.value}
                  onChange={onCheckBoxChange("active")}
                  value="active"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              required
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="Issued Date"
              value={certification.issuedDate.value}
              onChange={(date) => onDateChange(date, "issuedDate")}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              required
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="Expire Date"
              value={certification.expireDate.value}
              onChange={(date) => onDateChange(date, "expireDate")}
            />
          </Grid>
          <Grid item>
            <RadioGroup
              value={certification.owner.type}
              onChange={onOwnerTypeChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Worker" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Contractor"
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id="ownerTextField"
              label="Select Owner"
              variant="outlined"
              inputProps={{ readOnly: true }}
              onClick={onSelectOwnerClick}
              value={
                certification.owner.id
                  ? certification.owner.name
                  : "[Select Owner]"
              }
              error={certification.owner.error}
              onChange={(event) => onChangedHandler(event, "owner")}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="baseline">
              <Grid item>
                <Button variant="outlined" color="primary" component="label">
                  Upload File
                  <input
                    type="file"
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Typography color={certification.file.error ? "error" : "textPrimary"}>
                  File Selected: {certification.file.value}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <SaveButton onClick={onSaveButtonClick} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CertificationForm;
