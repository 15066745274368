import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import { createContractor } from '../../../services/contractorService';
import ContractorForm from './ContractorForm';
import CenterBox from '../../UI/CenterBox';
import { formatPhoneNumberInput } from '../../../utils/util';

const CreateContractorForm = (props) => {
  const history = useHistory();
  const [contractorData, setContractorData] = useState({
    name: {
      value: '',
      error: false,
    },
    email: {
      value: '',
    },
    country: {
      value: '',
      error: false,
    },
    state: {
      value: '',
      error: false,
    },
    city: {
      value: '',
      error: false,
    },
    address1: {
      value: '',
      error: false,
    },
    officePhone: {
      value: '',
    },
    description: {
      value: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onChangedHandler = (event, idName) => {
    const updatedState = {
      ...contractorData,
      [idName]: {
        ...contractorData.idName,
        value: event.target.value,
      },
    };

    setContractorData(updatedState);
  };

  const onSaveButtonClickHandler = () => {
    if (contractorData.name.value === '') {
      setContractorData({
        ...contractorData,
        name: {
          ...contractorData.name,
          error: true,
        },
      });

      return;
    }

    setLoading(true);

    createContractor({
      name: contractorData.name.value,
      email: contractorData.email.value,
      address: {
        address1: contractorData.address1.value,
        city: contractorData.city.value,
        addressStateId: contractorData.state.value,
        addressCountryId: contractorData.country.value,
      },
      officePhone: formatPhoneNumberInput(contractorData.officePhone.value),
      description: contractorData.description.value,
    })
      .then((response) => {
        history.push('/contractors/' + response.data.id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  let displayData;
  if (error || loading || !contractorData) {
    displayData = (
      <CenterBox>
        {error ? (
          <Typography>Error Occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  } else {
    displayData = (
      <ContractorForm
        contractorData={contractorData}
        onChangedHandler={onChangedHandler}
        loading={loading}
        onSaveButtonClickHandler={onSaveButtonClickHandler}
      />
    );
  }

  return (
    <Container maxWidth="md">
      <Box py={2}>{displayData}</Box>
    </Container>
  );
};

export default CreateContractorForm;
