import { useState } from "react";
import {
  createEquipment,
  updateEquipmentById,
} from "../../../services/equipmentService";
import strings from "../../../strings";

const useEquipmentForm = (equipmentState) => {
  const [equipment, setEquipment] = useState({
    id: equipmentState?.id,
    name: {
      value: equipmentState?.name || "",
      error: null,
    },
    unitNumber: {
      value: equipmentState?.unitNumber || "",
      error: null,
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async () => {
    const state = { ...equipment };

    const payload = {
      name: state.name.value,
      unitNumber: state.unitNumber.value,
    };

    let response;

    try {
      setLoading(true);
      setError(false);

      if (state.id == null) {
        response = await createEquipment(payload);
      } else {
        response = await updateEquipmentById(state.id, payload);
      }
      return response.data;
    } catch (err) {
      setError(strings.ERRORS.DEFAULT_ERROR);
      setLoading(false);
    }
  };

  const updateEquipmentForm = (id, value) => {
    setEquipment((equipment) => ({
      ...equipment,
      [id]: {
        value,
      },
    }));
  };

  const submitForm = async () => {
    // Validate form
    let hasError = false;
    const state = { ...equipment };
    if (!state.name.value) state.name.error = hasError = true;

    if (hasError) {
      setEquipment(state);
      return;
    }
    // Send Request
    return sendRequest();
  };

  return { error, loading, equipment, updateEquipmentForm, submitForm };
};

export default useEquipmentForm;
