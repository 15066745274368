import React, { useState } from "react";
import {
  Container,
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { makePasswordReset } from "../../../services/authService";
import { useHistory } from "react-router-dom";

const PasswordReset = (props) => {
  const history = useHistory();
  const [textState, setTextState] = useState({
    password: {
      value: "",
      error: null,
    },
    confirmPassword: {
      value: "",
      error: null,
    },
  });
  const [viewState, setViewState] = useState({
    loading: false,
    error: null,
    success: false,
    validToken: false,
  });

  const textChangeHandler = (event, id) => {
    const updatedState = {
      ...textState,
      [id]: {
        value: event.target.value,
      },
    };

    setTextState(updatedState);
  };

  const viewStateChange = (update) => {
    const updatedState = {
      ...viewState,
      ...update,
    };

    setViewState(updatedState);
  };

  const submitButtonClickHandler = () => {
    let hasError;
    const currentState = {
      ...textState,
    };
    viewStateChange({ error: false });

    if (!currentState.password.value) {
      currentState.password.error = true;
      hasError = true;
    }

    if (!currentState.confirmPassword.value) {
      currentState.confirmPassword.error = true;
      hasError = true;
    }

    if (hasError) {
      setTextState(currentState);
      return;
    }

    viewStateChange({ loading: true });

    const params = new URLSearchParams(window.location.search);

    const password = currentState.password.value;
    const confirmPassword = currentState.confirmPassword.value;
    const token = params.get("token");

    makePasswordReset({ token, password, confirmPassword })
      .then(() => {
        viewStateChange({ loading: false, success: true });
      })
      .catch((err) => {
        viewStateChange({ loading: false, error: err });
      });
  };

  return (
    <Container maxWidth="md">
      <Box
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={1}
      >
        <Box width="100%">
          <Paper>
            <Box px={8} py={8}>
              <Grid
                container
                direction="column"
                spacing={1}
                alignItems="stretch"
              >
                <Grid item>
                  <Typography variant="h4">
                    <strong>Password Reset</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    color="primary"
                    type="password"
                    label="New Password"
                    value={textState.password.value}
                    error={textState.password.error}
                    onChange={(event) => textChangeHandler(event, "password")}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    color="primary"
                    label="Confirm Password"
                    value={textState.confirmPassword.value}
                    error={textState.confirmPassword.error}
                    onChange={(event) =>
                      textChangeHandler(event, "confirmPassword")
                    }
                  />
                  {(viewState.success || viewState.error) && (
                    <Box py={2}>
                      {viewState.error ? (
                        <Typography color="error">
                          Error occurred changing password
                        </Typography>
                      ) : (
                        <Typography color="primary">
                          Password changed successfully!
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  {viewState.loading ? (
                    <CircularProgress />
                  ) : (
                    <Box hidden={viewState.success}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={submitButtonClickHandler}
                      >
                        Change Password
                      </Button>
                    </Box>
                  )}
                  <Box pt={2} display="flex" justifyContent="center">
                    <Link
                      href="login"
                      onClick={(event) => {
                        event.preventDefault();
                        history.replace("/login");
                      }}
                    >
                      Go to Login Page
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordReset;
