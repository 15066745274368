import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getCertifications } from "../../services/certificationsService";
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress,
  Grid,
  TextField,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import CertificationsTable from "../../components/Certifications/CertificationsTable";
import NewButton from "../../components/UI/NewButton";
import CenterBox from "../../components/UI/CenterBox";
import { Search } from "@material-ui/icons";

const CertificationsScreen = (props) => {
  const history = useHistory();

  const onCreateCertificationClickHandler = () => {
    history.push("/certifications/create");
  };

  /*  const onCertificationSelectedHandler = (event, id) => {
    history.push("/certifications/" + id);
  }; */

  /*   useEffect(() => {
    setError(false);
    setLoading(true);
    getCertifications()
      .then((response) => {
        setLoading(false);
        setCertifications(response.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, []); */

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold">Certifications</Box>
              </Typography>
            </Grid>
            <Grid item>
              <NewButton onClick={onCreateCertificationClickHandler}>
                New Certification
              </NewButton>
            </Grid>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <CertificationsPaper />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/*    <Box pb={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateCertificationClickHandler}
          >
            New
          </Button>
        </Box>
        <Paper>
          <CertificationsTable
            certifications={certifications}
            onRowSelected={onCertificationSelectedHandler}
          />
        </Paper> */}
    </Container>
  );
};

const CertificationsPaper = (props) => {
  const history = useHistory();
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // State of the query view
  const [searchParams, setSearchParams] = useState({
    name: "",
  });

  // State of the current API query
  const [queryData, setQueryData] = useState({
    params: {
      name: "",
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const onCertificationSelectedHandler = (event, id) => {
    history.push("/certifications/" + id);
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const nameSearchQueryChangeHandler = (event) => {
    const updatedSearchParams = {
      ...searchParams,
      name: event.target.value,
    };

    setSearchParams(updatedSearchParams);
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const onSearchButtonClickHandler = (event) => {
    const updatedQueryData = { ...queryData };
    const params = {};

    if (searchParams.name !== "") {
      params.name = searchParams.name;
    }

    params.limit = rowsPerPage;
    params.offset = 0;

    updatedQueryData.params = params;

    setQueryData(updatedQueryData);
    setPage(0);
  };

  const onClearSearchHandler = () => {
    const updatedPage = 0;
    const updatedQueryData = {
      params: {
        name: "",
        limit: rowsPerPage,
        offset: rowsPerPage * updatedPage,
      },
    };
    const updatedSearchParams = {
      name: "",
    };

    setSearchParams(updatedSearchParams);
    setQueryData(updatedQueryData);
    setPage(updatedPage);
  };

  useEffect(() => {
    setError(false);
    setLoading(true);
    console.log(queryData.params);
    getCertifications(queryData.params)
      .then((response) => {
        setCertifications(response.data.certifications);
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [queryData]);

  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                type="search"
                label="Search Certifications"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" c>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={nameSearchQueryChangeHandler}
                value={searchParams.name}
              />
            </Grid>
            {queryData.params.name && (
              <Grid item>
                <Chip
                  label={`Search: '${queryData.params.name}'`}
                  variant="outlined"
                  color="primary"
                  onDelete={onClearSearchHandler}
                />
              </Grid>
            )}
          </Grid>

          <Grid item>
            <Box py={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onSearchButtonClickHandler}
              >
                <Box px={2}>Search</Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid item>
        {loading ? (
          <CenterBox pb={8}>
            <CircularProgress />
          </CenterBox>
        ) : (
          <CertificationsTable
            certifications={certifications}
            onRowSelected={onCertificationSelectedHandler}
            total={total}
            rowsPerPage={rowsPerPage}
            page={page}
            pageChangeHandler={pageChangeHandler}
            rowsPerPageChangeHandler={rowsPerPageChangeHandler}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default CertificationsScreen;
