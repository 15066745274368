import React from "react";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import ProjectsTable from "../../Project/ProjectsTable";

const EmployeeProjects = ({ projects }) => {
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h5">Project History</Typography>
      </Box>
      <ProjectsTable projects={projects} />
    </Paper>
  );
};

export default EmployeeProjects;
