import axios from "../axios";

export const getAllEmergencyNumbers = () => {
  return axios.get("/emergency-numbers");
};

export const getProjectENumberForm = (projectId) => {
  return axios.get("/projects/" + projectId + "/emergency-numbers");

}

export const getENumberById = id => {
  return axios.get("/emergency-numbers/" + id);
};

export const getENumbersOnProject = projectId => {
  return axios.get(`/projects/${projectId}/emergency-numbers`);
};

export const postEmergencyNumber = entity => {
  return axios.post("/emergency-numbers", entity);
};

export const putEmergencyNumber = (entity, id) => {
  return axios.put("/emergency-numbers/" + id, entity);
};

export const deleteEmergencyNumberById = id => {
  return axios.delete("/emergency-numbers/" + id);
};

export const getEmergencyNumbersFormTemplate = () => {
  return axios.get("/emergency-numbers/template");
};

export const getHospitals = () => {
  return axios.get("/emergency-numbers/hospitals");
};

export const createHospital = (hospital) => {
  return axios.post("/emergency-numbers/hospitals", hospital);
};

export const updateHospital = (id, hospital) => {
  return axios.put("/emergency-numbers/hospitals/" + id , hospital);
};

export const downloadFormPDF = projectId => {
  return axios.get(`/projects/${projectId}/emergency-numbers/pdf`, {
    responseType: "blob"
  });
};
