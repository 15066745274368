import React from "react";
import { MenuItem } from "@material-ui/core";
import CustomOutlinedSelect from "./CustomOutlinedSelect";

const menuItemsData = [
  {
    id: 1,
    name: "Canada",
    ISO: "CA",
    ISO3: "CAN"
  },
//   {
//     id: 2,
//     name: "United States",
//     ISO: "US",
//     ISO3: "USA"
//   }
];

const menuItems = menuItemsData.map(item => {
  return (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  );
});
const CountrySelect = ({ fullWidth, error, onChange, selected, required }) => {
  return (
    <CustomOutlinedSelect
      label="Country"
      fullWidth={fullWidth}
      required={required}
      error={error}
      onChange={onChange}
      selected={selected}
      menuItems={menuItems}
    />
  );
};

export default CountrySelect;
