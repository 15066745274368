import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  TextField,
  DialogTitle,
} from "@material-ui/core";
import {
  getAllAccounts,
  createAccount,
} from "../../../services/superAdminService";
import { useHistory } from "react-router-dom";
import { formatToDisplayDate } from "../../../utils/util";
import axios from "../../../axios";
import { AuthContext } from "../../../context/auth-context";

const Accounts = (props) => {
  const [accounts, setAccounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const openDialogClickHandler = () => {
    setCreateDialogOpen(true);
  };

  const closeDialogHandler = (update) => {
    setCreateDialogOpen(false);

    if (update === true) {
      const updateToken = refresh + 1;
      setRefresh(updateToken);
    }
  };

  const logoutClickHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("userData");
    axios.defaults.headers.common["Authorization"] = "";
    authContext.logout();
    history.push("/super-admin/login");
  };
  useEffect(() => {
    setLoading(true);

    getAllAccounts().then((response) => {
      setAccounts(response.data);
      setLoading(false);
    });
  }, [refresh]);

  var view;

  if (!loading && accounts) {
    view = (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3">Accounts</Typography>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={openDialogClickHandler}
            >
              Create New Account
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={logoutClickHandler}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Paper>
            <Box py={2}>
              <AccountsTable accounts={accounts}></AccountsTable>
            </Box>
          </Paper>
        </Grid>
        <CreateAccountDialog
          open={createDialogOpen}
          onClose={closeDialogHandler}
        />
      </Grid>
    );
  } else {
    view = <CircularProgress />;
  }

  return <Container maxWidth="md">{view}</Container>;
};

const AccountsTable = ({ accounts }) => {
  const history = useHistory();

  const onViewAccountClickHandler = (id) => {
    history.push(`/super-admin/accounts/${id}`);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Type</TableCell>
          <TableCell align="right">Created</TableCell>
          <TableCell align="right">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accounts.map((account) => (
          <TableRow key={account.id}>
            <TableCell component="th" scope="row">
              {account.accountName}
            </TableCell>
            <TableCell align="right">
              {account.active ? "ACTIVE" : "DISABLED"}
            </TableCell>
            <TableCell align="right">
              {account.type === 1 ? "Regular" : ""}
            </TableCell>
            <TableCell align="right">
              {formatToDisplayDate(account.createdAt)}
            </TableCell>
            <TableCell align="right">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onViewAccountClickHandler(account.id)}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const CreateAccountDialog = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [fieldsState, setFieldsState] = useState({
    accountName: {
      value: "",
      error: null,
    },

    firstName: {
      value: "",
      error: null,
    },
    lastName: {
      value: "",
      error: null,
    },
    email: {
      value: "",
      error: null,
      helperText: null,
    },
    title: {
      value: "",
      error: null,
    },
    password: {
      value: "",
      error: null,
    },
  });

  const textFieldsChangeHandler = (event, id) => {
    const updatedState = {
      ...fieldsState,
      [id]: {
        ...fieldsState[id],
        value: event.target.value,
        error: null,
      },
    };

    setFieldsState(updatedState);
  };

  const submitFormHandler = (event) => {
    let hasError = false;
    const currentState = {
      ...fieldsState,
    };

    if (!currentState.accountName.value) {
      currentState.accountName.error = true;
      hasError = true;
    }

    if (!currentState.firstName.value) {
      currentState.firstName.error = true;
      hasError = true;
    }

    if (!currentState.lastName.value) {
      currentState.lastName.error = true;
      hasError = true;
    }
    if (!currentState.email.value) {
      currentState.email.error = true;
      hasError = true;
    }

    if (!currentState.title.value) {
      currentState.title.error = true;
      hasError = true;
    }

    if (!currentState.password.value) {
      currentState.password.error = true;
      hasError = true;
    }

    if (hasError) {
      setFieldsState(currentState);
      return;
    }

    setLoading(true);

    const accountRequest = {
      account: {
        accountName: currentState.accountName.value,
        active: 1,
        type: 1,
      },
      user: {
        email: currentState.email.value,
        password: currentState.password.value,
        firstName: currentState.firstName.value,
        lastName: currentState.lastName.value,
        type: 1,
        title: currentState.title.value,
      },
    };

    createAccount(accountRequest)
      .then((response) => {
        onClose(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 409) {
          currentState.email.error = true;
          currentState.email.helperText = "Invalid email, already in use";
          setFieldsState(currentState);
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create Account</DialogTitle>
      <Box p={2}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField
              label="Account Name"
              variant="outlined"
              required
              value={fieldsState.accountName.value}
              error={fieldsState.accountName.error}
              onChange={(event) =>
                textFieldsChangeHandler(event, "accountName")
              }
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography>Admin User</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="First name"
              variant="outlined"
              required
              value={fieldsState.firstName.value}
              error={fieldsState.firstName.error}
              onChange={(event) => textFieldsChangeHandler(event, "firstName")}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Last name"
              variant="outlined"
              required
              value={fieldsState.lastName.value}
              error={fieldsState.lastName.error}
              onChange={(event) => textFieldsChangeHandler(event, "lastName")}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              variant="outlined"
              required
              value={fieldsState.email.value}
              error={fieldsState.email.error}
              helperText={fieldsState.email.helperText}
              onChange={(event) => textFieldsChangeHandler(event, "email")}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              variant="outlined"
              required
              value={fieldsState.title.value}
              error={fieldsState.title.error}
              onChange={(event) => textFieldsChangeHandler(event, "title")}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Password"
              variant="outlined"
              required
              value={fieldsState.password.value}
              error={fieldsState.password.error}
              onChange={(event) => textFieldsChangeHandler(event, "password")}
              fullWidth
            />
          </Grid>
          <Grid item>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={submitFormHandler}
              >
                Create
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default Accounts;
