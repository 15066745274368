import React, { useCallback, useEffect, useState } from "react";
import { getEquipmentChecklistsOnEquipment } from "../../../../../../services/equipmentService";
import strings from "../../../../../../strings";

const useEquipmentChecklistsTable = (equipmentId) => {
  const [equipmentChecklists, setEquipmentChecklists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    offset: 0,
    limit: 10,
  });

  const getChecklistsRequest = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await getEquipmentChecklistsOnEquipment(
        equipmentId,
        query
      );

      setCount(data.total);
      setEquipmentChecklists(data.equipmentChecklists);
      setLoading(false);
    } catch (err) {
      setError(strings.ERRORS.DEFAULT_ERROR);
      setLoading(false);
    }
  }, [query, equipmentId]);

  useEffect(() => {
    getChecklistsRequest();
  }, [getChecklistsRequest, query]);

  const changePage = (page) => {
    setPage(page);
    setQuery((query) => ({
      ...query,
      offset: page * rowsPerPage,
    }));
  };

  const updateRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setQuery((query) => ({
      ...query,
      limit: rowsPerPage,
    }));
  };

  return {
    equipmentChecklists,
    loading,
    error,
    count,
    page,
    rowsPerPage,
    updateRowsPerPage,
    changePage,
  };
};

export default useEquipmentChecklistsTable;
