import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CenterBox from "../../../components/UI/CenterBox";
import LoadingBackdrop from "../../../components/UI/LoadingBackdrop";
import MainViewContainer from "../../../hoc/MainViewContainer";
import { getEquipmentChecklistsOnEquipment } from "../../../services/equipmentService";
import EquipmentFormDialog from "../components/EquipmentFormDialog";
import ChecklistsPaper from "./components/ChecklistsPaper";
import CustomBreadcrumbs from "./components/CustomBreadcrumbs";
import DeleteEquipmentDialog from "./components/DeleteEquipmentDialog/DeleteEquipmentDialog";
import DetailsPaper from "./components/DetailsPaper";
import useEquipmentDetails from "./hooks/useEquipmentDetails";

const EquipmentDetails = () => {
  const { id } = useParams();
  const { loading, error, equipment, refresh } = useEquipmentDetails(id);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const history = useHistory();

  const deleteDialogCloseHandler = (result) => {
    if (result) return history.push("/equipment");
    setDeleteDialogOpen(false);
  };

  const editDialogCloseHandler = (result) => {
    setEditDialogOpen(false);

    if (result) {
      refresh();
    }
  };

  const deleteButtonClickHandler = () => {
    setDeleteDialogOpen(true);
  };

  const editButtonClickHandler = () => {
    setEditDialogOpen(true);
  };

  const memoizedChecklistsPaper = useMemo(() => {
    return <ChecklistsPaper equipment={equipment} />;
  }, [equipment]);

  return (
    <MainViewContainer>
      {deleteDialogOpen && (
        <DeleteEquipmentDialog
          id={id}
          open={deleteDialogOpen}
          onClose={deleteDialogCloseHandler}
        />
      )}
      {editDialogOpen && (
        <EquipmentFormDialog
          equipmentState={equipment}
          open={editDialogOpen}
          onCloseHandler={editDialogCloseHandler}
        />
      )}
      {loading && (
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      )}
      {error && (
        <CenterBox>
          <Typography>{error}</Typography>
        </CenterBox>
      )}
      {!loading && !error && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomBreadcrumbs name={equipment.name} />
          </Grid>
          <Grid item>
            <DetailsPaper
              onEditClick={editButtonClickHandler}
              onDeleteClick={deleteButtonClickHandler}
              equipment={equipment}
            />
          </Grid>
          <Grid item>{memoizedChecklistsPaper}</Grid>
        </Grid>
      )}
    </MainViewContainer>
  );
};

export default EquipmentDetails;
