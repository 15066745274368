import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import CenterBox from "../../../components/UI/CenterBox";
import MainViewContainer from "../../../hoc/MainViewContainer";
import DeleteEquipmentChecklistDialog from "../components/DeleteEquipmentChecklistDialog";
import DetailsPaper from "../components/DetailsPaper";
import DetailsTab from "../components/DetailsTab";
import useEquipmentChecklistDetails from "./hooks/useEquipmentChecklistDetails";

const EquipmentChecklistDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, equipmentChecklist } = useEquipmentChecklistDetails(
    id
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const deleteButtonClickHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteEquipmentDialogCloseHandler = (result) => {
    if (result) {
      history.replace(`/equipment/${equipmentChecklist.equipment.id}`);
      return;
    }
    setDeleteDialogOpen(false);
  };

  return (
    <MainViewContainer>
      {deleteDialogOpen && (
        <DeleteEquipmentChecklistDialog
          id={id}
          open={deleteDialogOpen}
          onClose={deleteEquipmentDialogCloseHandler}
        />
      )}
      <Grid
        style={{ flexWrap: "nowrap" }}
        container
        direction="column"
        spacing={3}
      >
        <Grid item>
          <Typography variant="h5">
            <strong>Equipment/Vehicle Pre-Start Checklist</strong>
          </Typography>
        </Grid>
        {loading && (
          <CenterBox>
            <CircularProgress />
          </CenterBox>
        )}
        {!loading && error && (
          <CenterBox>
            <Typography>{error}</Typography>
          </CenterBox>
        )}
        {!loading && !error && equipmentChecklist && (
          <Fragment>
            <Grid item>
              <DetailsPaper
                checklist={equipmentChecklist}
                onDeleteClick={deleteButtonClickHandler}
              />
            </Grid>

            <Grid item>
              <DetailsTab checklist={equipmentChecklist} />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </MainViewContainer>
  );
};

export default EquipmentChecklistDetails;
