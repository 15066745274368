import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { getContractorById } from '../../../services/contractorService';
import ContractorForm from './ContractorForm';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { updateContractor } from '../../../services/contractorService';
import CenterBox from '../../UI/CenterBox';
import { formatPhoneNumberInput } from '../../../utils/util';

const EditContractor = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [contractorData, setContractorData] = useState({
    name: {
      value: '',
      error: false,
    },
    email: {
      value: '',
    },
    country: {
      value: '',
      error: false,
    },
    state: {
      value: '',
      error: false,
    },
    city: {
      value: '',
      error: false,
    },
    address1: {
      value: '',
      error: false,
    },
    officePhone: {
      value: '',
    },
    description: {
      value: '',
    },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    getContractorById(id)
      .then((response) => {
        const addressJSON = response.data.address;
        const updatedContractorData = {
          ...contractorData,
          name: {
            value: response.data.name,
          },
          email: {
            value: response.data.email,
          },
          country: {
            value: addressJSON.country ? addressJSON.country.id : '' || '',
            error: false,
          },
          state: {
            value: addressJSON.state ? addressJSON.state.id : '' || '',
            error: false,
          },
          city: {
            value: addressJSON.city || '',
            error: false,
          },
          address1: {
            value: addressJSON.address1 || '',
            error: false,
          },
          officePhone: {
            value: response.data.officePhone,
          },
          description: {
            value: response.data.description,
          },
        };
        setContractorData(updatedContractorData);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const onChangedHandler = (event, idName) => {
    const updatedState = {
      ...contractorData,
      [idName]: {
        ...contractorData.idName,
        value: event.target.value,
      },
    };

    setContractorData(updatedState);
  };

  const onSaveButtonClickHandler = () => {
    if (contractorData.name.value === '') {
      setContractorData({
        ...contractorData,
        name: {
          ...contractorData.name,
          error: true,
        },
      });

      return;
    }

    setLoading(true);

    updateContractor(id, {
      name: contractorData.name.value,
      email: contractorData.email.value,
      address: {
        address1: contractorData.address1.value,
        city: contractorData.city.value,
        addressStateId: contractorData.state.value,
        addressCountryId: contractorData.country.value,
      },
      officePhone: formatPhoneNumberInput(contractorData.officePhone.value),
      description: contractorData.description.value,
    })
      .then((response) => {
        history.push('/contractors/' + id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  let displayData;
  if (error || loading || !contractorData) {
    displayData = (
      <CenterBox>
        {error ? (
          <Typography>Error Occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  } else {
    displayData = (
      <ContractorForm
        edit
        contractorData={contractorData}
        onChangedHandler={onChangedHandler}
        loading={loading}
        onSaveButtonClickHandler={onSaveButtonClickHandler}
      />
    );
  }

  return (
    <Container maxWidth="md">
      <Box py={2}>{displayData}</Box>
    </Container>
  );
};

export default EditContractor;
