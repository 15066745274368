import React from "react";
import CustomTable from "../UI/CustomTable";
import strings from "../../strings";

const ProjectsTable = props => {
  const tableHead = [
    "Project Name",
    "Start Date",
    "End Date",
    "Client Name",
    "State"
  ];
  const tableBody = props.projects.map(project => {
    return {
      fields: [
        project.name,
        project.startDate,
        project.endDate,
        project.clientName,
        strings.PROJECT_STATES[project.state - 1]
      ],
      key: project.id,
      onSelected: event => {}
    };
  });

  return <CustomTable tableHead={tableHead} tableBody={tableBody} />;
};

export default ProjectsTable;
