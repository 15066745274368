import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Check, HourglassFull } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDateShort } from "../../../utils/util";

const WorkAssignmentsTable = ({ workAssignments = [] }) => {
  return (
    <Table size="small" style={{ border: "1px solid #d3d3d3" }}>
      <TableHeader />
      <WorkAssignmentsTableBody workAssignments={workAssignments} />
    </Table>
  );
};

const TableHeader = () => {
  return (
    <TableHead style={{ backgroundColor: "#d3d3d3" }}>
      <TableRow>
        <TableCell width="5%" />
        <TableCell>
          <Typography>
            <strong>Work Required</strong>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            <strong>Assigned To</strong>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            <strong>Date Created</strong>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            <strong>Date Completed</strong>
          </Typography>
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

const WorkAssignmentsTableBody = ({ workAssignments }) => {
  const history = useHistory();
  const viewClickHandler = (event, id) => {
    event.preventDefault();
    history.push(`/equipment-checklist-work-assignments/${id}`);
  };

  return (
    <TableBody>
      {workAssignments?.map((assignment) => {
        return (
          <TableRow hover key={assignment.id}>
            <TableCell>
              {assignment.completedAt ? (
                <Check style={{ color: "#46B83D" }} />
              ) : (
                <HourglassFull style={{ color: "#EE7302" }} />
              )}
            </TableCell>
            <TableCell>
              <Typography>{assignment.workRequired}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{assignment.assignedTo}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatDateShort(assignment.createdAt)}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatDateShort(assignment.completedAt)}</Typography>
            </TableCell>
            <TableCell align="right">
              <Box py={1}>
                <Link
                  href={`/equipment-checklist-work-assignments/${assignment.id}`}
                  onClick={(event) => viewClickHandler(event, assignment.id)}
                >
                  <Typography>
                    <strong>View</strong>
                  </Typography>
                </Link>
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default WorkAssignmentsTable;
