import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  getCertificationById,
  createCertification,
  editCertification,
} from "../../../services/certificationsService";
import {
  Container,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import CertificationForm from "../../../components/Certifications/Certification/CertificationForm";

import { getAllWorkers } from "../../../services/workerService";
import { getAllContractors } from "../../../services/contractorService";
import SelectWorkersDialog from "../../../components/Workers/SelectWorkersDialog";
import SelectContractorsDialog from "../../Contractors/SelectContractorsDialog";
import { dateToISOStringNoTime } from "../../../utils/util";

const CertificationFormScreen = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [certification, setCertification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [contractorsDialogOpenState, setContractorsDialogOpenState] = useState(
    false
  );
  const [contractors, setContractors] = useState(null);
  const [workers, setWorkers] = useState(null);

  const onFileSelectedHandler = (event) => {
    console.log(event.target.files[0]);
    const updatedData = {
      ...certification,
      file: {
        value: event.target.files[0].name,
        data: event.target.files[0],
      },
    };

    setCertification(updatedData);
  };

  const onFormChangedHandler = (event, id) => {
    const updatedData = {
      ...certification,
      [id]: {
        ...certification[id],
        value: event.target.value,
        error: false,
      },
    };

    setCertification(updatedData);
  };

  const onDateChangeHandler = (date, id) => {
    const updatedCertificationData = {
      ...certification,
      [id]: {
        value: date,
      },
    };

    setCertification(updatedCertificationData);
  };

  const onFormCheckBoxChangedHandler = (id) => (event) => {
    const updatedState = {
      ...certification,
      [id]: {
        value: event.target.checked,
      },
    };

    setCertification(updatedState);
  };

  const onOwnerTypeChangedHandler = (event) => {
    const updatedState = {
      ...certification,
      owner: {
        //...certification.owner,
        type: event.target.value,
      },
    };

    setCertification(updatedState);
  };

  const onSelectOwnerClickHandler = () => {
    if (certification.owner.type == 1) {
      setDialogOpen(true);
      getAllWorkers().then((response) => {
        setWorkers(response.data.workers);
      });
    } else {
      setContractorsDialogOpenState(true);
      getAllContractors().then((response) => {
        setContractors(response.data.contractors);
      });
    }
  };

  const onContractorSelectedHandler = (contractor) => {
    const updatedCertState = {
      ...certification,
      owner: {
        ...certification.owner,
        error: false,
        id: contractor.id,
        name: contractor.name,
      },
    };

    setCertification(updatedCertState);
    setContractorsDialogOpenState(false);
  };

  const onWorkerSelectedHandler = (event, worker) => {
    const updatedCertState = {
      ...certification,
      owner: {
        ...certification.owner,
        error: false,
        id: worker.id,
        name: `${worker.firstName} ${worker.lastName}`,
      },
    };

    setCertification(updatedCertState);
    setDialogOpen(false);
  };

  const onDialogCloseHandler = () => {
    setDialogOpen(false);
    setContractorsDialogOpenState(false);
  };

  const onSaveClickHandler = () => {
    // Check inputs
    let hasError = false;
    const updatedCertState = {
      ...certification,
    };

    if (!certification.name.value) {
      updatedCertState.name.error = true;
      hasError = true;
    }

    if (!certification.owner.type) {
      hasError = true;
    }

    if (!certification.owner.id) {
      updatedCertState.owner.error = true;
      hasError = true;
    }

    if (
      !certification.file.value ||
      certification.file.value === "FILE REQUIRED"
    ) {
      updatedCertState.file.value = "FILE REQUIRED";
      updatedCertState.file.error = true;
      hasError = true;
    }

    setCertification(updatedCertState);
    console.log(hasError);
    if (hasError) return;

    setLoading(true);
    setError(null);

    const certEntityForm = new FormData();
    certEntityForm.append("name", certification.name.value);
    certEntityForm.append("required", certification.required.value);
    certEntityForm.append("active", certification.active.value);
    certEntityForm.append(
      "issuedDate",
      dateToISOStringNoTime(new Date(certification.issuedDate.value))
    );
    certEntityForm.append(
      "expireDate",
      dateToISOStringNoTime(new Date(certification.expireDate.value))
    );
    certEntityForm.append("ownerType", certification.owner.type);
    certEntityForm.append("ownerId", certification.owner.id);
    certEntityForm.append("certification-file", certification.file.data);

    if (!id) {
      createCertification(certEntityForm)
        .then((response) => {
          history.push("/certifications/" + response.data.id);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else {
      editCertification(id, certEntityForm)
        .then((response) => {
          history.push("/certifications/" + response.data.id);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!id) {
      const todaysDate = new Date();

      setCertification({
        name: {
          value: "",
          error: false,
        },
        required: {
          value: false,
        },
        active: {
          value: false,
        },
        issuedDate: {
          value: todaysDate,
          error: false,
        },
        expireDate: {
          value: todaysDate,
          error: false,
        },
        owner: {
          type: "1",
          id: "",
          name: "",
          error: false,
        },
        file: {
          value: "",
          data: null,
          error: false,
        },
      });
    } else {
      setLoading(true);
      setError(null);

      getCertificationById(id)
        .then((response) => {
          const issuedDate = response.data.issuedDate;
          const expireDate = response.data.expireDate;

          const ownerType = response.data.owner
            ? response.data.owner.type === 1
              ? "1"
              : "2"
            : "1";

          setCertification({
            name: {
              value: response.data.name,
              error: false,
            },
            required: {
              value: response.data.required,
              error: false,
            },
            active: {
              value: response.data.active,
            },
            issuedDate: {
              value: response.data.issuedDate || new Date(),
              error: false,
            },
            expireDate: {
              value: response.data.expireDate || new Date(),
              error: false,
            },
            owner: {
              ...response.data.owner,
              type: ownerType,
            },
            file: {
              value: response.data.filename,
              data: null,
              error: false,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, []);

  if (error || loading || !certification) {
    return (
      <Box marginTop={16} display="flex" justifyContent="center">
        {error ? (
          <Typography>Error occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <SelectWorkersDialog
        open={dialogOpen}
        onClose={onDialogCloseHandler}
        onWorkerSelected={onWorkerSelectedHandler}
        workersList={workers}
        single
      />
      <SelectContractorsDialog
        open={contractorsDialogOpenState}
        onClose={onDialogCloseHandler}
        onContractorSelectedHandler={onContractorSelectedHandler}
        contractorsList={contractors}
      />
      <Box py={2}>
        <CertificationForm
          create={!id}
          certification={certification}
          onChangedHandler={onFormChangedHandler}
          onFileChange={onFileSelectedHandler}
          onCheckBoxChange={onFormCheckBoxChangedHandler}
          onOwnerTypeChange={onOwnerTypeChangedHandler}
          onSelectOwnerClick={onSelectOwnerClickHandler}
          onSaveButtonClick={onSaveClickHandler}
          onDateChange={onDateChangeHandler}
        />
      </Box>
    </Container>
  );
};

export default CertificationFormScreen;
