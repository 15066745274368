import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Container, Typography, Button, makeStyles, Paper, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core';
import WorkerInfo from '../../../components/Workers/Worker/WorkerInfo';

import {deleteEmployee} from '../../../services/workerService';

class WorkerDetail extends Component {

    componentDidMount() {
        this.props.onGetWorkerDetails(this.props.match.params.id);
        console.log(this.props.match.params.id);
    }

    contractorLinkClickHandler = (event, id) => {
        this.props.history.push(`/contractors/${id}`);
    }

    onEditWorkerClickHandler = (event) => {
        this.props.history.push('/workers/' + this.props.match.params.id + '/edit');
    }

    onDeleteWorkerClickHandler = () => {
        deleteEmployee(this.props.match.params.id).then(response => {
            this.props.history.push('/workers');
        }).catch(err => {
            console.log(err);
        });
    };

    render() {
        const classes = makeStyles(theme => ({
            button: {
                margin: theme.spacing(8),
            },
            paper: {
                padding: theme.spacing(3, 2),
            }
        }));

        let workerDetailsList = null;
        let userDetails = null;
        let workerProjectsTable = null;
        let workerCertsTable = null;
        let workerName = null;
        if (!this.props.loading) {
            const worker = this.props.worker;
            workerName = (
                <Typography variant="h3" gutterBottom>
                    {`${worker.firstName} ${worker.lastName}`}
                </Typography>
            )
            workerDetailsList = (
                <WorkerInfo worker={this.props.worker}
                    onContractorClick={this.contractorLinkClickHandler}
                />
            );

            userDetails = (
                <React.Fragment>
                    <Typography variant="h5" component="h3">
                        User Info
                    </Typography>
                    <br />
                </React.Fragment>
            )

            workerProjectsTable = (
                <React.Fragment>
                    <Typography variant="h5" component="h3">
                        Projects
                    </Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Project Name</TableCell>
                                <TableCell align="right">Start Date</TableCell>
                                <TableCell align="right">End Date</TableCell>
                                <TableCell align="right">Client Name</TableCell>
                                <TableCell align="right">State</TableCell>
                            </TableRow>
                        </TableHead>
                        {(worker.projects) ? (
                            <TableBody>
                                {worker.projects.map(project => (
                                    <TableRow key={project.id}>
                                        <TableCell component="th" scope="row">
                                            {project.name}
                                        </TableCell>
                                        <TableCell align="right">{project.startDate}</TableCell>
                                        <TableCell align="right">{project.endDate}</TableCell>
                                        <TableCell align="right">{project.clientName}</TableCell>
                                        <TableCell align="right">{project.state}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : null}

                    </Table>
                </React.Fragment>

            );

            workerCertsTable = (
                <React.Fragment>
                    <Typography variant="h5" component="h3">
                        Certifications
                    </Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Certification Name</TableCell>
                                <TableCell align="right">Required</TableCell>
                                <TableCell align="right">Active</TableCell>
                                <TableCell align="right">Issued Date</TableCell>
                                <TableCell align="right">Expire Date</TableCell>
                            </TableRow>
                        </TableHead>
                        {(worker.certifications) ? (
                            <TableBody>
                                {worker.certifications.map(cert => (
                                    <TableRow key={cert.id}>
                                        <TableCell component="th" scope="row">
                                            {cert.name}
                                        </TableCell>
                                        <TableCell align="right">{cert.required}</TableCell>
                                        <TableCell align="right">{cert.active}</TableCell>
                                        <TableCell align="right">{cert.issuedDate}</TableCell>
                                        <TableCell align="right">{cert.expireDate}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : null}

                    </Table>
                </React.Fragment>

            );


        } else {
            workerDetailsList = (<CircularProgress />);
        }

        return (
            <Container maxWidth="xl">
                {`Workers`}
                {workerName}
                <Button variant="contained" color="primary" className={classes.button} onClick={this.onEditWorkerClickHandler}>
                    Edit
                </Button>
                <Button variant="contained" color="secondary" className={classes.button} onClick={this.onDeleteWorkerClickHandler}>
                    Delete
                </Button>
                <Paper className={classes.paper}>
                    {workerDetailsList}
                </Paper>
                <Box mt={4} />
                <Paper m={2}>
                    {workerProjectsTable}
                </Paper>
                <Box mt={4} />
                <Paper m={2}>
                    {workerCertsTable}
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        worker: state.worker.selectedWorker,
        loading: state.worker.loading,
        error: state.worker.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetWorkerDetails: (id) => dispatch(actions.getWorkerById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerDetail);