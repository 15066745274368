import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { PriorityHigh } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getIncidents } from "../../../services/incidentService";
import strings from "../../../strings";
import useNetworkRequest from "../../../utils/useNetworkRequest";
import { formatDateShort } from "../../../utils/util";

const IncidentsToReview = () => {
  const history = useHistory();
  const { loading, error, data, handleRequest } = useNetworkRequest(true);

  useEffect(() => {
    handleRequest(() => getIncidents({ state: "REPORTED" }));
  }, [handleRequest]);

  const cellClickHandler = (id) => history.push(`/incidents/${id}`);

  let contents;
  if (loading || error || data?.incidents.length === 0) {
    contents = (
      <Box py={2} display="flex" justifyContent="center">
        {loading && <CircularProgress />}
        {error && <Typography>{error}</Typography>}
        {!loading && !error && data?.incidents.length === 0 && (
          <Typography>No items to display.</Typography>
        )}
      </Box>
    );
  } else {
    contents = (
      <Table size="small" style={{ border: "1px solid #d3d3d3" }}>
        <TableBody>
          {data?.incidents.map((incident) => (
            <TableRow
              hover
              key={incident.id}
              style={{ height: "64px", cursor: "pointer" }}
            >
              <TableCell
                width="5"
                onClick={() => cellClickHandler(incident.id)}
              >
                <PriorityHigh fontSize="large" style={{ color: "#EE0202" }} />
              </TableCell>
              <TableCell onClick={() => cellClickHandler(incident.id)}>
                <Typography>
                  <strong>{incident.project?.name}</strong>
                </Typography>
              </TableCell>

              <TableCell
                align="right"
                onClick={() => cellClickHandler(incident.id)}
              >
                <Typography>
                  {formatDateShort(new Date(incident.incidentDate))}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return <Paper elevation={0}>{contents}</Paper>;
};

export default IncidentsToReview;
