import {
  Box,
  Button,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Check, Report } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import BasePaper from "../../../components/UI/BasePaper";
import { formatDateShort, formatToDisplayDate } from "../../../utils/util";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      tableLayout: "fixed",
    },
    "table-cell": {
      paddingLeft: "0px",
      borderBottom: "none",
    },
  })
);

const DetailsPaper = ({ checklist, onDeleteClick, onEditClick }) => {
  const classes = useStyles();
  const history = useHistory();

  const equipmentLinkClickHandler = (event, id) => {
    event.preventDefault();
    history.push(`/equipment/${id}`);
  };

  let statusField;
  if (checklist?.status === "PASSED") {
    statusField = (
      <Box display="flex" alignItems="center">
        <Check style={{ color: "#46B83D" }} />
        <Typography style={{ color: "#46B83D", paddingTop: "6px" }}>
          <strong>Passed</strong>
        </Typography>
      </Box>
    );
  } else {
    statusField = (
      <Box display="flex" alignItems="center">
        <Report style={{ color: "#EE7302" }} />
        <Typography style={{ color: "#EE7302", paddingTop: "6px" }}>
          <strong>Not Passed</strong>
        </Typography>
      </Box>
    );
  }

  const details = (
    <Table size="small" className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell width="25%" className={classes["table-cell"]}>
            <Typography>
              <strong>Equipment</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Link
              href={`/equipment/${checklist?.equipment?.id}`}
              onClick={(event) =>
                equipmentLinkClickHandler(event, checklist?.equipment?.id)
              }
            >
              <Typography>{checklist?.equipment?.name}</Typography>
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              <strong>Milage</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{checklist?.milage}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              <strong>Date Created</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{formatDateShort(checklist?.createdAt)}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              <strong>Created By</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{`${checklist?.worker?.firstName} ${checklist?.worker?.lastName}`}</Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              <strong>Status</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>{statusField}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <BasePaper>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          spacing={2}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h6">
              <strong>Details</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="secondary" onClick={onDeleteClick}>
                  Delete
                </Button>
              </Grid>
              {/*    <Grid item>
                <Button onClick={onEditClick} color="primary">
                  <strong>Edit</strong>
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          {details}
        </Grid>
      </Grid>
    </BasePaper>
  );
};

export default DetailsPaper;
