import React, { Fragment } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import strings from '../../strings';

const ProjectDetails = ({
  project,
  onViewEmergencyNumbersFormClick,
  onCreateEmergencyNumbersFormClick,
  onViewHazardAssessmentsClick,
}) => {
  return (
    <Fragment>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption">Client Name</Typography>
            <Typography variant="h6">{project.clientName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Project Number</Typography>
            <Typography variant="h6">{project.projectNumber}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Start Date</Typography>
            <Typography variant="h6">{project.startDate}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">End Date</Typography>
            <Typography variant="h6">{project.endDate}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Address</Typography>
            <Typography variant="h6">{project.address.addressLabel}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">State</Typography>
            <Typography variant="h6">
              {strings.PROJECT_STATES[project.state - 1]}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Emergency Numbers Form</Typography>
            <Grid container driection="row" spacing={2}>
              <Grid item>
                {project.emergencyNumberForm ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onViewEmergencyNumbersFormClick}
                  >
                    <Box px={2}>View</Box>
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCreateEmergencyNumbersFormClick}
                  >
                    <Box px={2}>Create</Box>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="caption">Risk Assessments</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onViewHazardAssessmentsClick}
                >
                  <Box px={2}>View</Box>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ProjectDetails;
