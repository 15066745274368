import React, { Component } from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Container, Typography, CssBaseline, Button, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";


class Users extends Component {
    
    componentDidMount() {
        this.props.onGetUsers();
    }
      
    onUserViewClickHandler = (event, id) => {
        this.props.history.push(`/users/${id}`);
    }

    render() {

        let usersCells = null;
        if (!this.props.loading && !this.props.error) {
            usersCells = this.props.users.map(user => (
                <TableRow hover key={user.id} onClick={(event) => this.onUserViewClickHandler(event, user.id)}>
                    <TableCell component="th" scope="row">
                        {`${user.firstName} ${user.lastName}`}
                    </TableCell>
                    <TableCell align="right">{user.title}</TableCell>
                    <TableCell align="right">{user.username}</TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                </TableRow>
            ));
        }

        return (
            <Container maxWidth="xl">
                <Typography variant="h2">
                    Users
                </Typography>
                <Button variant="contained" color="primary">
                    New
                </Button>
                <Paper>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Title</TableCell>
                                <TableCell align="right">Username</TableCell>
                                <TableCell align="right">Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersCells}
                        </TableBody>
                    </Table>
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.user.users,
        loading: state.user.loading,
        error: state.user.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUsers: () => dispatch(actions.getAllUsers())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);