import React from 'react';
import { Button } from '@material-ui/core';
import FileDownload from "js-file-download";


import {downloadFormPDF} from "../../../services/emergencyNumberService";

const PrintPDFButton = ({projectId}) => {

    const onButtonClick = (event) => {
        event.preventDefault();
        downloadFormPDF(projectId).then(response => {
            FileDownload(response.data, "emergency_numbers_form.pdf");
        });
    }


    return (
        <Button color="primary" variant="contained" onClick={onButtonClick}>
            Download PDF
        </Button>
    )

}

export default PrintPDFButton;