import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Paper,
  Box,
  Typography,
  Button,
  CircularProgress
} from "@material-ui/core";
import CertificationsTable from "../../Certifications/CertificationsTable";
import { getCertifications } from "../../../services/certificationsService";
import CenterBox from "../../UI/CenterBox";

const WorkerCertifications = props => {
  const history = useHistory();
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // State of the current API query
  const [queryData, setQueryData] = useState({
    params: {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const onViewCertificationHandler = (event, certId) => {
    history.push("/certifications/" + certId);
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    getCertifications({
      ownerId: props.workerId,
      ownerType: 1,
      ...queryData.params
    }).then(response => {
      setCertifications(response.data.certifications);
      setTotal(response.data.total);
      setLoading(false);
    }).catch(err => {
      setError(err);
      setLoading(false);
    });
  }, [queryData]);

  let dataDisplay;

  if (loading || error || !certifications) {
    dataDisplay = (
      <CenterBox pb={8}>
        {error ? (
          <Typography>Error Occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  } else {
    dataDisplay = (
      <CertificationsTable
        certifications={certifications}
        onRowSelected={onViewCertificationHandler}
        total={total}
        rowsPerPage={rowsPerPage}
        page={page}
        pageChangeHandler={pageChangeHandler}
        rowsPerPageChangeHandler={rowsPerPageChangeHandler}
      />
    );
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h5">Certifications</Typography>
      </Box>
      {dataDisplay}
    </Paper>
  );

 /*  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h5">Certifications</Typography>
        <Box py={1}>
          <Button variant="outlined" color="primary" disabled>
            Add / Remove Certifications
          </Button>
        </Box>
        {!loading && certificationsState ? (
          <CertificationsTable
            certifications={certificationsState}
            onRowSelected={onViewCertificationHandler}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Paper>
  ); */
};

export default WorkerCertifications;
