import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initState = {
    contractors: [],
    selectedContractor: {},
    loading: false,
    error: false
}

const reducer = (state = initState, action ) => {
    switch (action.type) {
        case actionTypes.GET_ALL_CONTRACTORS_REQUEST: return updateObject(state, { loading: true });
        case actionTypes.GET_ALL_CONTRACTORS_SUCCESS: return updateObject(state, {
            loading: false,
            contractors: action.contractors
        });
        case actionTypes.GET_CONTRACTOR_DETAILS_SUCCESS: return updateObject(state, {
            loading: false,
            selectedContractor: action.selectedContractor
        });
        case actionTypes.GET_ALL_CONTRACTORS_FAILURE: return updateObject(state, { 
            loading: false,
            error: true
        
        });
        default: return state;
    }
};

export default reducer;