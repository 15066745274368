import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import CenterBox from "../../../components/UI/CenterBox";
import { deleteEquipmentWorkAssignmentById } from "../../../services/equipmentService";
import useNetworkRequest from "../../../utils/useNetworkRequest";

const DeleteWorkAssignmentDialog = ({ id, open, onClose }) => {
  const { loading, error, data, handleRequest } = useNetworkRequest();

  const deleteButtonClickHandler = async () => {
    const result = await handleRequest(() =>
      deleteEquipmentWorkAssignmentById(id)
    );

    if (result) onClose(result);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <strong>Delete Work Assignment</strong>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this work assignment record?
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      {loading && (
        <CenterBox marginTop={2}>
          <CircularProgress />
        </CenterBox>
      )}
      <DialogActions>
        {!loading && (
          <Button
            onClick={deleteButtonClickHandler}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWorkAssignmentDialog;
