import {
  Box,
  Button,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Assignment, Build, Check, Report } from "@material-ui/icons";
import React from "react";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import CenterBox from "../../../../../components/UI/CenterBox";
import { formatDateShort } from "../../../../../utils/util";
import useEquipmentChecklistsTable from "./hooks/useEquipmentChecklistsTable";

const EquipmentChecklistsTable = ({ equipmentId }) => {
  const history = useHistory();
  const {
    loading,
    error,
    count,
    page,
    rowsPerPage,
    equipmentChecklists,
    updateRowsPerPage,
    changePage,
  } = useEquipmentChecklistsTable(equipmentId);

  const rowSelectedHandler = (event, id) => {
    event.preventDefault();
    history.push(`/equipment-checklists/${id}`);
  };

  const changePageHandler = (event, newPage) => {
    changePage(newPage);
  };

  const changeRowsPerPageHandler = (event) => {
    updateRowsPerPage(+event.target.value);
  };

  return (
    <Fragment>
      <Table style={{ border: "1px solid #d3d3d3" }} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "#d3d3d3" }}>
            <TableCell />
            <TableCell>
              <Typography>
                <strong>Date Created</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Status</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Milage (km)</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Created By</strong>
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {equipmentChecklists.map(
            ({ id, createdAt, status, milage, worker }) => {
              return (
                <ChecklistTableRow
                  id={id}
                  createdAt={createdAt}
                  status={status}
                  milage={milage}
                  createdBy={`${worker?.firstName} ${worker?.lastName}`}
                  onViewClick={(event) => rowSelectedHandler(event, id)}
                />
              );
            }
          )}
        </TableBody>
        {equipmentChecklists?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={changePageHandler}
            onChangeRowsPerPage={changeRowsPerPageHandler}
          />
        )}
      </Table>
      {loading && (
        <CenterBox marginTop={4}>
          <CircularProgress />
        </CenterBox>
      )}
      {!loading && error && (
        <CenterBox marginTop={4}>
          <Typography>{error}</Typography>
        </CenterBox>
      )}
      {!loading && !error && equipmentChecklists.length === 0 && (
        <CenterBox marginTop={4}>
          <Typography>No checklists to display.</Typography>
        </CenterBox>
      )}
    </Fragment>
  );
};

const ChecklistTableRow = ({
  id,
  createdAt,
  status,
  milage,
  createdBy,
  onViewClick,
}) => {
  let statusField;
  if (status === "PASSED") {
    statusField = (
      <Box display="flex" alignItems="flex-end">
        <Check style={{ color: "#46B83D" }} />
        <Typography style={{ color: "#46B83D" }} variant="caption">
          <strong>Passed</strong>
        </Typography>
      </Box>
    );
  } else {
    statusField = (
      <Box display="flex" alignItems="flex-end">
        <Report style={{ color: "#EE7302" }} />
        <Typography style={{ color: "#EE7302" }} variant="caption">
          <strong>Not Passed</strong>
        </Typography>
      </Box>
    );
  }

  return (
    <TableRow hover key={id}>
      <TableCell width="5%">
        <Assignment color="primary" />
      </TableCell>
      <TableCell>
        <Typography>{formatDateShort(createdAt)}</Typography>
      </TableCell>
      <TableCell>{statusField}</TableCell>
      <TableCell>
        <Typography>{milage}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{createdBy}</Typography>
      </TableCell>
      <TableCell align="right">
        <Box py={1} justifyContent="center" display="flex">
          <Link
            color="primary"
            href={`/equipment-checklists/${id}`}
            onClick={onViewClick}
          >
            <Typography>
              <strong>View</strong>
            </Typography>
          </Link>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default EquipmentChecklistsTable;
