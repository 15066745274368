import React, { useEffect, useState } from "react";
import { getAllEquipment } from "../../../services/equipmentService";

const useEquipment = () => {
  const [equipment, setEquipment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState({
    name: undefined,
    limit: 10,
    offset: 0,
  });
  const [dialogFormOpen, setDialogFormOpen] = useState(false);

  useEffect(() => {
    let timer;

    if (!query.name) {
      getEquipmentRequest(query);
    } else {
      timer = setTimeout(() => {
        getEquipmentRequest(query);
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [query]);

  const getEquipmentRequest = async (query) => {
    setLoading(true);
    setEquipment([]);
    setError(null);

    try {
      const response = await getAllEquipment(query);
      setEquipment(response.data.equipments);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const updateNameQuery = (name) => {
    setQuery((query) => ({
      ...query,
      name: name || undefined,
      offset: 0,
    }));
    setPage(0);
  };

  const updateRowsPerPage = (limit) => {
    setRowsPerPage(limit);
    setQuery((query) => ({
      ...query,
      limit,
    }));
  };

  const updatePage = (page) => {
    setPage(page);
    setQuery((query) => ({
      ...query,
      offset: rowsPerPage * page,
    }));
  };

  const updateDialogForm = (state) => {
    setDialogFormOpen(state);
  };

  return {
    equipment,
    loading,
    error,
    updateNameQuery,
    getEquipmentRequest,
    updatePage,
    updateRowsPerPage,
    updateDialogForm,
    dialogFormOpen,
    total,
    rowsPerPage,
    page,
  };
};

export default useEquipment;
