import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Paper, Typography, CircularProgress, Box } from "@material-ui/core";
import SafetyTalksTable from "../SafetyTalks/SafetyTalksTable";
import * as safetyTalksService from "../../services/safetyTalksService";
import CenterBox from "../UI/CenterBox";

const ProjectSafetyTalks = (props) => {
  const history = useHistory();
  const [safetyTalks, setSafetyTalks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [queryData, setQueryData] = useState({
    params: {
      name: "",
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const safetyTalkSelectedHandler = (event, id) => {
    history.push("/safety-talks/" + id);
  };

  useEffect(() => {
    setIsLoading(true);
    safetyTalksService
      .getProjectSafetyTalks(props.projectId)
      .then((response) => {
        setSafetyTalks(response.data.safetyTalks);
        setTotal(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [queryData]);

  let renderedView;

  if (isLoading) {
    renderedView = (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  } else if (error) {
    renderedView = <div>Error Occurred</div>;
  } else if (safetyTalks) {
    renderedView = (
      <SafetyTalksTable
        safetyTalks={safetyTalks}
        safetyTalkSelected={safetyTalkSelectedHandler}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        pageChangeHandler={pageChangeHandler}
        rowsPerPageChangeHandler={rowsPerPageChangeHandler}
      />
    );
  }

  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box m={1}>
        <Typography variant="h5">Project Safety Talks</Typography>
      </Box>
      {renderedView}
    </Paper>
  );
};

export default ProjectSafetyTalks;
