import React, { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  Grid,
  TextField,
  CircularProgress,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import { getAllWorkers } from "../../services/workerService";
import NewButton from "../../components/UI/NewButton";
import CenterBox from "../../components/UI/CenterBox";
import { Devices, Search } from "@material-ui/icons";

const WorkersScreen = (props) => {
  const [workers, setWorkers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const onWorkerViewClickHandler = (event, id) => {
    history.push(`/workers/${id}`);
  };

  const onCreateEmployeeClickHandler = (event) => {
    history.push("/workers/create");
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    getAllWorkers()
      .then((response) => {
        setLoading(false);
        setWorkers(response.data.workers);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  let workersTable = null;
  if (workers && !loading && !error) {
    workersTable = (
      <WorkersTable
        workers={workers}
        workerViewClicked={onWorkerViewClickHandler}
      />
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold">Workers</Box>
              </Typography>
            </Grid>
            <Grid item>
              <NewButton onClick={onCreateEmployeeClickHandler}>
                New Worker
              </NewButton>
            </Grid>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <WorkersPaper />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/*   <Typography variant="h4">Employees</Typography>

      <Paper>{workersTable}</Paper> */}
    </Container>
  );
};

const WorkersPaper = (props) => {
  const history = useHistory();
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // State of the query view
  const [searchParams, setSearchParams] = useState({
    name: "",
  });

  // State of the current API query
  const [queryData, setQueryData] = useState({
    params: {
      name: "",
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const onWorkerSelectedHandler = (event, id) => {
    history.push("/workers/" + id);
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const nameSearchQueryChangeHandler = (event) => {
    const updatedSearchParams = {
      ...searchParams,
      name: event.target.value,
    };

    setSearchParams(updatedSearchParams);
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const onSearchButtonClickHandler = (event) => {
    const updatedQueryData = { ...queryData };
    const params = {};

    if (searchParams.name !== "") {
      params.name = searchParams.name;
    }

    params.limit = rowsPerPage;
    params.offset = 0;

    updatedQueryData.params = params;

    setQueryData(updatedQueryData);
    setPage(0);
  };

  const onClearSearchHandler = () => {
    const updatedPage = 0;
    const updatedQueryData = {
      params: {
        name: "",
        limit: rowsPerPage,
        offset: rowsPerPage * updatedPage,
      },
    };
    const updatedSearchParams = {
      name: "",
    };

    setSearchParams(updatedSearchParams);
    setQueryData(updatedQueryData);
    setPage(updatedPage);
  };

  useEffect(() => {
    setError(false);
    setLoading(true);
    console.log(queryData.params);
    getAllWorkers(queryData.params)
      .then((response) => {
        setWorkers(response.data.workers);
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [queryData]);

  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                type="search"
                label="Search Workers"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" c>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={nameSearchQueryChangeHandler}
                value={searchParams.name}
              />
            </Grid>
            {queryData.params.name && (
              <Grid item>
                <Chip
                  label={`Search: '${queryData.params.name}'`}
                  variant="outlined"
                  color="primary"
                  onDelete={onClearSearchHandler}
                />
              </Grid>
            )}
          </Grid>

          <Grid item>
            <Box py={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onSearchButtonClickHandler}
              >
                <Box px={2}>Search</Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <CenterBox pb={8}>
          <CircularProgress />
        </CenterBox>
      ) : (
        <WorkersTable
          workers={workers}
          onRowSelected={onWorkerSelectedHandler}
          total={total}
          rowsPerPage={rowsPerPage}
          page={page}
          pageChangeHandler={pageChangeHandler}
          rowsPerPageChangeHandler={rowsPerPageChangeHandler}
        />
      )}
    </Paper>
  );
};

const WorkersTable = ({ workers, ...props }) => {
  let workerCells;
  if (!workers || workers.length === 0) {
    workerCells = (
      <Box p={2}>
        <Typography>No Workers to Display</Typography>
      </Box>
    );
  } else {
    workerCells = workers.map((worker) => {
      return (
        <TableRow key={worker.id} hover>
          <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>
          <TableCell align="left">
            {worker.contractor && worker.contractor.name}
          </TableCell>

          <TableCell align="left">{worker.title}</TableCell>
          <TableCell align="left">{worker.email}</TableCell>
          <TableCell align="left">
            {worker.isUser ? (
              <Tooltip title="This worker has login credentials">
                <Devices color="primary" />
              </Tooltip>
            ) : null}
          </TableCell>
          <TableCell align="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => props.onRowSelected(event, worker.id)}
            >
              <Box px={2}>View</Box>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }
  return (
    <Fragment>
      <Table style={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Contractor</strong>
            </TableCell>

            <TableCell align="left">
              <strong>Title</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Email</strong>
            </TableCell>
            <TableCell align="left">
              <strong>User</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{workerCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
      {/* {certificationCells.length === 0 ? (
          <Typography>No Cetifications found</Typography>
        ) : null} */}
    </Fragment>
  );
};

export default WorkersScreen;
