import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  CircularProgress,
  Button,
} from '@material-ui/core';
import CountrySelect from '../../UI/CountrySelect';
import ProvinceSelect from '../../UI/ProvinceSelect';
import SaveButton from '../../UI/SaveButton';
import PhoneNumberInputMask from '../../UI/PhoneNumberInputMask/PhoneNumberInputMask';

const ContractorForm = ({
  contractorData,
  onChangedHandler,
  loading,
  onSaveButtonClickHandler,
  edit,
}) => {
  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h4">{edit ? 'Edit' : 'New'} Contractor</Typography>
        <Box mb={2} />
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography>Information</Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id="nameTextField"
              label="Name"
              variant="outlined"
              value={contractorData.name.value}
              error={contractorData.name.error}
              onChange={(event) => onChangedHandler(event, 'name')}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="emailTextField"
              label="Email"
              variant="outlined"
              value={contractorData.email.value}
              onChange={(event) => onChangedHandler(event, 'email')}
            />
          </Grid>
          <Grid item>
            <PhoneNumberInputMask
              value={contractorData.officePhone.value}
              onChange={(event) => onChangedHandler(event, 'officePhone')}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="descriptionTextField"
              label="Description"
              variant="outlined"
              value={contractorData.description.value}
              onChange={(event) => onChangedHandler(event, 'description')}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>Address</Typography>
          </Grid>
          <Grid item>
            <CountrySelect
              fullWidth
              error={contractorData.country.error}
              selected={contractorData.country.value}
              onChange={(event) => onChangedHandler(event, 'country')}
            />
          </Grid>
          <Grid item>
            <ProvinceSelect
              fullWidth
              error={contractorData.state.error}
              selected={contractorData.state.value}
              onChange={(event) => onChangedHandler(event, 'state')}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="cityTextField"
              label="City"
              value={contractorData.city.value}
              error={contractorData.city.error}
              variant="outlined"
              onChange={(event) => onChangedHandler(event, 'city')}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="addressTextField"
              label="Address"
              value={contractorData.address1.value}
              error={contractorData.address1.error}
              variant="outlined"
              onChange={(event) => onChangedHandler(event, 'address1')}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            {loading ? (
              <CircularProgress />
            ) : (
              <SaveButton onClick={onSaveButtonClickHandler} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ContractorForm;
