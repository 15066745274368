import { Box, Typography } from "@material-ui/core";
import React from "react";

const EquipmentChecklistItemCategory = ({ header }) => {
  return (
    <Box width={1} pb={1} pt={1.5} px={3} bgcolor="#01579B">
      <Typography variant="h6" style={{ color: "#FFFFFF" }}>
        <strong>{header}</strong>
      </Typography>
    </Box>
  );
};

export default EquipmentChecklistItemCategory;
