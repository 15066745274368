import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  CircularProgress,
  Card,
} from "@material-ui/core";
import ProjectWorkers from "../../../components/Project/ProjectWorkers/ProjectWorkers";
import ProjectChecklists from "../../../components/Project/ProjectChecklists";
import ProjectSafetyTalks from "../../../components/Project/ProjectSafetyTalks";
import { getProjectById } from "../../../services/projectService";
import ProjectDetails from "../../../components/Project/ProjectDetails";
import EditButton from "../../../components/UI/EditButton";
import ProjectIncidents from "../../../components/Incidents/ProjectIncidents";
import CenterBox from "../../../components/UI/CenterBox";
import ProjectIssues from "../../../components/Project/ProjectIssues/ProjectIssues";
import { CheckCircle, Warning } from "@material-ui/icons";
import { green, orange } from "@material-ui/core/colors";
import DeleteProjectButton from "./components/DeleteButton";
import ConfirmDeleteProjectDialog from "./components/ConfirmDeleteProjectDialog";

const ProjectDetailScreen = () => {
  const { id } = useParams();
  const history = useHistory();
  const [projectState, setProjectState] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [errorState, setErrorState] = useState(null);
  const [
    confirmDeleteProjectDialogOpen,
    setConfirmDeleteProjectDialogOpen,
  ] = useState(false);

  const onEditProjectButtonClickHandler = () => {
    history.push("/projects/" + id + "/edit");
  };

  const onDeleteProjectButtonClickHandler = () => {
    setConfirmDeleteProjectDialogOpen(true);
  };

  const onViewEmergencyNumbersFormClickHandler = (event) => {
    event.preventDefault();

    history.push("/projects/" + projectState.id + "/emergency-numbers", {
      projectId: projectState.id,
    });
  };

  const onCreateNewEmergencyNumbersFormClickHandler = (event) => {
    event.preventDefault();

    history.push("/emergency-numbers/create", { projectId: projectState.id });
  };

  const closeDeleteProjectDialogHandler = (value) => {
    if (value === true) {
      history.replace("/projects");
    } else {
      setConfirmDeleteProjectDialogOpen(false);
    }
  };

  useEffect(() => {
    setErrorState(null);
    setLoadingState(true);
    getProjectById(id)
      .then((response) => {
        setLoadingState(false);
        setProjectState(response.data);
      })
      .catch((err) => {
        setLoadingState(false);
        setErrorState(err);
      });
  }, []);

  if (projectState && !loadingState && !errorState) {
    return (
      <Container maxWidth="lg">
        <Box py={2}>
          {confirmDeleteProjectDialogOpen && (
            <ConfirmDeleteProjectDialog
              open={confirmDeleteProjectDialogOpen}
              id={id}
              onClose={closeDeleteProjectDialogHandler}
              projectName={projectState.name}
            />
          )}

          <Grid container direction="column">
            <Grid item>
              <Typography>Projects</Typography>
            </Grid>
            <Grid item>
              <Box pb={2}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    lg
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h4">
                        <strong>{projectState.name || ""}</strong>
                      </Typography>
                    </Grid>
                    {projectState.projectStatus != null && (
                      <Grid item>
                        <Card>
                          <Box py={2} px={2}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              justify="center"
                            >
                              <Grid item>
                                <Typography>
                                  {projectState.projectStatus === 0
                                    ? "No Issues To Report"
                                    : "Project Not Safe"}
                                </Typography>
                              </Grid>
                              <Grid item>
                                {projectState.projectStatus === 0 ? (
                                  <CheckCircle
                                    style={{ color: green[500], fontSize: 32 }}
                                  />
                                ) : (
                                  <Warning
                                    style={{ color: orange[700], fontSize: 32 }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <Grid item>
                          <EditButton
                            onClick={onEditProjectButtonClickHandler}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <DeleteProjectButton
                          onClick={onDeleteProjectButtonClickHandler}
                          projectState={projectState.state}
                        />
                        {/*  <DeleteButton
                          onClick={onDeleteProjectButtonClickHandler}
                          disabled
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Paper>
                  <ProjectDetails
                    project={projectState}
                    onCreateEmergencyNumbersFormClick={
                      onCreateNewEmergencyNumbersFormClickHandler
                    }
                    onViewEmergencyNumbersFormClick={
                      onViewEmergencyNumbersFormClickHandler
                    }
                    onViewHazardAssessmentsClick={() =>
                      history.push(
                        `/projects/${projectState.id}/risk-assessments`
                      )
                    }
                  />
                </Paper>
              </Grid>
              {projectState.projectStatus === 1 && (
                <Grid item>
                  <ProjectIssues projectId={id} />
                </Grid>
              )}

              <Grid item>
                <ProjectWorkers projectId={id} />
              </Grid>
              <Grid item>
                <ProjectChecklists
                  projectId={id}
                  name={`Inspection Checklists`}
                  checklistType={0}
                />
              </Grid>
              <Grid item>
                <ProjectChecklists
                  projectId={id}
                  name={`First Aid Checklists`}
                  checklistType={3}
                />
              </Grid>
              <Grid item>
                <ProjectSafetyTalks projectId={id} />
              </Grid>
              <Grid item>
                <ProjectIncidents projectId={id} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <CenterBox p={4}>
        <CircularProgress />
      </CenterBox>
    </Container>
  );
};

export default ProjectDetailScreen;
