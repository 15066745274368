import React, { useState, useEffect } from "react";
import { MenuItem } from "@material-ui/core";

import { getHospitals } from "../../services/emergencyNumberService";
import CustomOutlinedSelect from "../UI/CustomOutlinedSelect";

const useRequestHandler = () => {
  const [hospitals, setHospitals] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  const getHospitalsRequest = () => {
    setIsLoading(true);
    setHasError(null);

    return getHospitals()
      .then(response => {
        setHospitals(response.data);
      })
      .catch(err => {
        setHasError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return { hospitals, isLoading, hasError, getHospitalsRequest };
};

const HospitalSelect = ({ onChange, selected, error, refresh }) => {
  const {
    hospitals,
    isLoading,
    hasError,
    getHospitalsRequest
  } = useRequestHandler();

  useEffect(() => {
    const hospitalsRequest = getHospitalsRequest();
  }, [refresh]);

  let menuItems;

  if (hasError) {
    menuItems = (
      <MenuItem disabled value="">
        <em>Error Loading Hospitals</em>
      </MenuItem>
    );
  } else if (hospitals && !isLoading) {
    menuItems = hospitals.map(hospital => {
      const label = `${hospital.name} (${hospital.address})`;
      return (
        <MenuItem key={hospital.id} value={hospital.id}>
          {label}
        </MenuItem>
      );
    });
  } else {
    menuItems = (
      <MenuItem disabled value="">
        <em>Loading...</em>
      </MenuItem>
    );
  }

  return (
    <CustomOutlinedSelect
      label="Nearest Hospital"
      fullWidth
      error={error}
      onChange={onChange}
      selected={selected}
      menuItems={menuItems}
    />
  );
};

export default HospitalSelect;
