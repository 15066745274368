import React, { Component, useEffect, useContext, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";
import { AuthContext } from "../../../context/auth-context";
import { CircularProgress } from "@material-ui/core";
import axios from "../../../axios";

const LogoutPage = (props) => {
  const authContext = useContext(AuthContext);
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("userData");
    axios.defaults.headers.common["Authorization"] = "";
    authContext.logout();
  }, []);

  return <Redirect to="/login" />;
};

export default LogoutPage;
