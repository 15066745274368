import React from "react";
import { Button, Box, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

const NewButton = ({ children, onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<AddCircle />}
      onClick={onClick}
    >
      <Box px={1}>
        <Typography>{children}</Typography>
      </Box>
    </Button>
  );
};

export default NewButton;
