import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initState = {
    projects: [],
    selectedProject: {},
    loading: false
}

const reducer = (state = initState, action ) => {
    switch (action.type) {
        case actionTypes.GET_ALL_PROJECTS_REQUEST: return updateObject(state, { loading: true});
        case actionTypes.GET_ALL_PROJECTS_SUCCESS: return updateObject(state, {
            loading: false,
            projects: action.projects
        });
        case actionTypes.GET_PROJECT_DETAILS_SUCCESS: return updateObject(state, {
            loading: false,
            selectedProject: action.selectedProject
        });
        case actionTypes.GET_ALL_PROJECTS_FAILURE: return updateObject(state, { loading: false});
        default: return state;
    }
};

export default reducer;