import React, { useState, Fragment } from "react";
import Strings from "../../strings";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Box,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import {
  ArrowLeft,
  ArrowRightAlt,
  ArrowRight,
  ArrowForward,
} from "@material-ui/icons";

const ResourcesTable = (props) => {
  let resourcesCells;

  if (!props.resources || props.resources.length === 0) {
    resourcesCells = (
      <TableRow>
        <Box p={2}>
          <Typography>No Resources to Display</Typography>
        </Box>
      </TableRow>
    );
  } else {
    resourcesCells = props.resources
      .slice(
        props.page * props.rowsPerPage,
        props.page * props.rowsPerPage + props.rowsPerPage
      )
      .map((resource) => {
        //const dateCreatedLabel = new Date(checklist.createdDate);

        return (
          <TableRow hover key={resource.id}>
            <TableCell component="th" scope="row">
              {resource.name}
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {Strings.RESOURCE_TYPES[resource.type - 1]}
            </TableCell>
            <TableCell align="left">{resource.expireDate}</TableCell>

            <TableCell align="left">
              {resource.active ? "ACTIVE" : "DISABLED"}
            </TableCell>
            <TableCell align="right">
              <Button
                variant="outlined"
                color="primary"
                onClick={(event) =>
                  props.onResourceSelected(event, resource.id)
                }
              >
                <Box px={2}>View</Box>
              </Button>
              {/*  <IconButton>
              <ArrowForward color="primary" />
            </IconButton> */}
            </TableCell>
          </TableRow>
        );
      });
  }

  return (
    <Fragment>
      <Table style={{ tableLayout: "fixed", minWidth: 450 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box fontWeight="fontWeightBold">Name</Box>
            </TableCell>
            <TableCell align="left">
              <Box fontWeight="fontWeightBold">Type</Box>
            </TableCell>
            <TableCell align="left">
              <Box fontWeight="fontWeightBold">Expire Date</Box>
            </TableCell>
            <TableCell align="left">
              <Box fontWeight="fontWeightBold">Active</Box>
            </TableCell>
            <TableCell align="right">
              <Box fontWeight="fontWeightBold">Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{resourcesCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
    </Fragment>
  );
};

export default ResourcesTable;
