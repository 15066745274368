import React, { Component } from 'react';
import classes from './Layout.module.css';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../SideDrawer/SideDrawer';

class Layout extends Component {

    render() {

        return(
            <div style={{display: 'flex'}}>

            
                <Toolbar />
                <SideDrawer/>
                <main className={classes.Content}>
                    {this.props.children}
                </main>
          
            </div>
        );
    }
}

export default Layout;