import axios from "../axios";

export const getAllWorkers = (params = {}) => {
  return axios.get("/workers", { params });
};

export const getWorkerById = (id) => {
  return axios.get("/workers/" + id);
};

export const getWorkersOnProject = (projectId) => {
  return axios.get(`/projects/${projectId}/workers`);
};

export const createEmployee = (employee) => {
  return axios.post("/workers", employee);
};

export const updateEmployee = (id, employee) => {
  return axios.put("/workers/" + id, employee);
};

export const deleteEmployee = (id) => {
  return axios.delete("/workers/" + id);
};

export const addWorkersToProject = (requestBody, projectId) => {
  return axios.put(`/projects/${projectId}/workers`, requestBody);
};
