/*
 *
 *   A Wrapper for the Select component using a form control and
 *   input label. Fixes bug when label moves up after selecting
 *
 */

import React, { useEffect, useState, useRef } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";

const CustomOutlinedSelect = ({
  label,
  disabled,
  required,
  fullWidth,
  error,
  onChange,
  selected,
  menuItems
}) => {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  return (
    <FormControl
      required={required}
      variant="outlined"
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
    >
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        fullWidth
        onChange={onChange}
        value={menuItems ? selected : ""}
        labelWidth={labelWidth}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default CustomOutlinedSelect;
