import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";

const ActivityCard = ({ name, value, loading }) => {
  return (
    <Paper
      style={{
        height: "120px",
        width: "220px",
        borderLeft: "8px solid #01579B",
      }}
    >
      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100%" }}
      >
        <Grid item>
          <Typography variant="h6">
            <strong>{name}</strong>
          </Typography>
        </Grid>
        <Grid item>
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography color="primary" variant="h2">
              {value}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ActivityCard;
