import { createMuiTheme } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#4f83cc",
      main: "#01579b",
      dark: "#002f6c",
    },
    white: {
      main: "#FFFFFF",
    },
    green: {
      main: "#46B83D",
    },
    /*    warning: {
      main: '#ff9800'
    },
    error: {
      main: '#f44336'
    },
    success: {
      main: '#4caf50'
    } */
  },
});

export default customTheme;
