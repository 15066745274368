import { Dialog } from "@material-ui/core";
import React from "react";
import useEquipmentForm from "../hooks/useEquipmentForm";
import EquipmentForm from "./EquipmentForm";

const EquipmentFormDialog = ({ open, onCloseHandler, equipmentState }) => {
  const {
    loading,
    error,
    equipment,
    updateEquipmentForm,
    submitForm,
  } = useEquipmentForm(equipmentState);

  const textChangeHandler = (event) => {
    updateEquipmentForm(event.target.id, event.target.value);
  };

  const submitClickHandler = async () => {
    const result = await submitForm();

    if (result) onCloseHandler(result);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onCloseHandler()}
    >
      <EquipmentForm
        error={error}
        loading={loading}
        equipment={equipment}
        onTextChangeHandler={textChangeHandler}
        submitClickHandler={submitClickHandler}
      />
    </Dialog>
  );
};
export default EquipmentFormDialog;
