import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { requestPasswordReset } from "../../../services/authService";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const history = useHistory();
  const [textState, setTextState] = useState({
    email: {
      value: "",
      error: null,
    },
  });
  const [viewState, setViewState] = useState({
    loading: false,
    error: null,
    success: false,
  });

  const textChangeHandler = (event, id) => {
    const updatedState = {
      ...textState,
      [id]: {
        value: event.target.value,
      },
    };

    setTextState(updatedState);
  };

  const changeViewStateHandler = (newState) => {
    const updatedState = {
      ...viewState,
      ...newState,
    };

    setViewState(updatedState);
  };

  const submitButtonHandler = () => {
    let hasError;
    const currentState = {
      ...textState,
    };

    if (!currentState.email.value) {
      currentState.email.error = true;
      hasError = true;
    }

    if (hasError) {
      setTextState(currentState);
      return;
    }

    changeViewStateHandler({ loading: true });

    requestPasswordReset(currentState.email.value)
      .then(() => {
        changeViewStateHandler({
          loading: false,
          success: true,
        });
      })
      .catch((err) => {
        changeViewStateHandler({
          loading: false,
          error: err,
        });
      });
  };

  return (
    <Container maxWidth="md">
      <Box
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={1}
      >
        <Box width="100%">
          <Paper>
            <Box
              px={8}
              py={8}
              display="flex"
              minHeight={400}
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h4">
                    <strong>Forgot Password</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Enter in your email address and we will send you a recovery
                    link
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    value={textState.email.value}
                    error={textState.email.error}
                    onChange={(event) => textChangeHandler(event, "email")}
                  />
                  {(viewState.error || viewState.success) && (
                    <Box py={1}>
                      {viewState.error ? (
                        <Typography color="error">
                          Whoops. Something went wrong sending the email.
                        </Typography>
                      ) : (
                        <Typography color="primary">
                          Email sent sucessfully. If there is a user linked to
                          the email you provided you should receive an email
                          shortly.
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  {viewState.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={submitButtonHandler}
                    >
                      Send Email
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Box pt={2} display="flex" justifyContent="center">
                    <Link
                      href="/login"
                      onClick={(event) => {
                        event.preventDefault();

                        history.replace("/login");
                      }}
                    >
                      Back to Login
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
