import React, { Fragment } from "react";
import Strings from "../../strings";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  TablePagination,
} from "@material-ui/core";

const FirstAidChecklistsTable = (props) => {
  const checklistsCells = props.checklists.map((checklist) => {
    const dateCreatedLabel = new Date(checklist.createdDate).toLocaleString();

    return (
      <TableRow hover key={checklist.id}>
        <TableCell component="th" scope="row">
          {checklist.name}
        </TableCell>
        <TableCell align="left">
          {Strings.checklistTypes[checklist.type - 1]}
        </TableCell>
        <TableCell align="left">
          {Strings.FIRST_AID_TYPES[checklist.firstAidType - 1]}
        </TableCell>
        <TableCell align="left">{Strings.STATE[checklist.state - 1]}</TableCell>
        <TableCell align="left">{`${dateCreatedLabel}`}</TableCell>
        <TableCell align="left">{checklist.assignedUser.name}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => props.onChecklistSelected(event, checklist.id)}
          >
            <Box px={2}>View</Box>
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Fragment>
      <Table style={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Type</strong>
            </TableCell>
            <TableCell align="left">
              <strong>First Aid Type</strong>
            </TableCell>
            <TableCell align="left">
              <strong>State</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Date Created</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Assigned User</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{checklistsCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
    </Fragment>
  );

  /*     return (
        <Table>
            <TableHead>
                <TableRow>
                 

                </TableRow>
            </TableHead>
            <TableBody>
                {checklistsCells}
            </TableBody>
        </Table>
    ); */
};

export default FirstAidChecklistsTable;
