import * as actionTypes from './actionTypes';
import * as services from '../../services/userService';

export const getAllUsers = () => {
    return dispatch => {
        console.log('making request...');
        dispatch(request());
        services.getAllUsersOnAccount()
            .then(response => {
                console.log(response.data);
                dispatch(success(response.data));
            })
            .catch(err => {
                console.log(err.response);
                dispatch(error(err));
            });
    }
    function request() { return { type: actionTypes.GET_ALL_USERS_ON_ACCOUNT_REQUEST }}
    function success(users) { return { type: actionTypes.GET_ALL_USERS_ON_ACCOUNT_SUCCESS, users: users }}
    function error(error) { return { type: actionTypes.GET_ALL_USERS_ON_ACCOUNT_FAILURE, error: error }}
}

export const getUserById = (userId) => {
    return dispatch => {
        console.log('making request...');
        dispatch(request());
        services.getUserById(userId)
            .then(response => {
                console.log(response.data);
                dispatch(success(response.data));
            })
            .catch(err => {
                console.log(err.response);
                dispatch(error(err));
            });
    }
    function request() { return { type: actionTypes.GET_ALL_USERS_ON_ACCOUNT_REQUEST }}
    function success(user) { return { type: actionTypes.GET_USER_DETAILS_SUCCESS, selectedUser: user }}
    function error(error) { return { type: actionTypes.GET_ALL_USERS_ON_ACCOUNT_FAILURE, error: error }}
}