import React from "react";
import { MenuItem } from "@material-ui/core";
import CustomOutlinedSelect from "./CustomOutlinedSelect";

const menuItemsData = [
  {
    id: 1,
    name: "Newfoundland and Labrador",
    code: "NL"
  },
  {
    id: 2,
    name: "Prince Edward Island",
    code: "PE"
  },
  {
    id: 3,
    name: "Nova Scotia",
    code: "NS"
  },
  {
    id: 4,
    name: "New Brunswick",
    code: "NB"
  },
  {
    id: 5,
    name: "Quebec",
    code: "QC"
  },
  {
    id: 6,
    name: "Ontario",
    code: "ON"
  },
  {
    id: 7,
    name: "Manitoba",
    code: "MB"
  },
  {
    id: 8,
    name: "Saskatchewan",
    code: "SK"
  },
  {
    id: 9,
    name: "Alberta",
    code: "AB"
  },
  {
    id: 10,
    name: "British Columbia",
    code: "BC"
  },
  {
    id: 11,
    name: "Yukon",
    code: "TY"
  },
  {
    id: 12,
    name: "Northwest Territories",
    code: "NT"
  },
  {
    id: 13,
    name: "Nunavut",
    code: "NU"
  }
];

const menuItems = menuItemsData
  .sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
  .map(item => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    );
  });

const ProvinceSelect = ({ fullWidth, error, onChange, selected, required }) => {
  return (
    <CustomOutlinedSelect
      label="State / Province"
      fullWidth={fullWidth}
      required={required}
      error={error}
      onChange={onChange}
      selected={selected}
      menuItems={menuItems}
    />
  );
};

export default ProvinceSelect;
