import React, { Fragment } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
  Button,
  Box,
} from "@material-ui/core";

const CertificationsTable = (props) => {
  let certificationCells;
  if (!props.certifications || props.certifications.length === 0) {
    certificationCells = (
      <TableRow>
        <Box p={2}>
          <Typography>No records to display</Typography>
        </Box>
      </TableRow>
    );
  } else {
    certificationCells = props.certifications.map((cert) => {
      return (
        <TableRow key={cert.id} hover>
          <TableCell>{cert.name}</TableCell>
          <TableCell align="left">{cert.owner && cert.owner.name}</TableCell>
          <TableCell align="left">{cert.issuedDate}</TableCell>
          <TableCell align="left">{cert.expireDate}</TableCell>
          <TableCell align="left">
            {cert.active ? "ACTIVE" : "NOT ACTIVE"}
          </TableCell>
          <TableCell align="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => props.onRowSelected(event, cert.id)}
            >
              <Box px={2}>View</Box>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // if (props.certifications.length === 0) {
  //   certificationCells = <Typography>No Cetifications found</Typography>;
  // }

  return (
    <Fragment>
      <Table style={{ tableLayout: "fixed", minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Owner</strong>
            </TableCell>

            <TableCell align="left">
              <strong>Issued Date</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Expire Date</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Active</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{certificationCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
      {/* {certificationCells.length === 0 ? (
          <Typography>No Cetifications found</Typography>
        ) : null} */}
    </Fragment>
  );
};

export default CertificationsTable;
