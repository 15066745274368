import React from "react";
import { Button, Box } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const DeleteButton = ({onClick, disabled}) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<Delete />}
      onClick={onClick}
      disabled={disabled}
    >
      <Box px={0}>
      Delete
      </Box>
      
    </Button>
  );
};

export default DeleteButton;
