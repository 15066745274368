import React, { useState, useContext } from "react";

import classes from "./Toolbar.module.css";

import NavigationItems from "../NavigationItems/NavigationItems";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Icon,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Box,
  Link,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { AuthContext } from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import rebarLogo from "../../../images/rebar_logo.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    /*  flexGrow: 1 */
  },
  menuItem: {
    width: 150,
    padding: theme.spacing(1),
  },
}));

const CustomToolbar = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoClickHandler = (event) => {
    event.preventDefault();

    history.push("/projects");
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Box pl={4} style={{ flexGrow: 1 }} alignItems="center">
          <Link href="#" onClick={logoClickHandler}>
            <img
              src={rebarLogo}
              alt="logo"
              style={{ paddingTop: "3px" }}
              height={50}
            />
          </Link>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="Account of Current User"
          onClick={handleMenu}
        >
          <Box pr={1}>
            <AccountCircle />
          </Box>
          <Grid container direction="column">
            <Typography display="inline">
              {authContext.authState.name}
            </Typography>

            <Typography display="inline" variant="caption">
              {authContext.authState.accountName}
            </Typography>
          </Grid>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => {
              event.preventDefault();
              history.push(`/workers/${authContext.authState.userId}`);
            }}
          >
            Profile
          </MenuItem>
          {/* <MenuItem disabled className={classes.menuItem} onClick={handleClose}>
            Account
          </MenuItem> */}
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              history.push("/logout");
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default CustomToolbar;
