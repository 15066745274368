import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import CertificationsTable from "../../Certifications/CertificationsTable";
import { getCertifications } from "../../../services/certificationsService";
import CenterBox from "../../UI/CenterBox";

const ContractorCertifications = (props) => {
  const history = useHistory();
  const [certifications, setCertifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // State of the current API query
  const [queryData, setQueryData] = useState({
    params: {
      name: "",
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const onViewCertificationHandler = (event, certId) => {
    history.push("/certifications/" + certId);
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    getCertifications({
      ownerType: 2,
      ownerId: props.contractorId,
    })
      .then((response) => {
        setCertifications(response.data.certifications);
        setTotal(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [queryData]);

  let dataDisplay;

  if (isLoading || error || !certifications) {
    dataDisplay = (
      <CenterBox pb={8}>
        {error ? (
          <Typography>Error Occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  } else {
    dataDisplay = (
      <CertificationsTable
        certifications={certifications}
        onRowSelected={onViewCertificationHandler}
        total={total}
        rowsPerPage={rowsPerPage}
        page={page}
        pageChangeHandler={pageChangeHandler}
        rowsPerPageChangeHandler={rowsPerPageChangeHandler}
      />
    );
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h5">Certifications</Typography>
      </Box>
      {dataDisplay}
    </Paper>
  );
};

export default ContractorCertifications;
