import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Fragment } from "react";
import useNetworkRequest from "../../../utils/useNetworkRequest";
import * as RiskAssessmentService from "../../../services/riskAssessmentService";
import Hazards from "./Hazards";
import CenterBox from "../../../components/UI/CenterBox";

const RiskAssessmentDetails = ({ hazardActivityId }) => {
  const { loading, data, error, handleRequest } = useNetworkRequest(true);

  useEffect(() => {
    const request = () =>
      RiskAssessmentService.getHazardActivityDetails(hazardActivityId);
    handleRequest(request);
  }, [hazardActivityId, handleRequest]);

  if (loading || error) {
    return (
      <CenterBox width={1} marginTop={4} pb={4}>
        {loading ? <CircularProgress /> : <Typography>{error}</Typography>}
      </CenterBox>
    );
  }

  return (
    <Grid container direction="column">
      {data?.hazardTasks.length === 0 ? (
        <Typography>No Tasks to Display</Typography>
      ) : (
        data?.hazardTasks.map((task) => {
          return (
            <Fragment key={task.id}>
              <Grid item>
                <Box px={2} py={1} style={{ backgroundColor: "#01579B" }}>
                  <Typography variant="h5" style={{ color: "white" }}>
                    {task.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Hazards hazards={task.hazards} />
              </Grid>
            </Fragment>
          );
        })
      )}
    </Grid>
  );
};

export default RiskAssessmentDetails;
