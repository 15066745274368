import React, { useContext } from "react";
import {
  Drawer,
  List,
  Typography,
  Toolbar,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
  Link,
  Divider,
  Grid,
  Box,
} from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import BuildIcon from "@material-ui/icons/Build";

import DescriptionIcon from "@material-ui/icons/Description";
import { AuthContext } from "../../context/auth-context";

import NavigationItem from "../../components/Navigation/NavigationItems/NavigationItem/NavigationItem";
import { NavLink } from "react-router-dom";
import { AccountCircle, Dashboard, Help } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SideDrawer = (props) => {
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <Box p={2}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <AccountCircle color="primary" style={{ fontSize: 50 }} />
            </Grid>

            <Grid item>
              <Typography variant="h6">
                <Box fontWeight="fontWeightBold">
                  {authContext.authState.name}
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Admin</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider />
        <MenuList>
          <MenuItem component={NavLink} to="/dashboard">
            <ListItemIcon>
              <Dashboard color="primary" />
            </ListItemIcon>
            <Typography>Dashboard</Typography>
          </MenuItem>
          <MenuItem component={NavLink} to="/projects">
            <ListItemIcon>
              <StoreIcon color="primary" />
            </ListItemIcon>
            <Typography>Projects</Typography>
          </MenuItem>
          <MenuItem component={NavLink} to="/workers">
            <ListItemIcon>
              <PeopleIcon color="primary" />
            </ListItemIcon>
            <Typography>Workers</Typography>
          </MenuItem>
          <MenuItem component={NavLink} to="/contractors">
            <ListItemIcon>
              <BusinessIcon color="primary" />
            </ListItemIcon>
            <Typography>Contractors</Typography>
          </MenuItem>
          <MenuItem component={NavLink} to="/certifications">
            <ListItemIcon>
              <CardMembershipIcon color="primary" />
            </ListItemIcon>
            <Typography>Certifications</Typography>
          </MenuItem>
          <MenuItem component={NavLink} to="/equipment">
            <ListItemIcon>
              <BuildIcon color="primary" />
            </ListItemIcon>
            <Typography>Equipment</Typography>
          </MenuItem>
          <MenuItem component={NavLink} to="/resources">
            <ListItemIcon>
              <DescriptionIcon color="primary" />
            </ListItemIcon>
            <Typography>Resources</Typography>
          </MenuItem>
        </MenuList>
        <MenuList>
          <Divider />
          <MenuItem component={NavLink} to="/faqs">
            <ListItemIcon>
              <Help color="primary" />
            </ListItemIcon>
            <Typography>FAQs</Typography>
          </MenuItem>
        </MenuList>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
