import axios from "../axios";

export const getAllEquipment = (params = {}) => {
  return axios.get("/equipments", { params });
};

export const createEquipment = (body) => {
  return axios.post("/equipments", body);
};

export const getEquipmentById = (id) => {
  return axios.get(`/equipments/${id}`);
};

export const updateEquipmentById = (id, body) => {
  return axios.put(`/equipments/${id}`, body);
};

export const deleteEquipmentById = (id) => {
  return axios.delete(`/equipments/${id}`);
};

export const getEquipmentChecklistsOnEquipment = (id, params = {}) => {
  return axios.get(`/equipments/${id}/equipment-checklists`, { params });
};

export const getEquipmentChecklistById = (id) => {
  return axios.get(`/equipment-checklists/${id}`);
};

export const deleteEquipmentChecklistById = (id) => {
  return axios.delete(`/equipment-checklists/${id}`);
};

export const deleteEquipmentWorkAssignmentById = (id) => {
  return axios.delete(`/equipment-checklist-work-assignments/${id}`);
};

export const updateEquipmentWorkAssignmentById = (id, body) => {
  return axios.put(`/equipment-checklist-work-assignments/${id}`, body);
};

export const createEquipmentWorkAssignment = (body) => {
  return axios.post(`/equipment-checklist-work-assignments`, body);
};
