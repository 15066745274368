import React from "react";
import { Paper, Typography, Box, Button, Grid, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const checklistStateText = ["None", "In-Progress", "Submitted", "Completed"];
const checklistTypeText = ["Weekly", "Monthly", "First-Aid", "Orientation"];

const ChecklistInfo = (props) => {
  const history = useHistory();

  const assignedUserClickHandler = (event) => {
    event.preventDefault();
    history.push(`/workers/${props.checklist.assignedUser.id}`);
  }

  const assignedUser = props.checklist.assignedUser;
  const assignedUserName = assignedUser ? assignedUser.name : "";

  const dateCreatedLabel = new Date(props.checklist.createdDate).toLocaleString();
  const dateSubmittedLabel = props.checklist.submittedDate
    ? new Date(props.checklist.submittedDate).toLocaleString()
    : "";

  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption">Type</Typography>
            <Typography variant="h6">{checklistTypeText[props.checklist.type - 1]}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">State</Typography>
            <Typography variant="h6">{checklistStateText[props.checklist.state]}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Assigned User</Typography>
            <Typography variant="h6"><Link href="#" onClick={assignedUserClickHandler}>{assignedUserName}
            </Link></Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Date Created</Typography>
            <Typography variant="h6">{`${dateCreatedLabel}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Date Completed</Typography>
            <Typography variant="h6">{`${dateSubmittedLabel}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Signature</Typography>
            <Typography variant="h6"><Link href="#" onClick={(e) => e.preventDefault()}>
             
            </Link></Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ChecklistInfo;
