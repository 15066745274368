import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { CheckCircle, Warning } from "@material-ui/icons";
import { getProjectIssues } from "../../../services/projectService";
import strings from "../../../strings";
import { orange, green } from "@material-ui/core/colors";

const ProjectIssues = ({ projectId }) => {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    getProjectIssues(projectId)
      .then((response) => {
        setIssues(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, []);
  return (
    <Card>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box p={2}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" gutterBottom>
                Issues
              </Typography>
            </Grid>
            <Grid item>{loading && <CircularProgress />}</Grid>
          </Grid>

          {!loading && issues && (
            <Grid item container direction="row" spacing={2} justify="center">
              {issues.length > 0 ? (
                issues.map((issue) => {
                  return (
                    <Grid item key={issue.id}>
                      <Card>
                        <Box m={2} width={500}>
                          <Grid container direction="row" alignItems="center">
                            <Grid item sm={3} align="center">
                              <Warning
                                style={{ color: orange[700], fontSize: 44 }}
                              />
                            </Grid>
                            <Grid container sm item direction="column">
                              <Grid item>
                                <Typography>
                                  <strong>
                                    {strings[issue.type] || issue.type}
                                  </strong>
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {issue.type === "CORRECTIVE_ACTION" &&
                                    `'${
                                      strings.CORRECTIVE_ACTIONS[issue.name]
                                    }' `}
                                  {issue.message}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <Grid item>
                  <Card>
                    <Box p={2}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        direction="row"
                      >
                        <Grid item>
                          <Typography>No issues to report</Typography>
                        </Grid>
                        <Grid item>
                          <CheckCircle
                            style={{ color: green[500], fontSize: 28 }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
};

export default ProjectIssues;
