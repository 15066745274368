import * as actionTypes from './actionTypes';
import * as services from '../../services/workerService';

export const getAllWorkers = () => {
    return dispatch => {
        console.log('making request...');
        dispatch(request());
        services.getAllWorkers()
            .then(response => {
                console.log(response.data);
                dispatch(success(response.data));
            })
            .catch(err => {
                console.log(err.response);
                dispatch(error(err));
            });
    }
    function request() { return { type: actionTypes.GET_ALL_WORKERS_REQUEST }}
    function success(workers) { return { type: actionTypes.GET_ALL_WORKERS_SUCCESS, workers: workers }}
    function error(error) { return { type: actionTypes.GET_ALL_WORKERS_FAILURE, error: error }}
}

export const getWorkerById = (workerId) => {
    return dispatch => {
        console.log('making request...');
        dispatch(request());
        services.getWorkerById(workerId)
            .then(response => {
                console.log(response.data);
                dispatch(success(response.data));
            })
            .catch(err => {
                console.log(err.response);
                dispatch(error(err));
            });
    }
    function request() { return { type: actionTypes.GET_ALL_WORKERS_REQUEST }}
    function success(worker) { return { type: actionTypes.GET_WORKER_DETAILS_SUCCESS, selectedWorker: worker }}
    function error(error) { return { type: actionTypes.GET_ALL_WORKERS_FAILURE, error: error }}
}