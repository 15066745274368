import React, { useEffect, useState } from "react";
import { getEquipmentById } from "../../../../services/equipmentService";
import strings from "../../../../strings";

const useEquipmentDetails = (id) => {
  const [equipment, setEquipment] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getEquipmentDetailsRequest(id);
  }, [id]);

  const getEquipmentDetailsRequest = async (id) => {
    setLoading(true);
    setError(null);

    try {
      const response = await getEquipmentById(id);

      setEquipment(response.data);
      setLoading(false);
    } catch (err) {
      setError(strings.ERRORS.DEFAULT_ERROR);
      setLoading(false);
    }
  };

  const refresh = () => {
    getEquipmentDetailsRequest(id);
  };

  return { loading, error, equipment, refresh };
};

export default useEquipmentDetails;
