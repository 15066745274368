import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import React, { useContext, useState } from "react";
import { Fragment } from "react";
import useNetworkRequest from "../../../utils/useNetworkRequest";
import * as EquipmentService from "../../../services/equipmentService";
import { EquipmentWorkAssignmentContext } from "../../../context/equipment-work-assignment-context";
import CenterBox from "../../../components/UI/CenterBox";

const WorkAssignmentFormDialog = ({
  assignment,
  open,
  onClose,
  equipmentChecklistId,
}) => {
  const [assignmentState, setAssignmentState] = useState({
    id: assignment?.id,
    completed: assignment?.completedAt ? !!assignment.completedAt : false,
    workRequired: {
      value: assignment?.workRequired,
      error: null,
    },
    assignedTo: {
      value: assignment?.assignedTo,
    },
    completedAtDate: {
      value: assignment?.completedAt,
      error: null,
    },
    completedAtTime: {
      value: assignment?.completedAt,
      error: null,
    },
    completedAt: {
      value: assignment?.completedAt
        ? new Date(assignment?.completedAt)
        : new Date(),
      error: null,
    },
  });
  const { loading, error, handleRequest } = useNetworkRequest();
  const equipmentWorkAssignmentContext = useContext(
    EquipmentWorkAssignmentContext
  );

  const saveClickHandler = async () => {
    const state = { ...assignmentState };
    let hasError = false;

    // Validate
    if (!state.workRequired.value) state.workRequired.error = hasError = true;

    if (hasError) {
      setAssignmentState(state);
      return;
    }

    // Build request body
    const body = {
      workRequired: state.workRequired.value,
      assignedTo: state.assignedTo.value,
      completedAt: state.completed ? state.completedAt.value : null,
    };

    // ID is not null, user is updating a record.
    // Otherwise creating a new record
    if (state.id) {
      const request = () =>
        EquipmentService.updateEquipmentWorkAssignmentById(state.id, body);

      // Send request
      const result = await handleRequest(request);

      // Request was successful
      if (result) {
        // Update context
        equipmentWorkAssignmentContext.updateAssignment(+state.id, result);

        onClose(result);
      }
    } else {
      // Add the checklist ID that assignment will be linked to
      body.equipmentChecklistId = equipmentChecklistId;

      const request = () =>
        EquipmentService.createEquipmentWorkAssignment(body);

      // Send request
      const result = await handleRequest(request);

      // Request was successful
      if (result) {
        // Add new assignment to context
        equipmentWorkAssignmentContext.addAssignment(result);

        onClose(result);
      }
    }
  };

  const textChangeHandler = (event) => {
    const target = event.target;

    setAssignmentState((state) => ({
      ...state,
      [target.id]: {
        value: target.value,
      },
    }));
  };

  const completeSwitchChangeHandler = (event) => {
    setAssignmentState((state) => ({
      ...state,
      completed: event.target.checked,
    }));
  };

  const dateCompletedChangeHandler = (date) => {
    setAssignmentState((state) => ({
      ...state,
      completedAt: {
        value: date,
      },
    }));
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <Box px={3} py={2}>
        <Typography variant="h5" style={{ paddingBottom: "8px" }}>
          <strong>
            {assignmentState.id ? "Edit" : "Create"} Work Assignment
          </strong>
        </Typography>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              id="workRequired"
              fullWidth
              rows={3}
              multiline
              variant="outlined"
              label="Work Required"
              error={assignmentState.workRequired.error}
              value={assignmentState.workRequired.value}
              onChange={textChangeHandler}
            />
          </Grid>
          <Grid item>
            <TextField
              id="assignedTo"
              fullWidth
              variant="outlined"
              label="Assigned To"
              value={assignmentState.assignedTo.value}
              onChange={textChangeHandler}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  color="primary"
                  checked={assignmentState.completed}
                  onChange={completeSwitchChangeHandler}
                />
              }
              label="Assignment Complete?"
            />
          </Grid>
          <Grid item container direction="column" spacing={2}>
            {assignmentState.completed && (
              <Fragment>
                <Grid item>
                  <KeyboardDatePicker
                    fullWidth
                    label="Date Completed"
                    format="MMM D YYYY"
                    value={assignmentState.completedAt.value}
                    onChange={dateCompletedChangeHandler}
                  />
                </Grid>
                <Grid item>
                  <KeyboardTimePicker
                    fullWidth
                    label="Time Completed"
                    value={assignmentState.completedAt.value}
                    onChange={dateCompletedChangeHandler}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Grid>
        {error && <Typography color="error">{error}</Typography>}

        <Divider />
        <DialogActions>
          <Box display="flex" width={1} pt={1}>
            {loading && (
              <CenterBox marginTop={2}>
                <CircularProgress />
              </CenterBox>
            )}
            {!loading && (
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={saveClickHandler}
              >
                Save
              </Button>
            )}
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default WorkAssignmentFormDialog;
