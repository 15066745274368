import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import SaveButton from "../../UI/SaveButton";
import {
  createHospital,
  getHospitals,
  updateHospital,
} from "../../../services/emergencyNumberService";
import CenterBox from "../../UI/CenterBox";

const HospitalsDialog = ({ open, onClose, onSaveButtonClick }) => {
  const [tabValue, setTablValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hospital, setHospital] = useState({
    name: {
      value: "",
    },
    address: {
      value: "",
    },
  });

  const onTextChangeHandler = (event, id) => {
    const hospitalState = {
      ...hospital,
      [id]: {
        value: event.target.value,
      },
    };

    setHospital(hospitalState);
  };

  const onSaveButtonClickHandler = (event) => {
    const hospitalState = {
      ...hospital,
    };
    let hasError = false;

    if (!hospitalState.name.value) {
      hospitalState.name.error = true;
      hasError = true;
    }

    if (!hospitalState.address.value) {
      hospitalState.address.error = true;
      hasError = true;
    }

    if (hasError) {
      setHospital(hospitalState);
      return;
    }

    setLoading(true);

    const hospitalRequest = {
      name: hospitalState.name.value,
      address: hospitalState.address.value,
    };

    createHospital(hospitalRequest).then((response) => {
      setLoading(false);
      setHospital({
        name: {
          value: "",
        },
        address: {
          value: "",
        },
      });
      onSaveButtonClick();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h4">
          <strong>Hospitals</strong>
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box pb={1}>
          <Tabs
            value={tabValue}
            onChange={(event, value) => {
              setTablValue(value);
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Create" />
            <Tab label="Edit" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                label="Name"
                error={hospital.name.error}
                value={hospital.name.value}
                onChange={(event) => onTextChangeHandler(event, "name")}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                label="Address"
                error={hospital.address.error}
                value={hospital.address.value}
                onChange={(event) => onTextChangeHandler(event, "address")}
              />
            </Grid>
            <Grid item>
              {loading ? (
                <CircularProgress />
              ) : (
                <SaveButton onClick={onSaveButtonClickHandler} />
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <EditTabPanel />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

const EditTabPanel = () => {
  const [viewState, setViewState] = useState(0);
  return <HospitalsTable viewState={viewState} />;
};

const HospitalsTable = ({ viewState }) => {
  const [hospitals, setHospitals] = useState([]);
  const [selectedHosptial, setSelectedHospital] = useState({});
  const [loading, setLoading] = useState(true);
  const [refreshView, setRefreshView] = useState(0);

  const onEditRowClickHandler = (hospital) => {
    setSelectedHospital({
      id: hospital.id,
      name: {
        value: hospital.name,
      },
      address: {
        value: hospital.address,
      },
    });
  };

  const onEditTextChangeHandler = (event, id) => {
    const updatedSelectedHospital = {
      ...selectedHosptial,
      [id]: {
        ...selectedHosptial[id],
        value: event.target.value,
        error: false,
      },
    };

    setSelectedHospital(updatedSelectedHospital);
  };

  const onSaveEditHospitalHandler = () => {
    let hasError = false;
    const updatedSelectedHospital = {
      ...selectedHosptial,
    };

    if (!updatedSelectedHospital.name.value) {
      updatedSelectedHospital.name.error = true;
      hasError = true;
    }

    if (!updatedSelectedHospital.address.value) {
      updatedSelectedHospital.address.error = true;
      hasError = true;
    }

    if (hasError) {
      setSelectedHospital(updatedSelectedHospital);
      return;
    }

    setLoading(true);

    const hospitalData = {
      name: updatedSelectedHospital.name.value,
      address: updatedSelectedHospital.address.value,
    };
    updateHospital(updatedSelectedHospital.id, hospitalData).then(
      (response) => {
        setSelectedHospital({});
        setRefreshView(refreshView + 1);
      }
    );
  };

  useEffect(() => {
    getHospitals().then((response) => {
      setHospitals(response.data);
      setLoading(false);
    });
  }, [refreshView]);

  if (loading) {
    return (
      <Box minHeight={400}>
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      </Box>
    );
  }

  return (
    <CustomPaginationTable
      headers={["Name", "Address", "Action"]}
      paginationDisabled
    >
      {hospitals.map((hospital) => {
        return (
          <Fragment key={hospital.id}>
            {selectedHosptial.id === hospital.id ? (
              <TableRow>
                <TableCell align="left">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Name"
                    error={selectedHosptial.name.error}
                    value={selectedHosptial.name.value}
                    onChange={(event) => onEditTextChangeHandler(event, "name")}
                  />
                </TableCell>
                <TableCell align="left">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Address"
                    error={selectedHosptial.address.error}
                    value={selectedHosptial.address.value}
                    onChange={(event) =>
                      onEditTextChangeHandler(event, "address")
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveEditHospitalHandler}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left">
                  <Typography>{hospital.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{hospital.address}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onEditRowClickHandler(hospital)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </Fragment>
        );
      })}
    </CustomPaginationTable>
  );
};

const CustomPaginationTable = ({
  children,
  headers,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  paginationDisabled,
  ...other
}) => {
  return (
    <Table {...other}>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => {
            return (
              <TableCell
                align={index === headers.length - 1 ? "right" : "left"}
                key={index}
              >
                <strong>{header}</strong>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
      {!paginationDisabled && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </Table>
  );
};

export default HospitalsDialog;
