import React, { Component, Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import {
  Container,
  Typography,
  CssBaseline,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Grid,
  Tooltip,
  TextField,
  CircularProgress,
  TablePagination,
  Chip,
  InputAdornment,
} from "@material-ui/core";
import { Star, Search } from "@material-ui/icons";
import NewButton from "../../components/UI/NewButton";
import { useHistory } from "react-router-dom";
import CenterBox from "../../components/UI/CenterBox";
import { getAllContractors } from "../../services";

/*class Contractors extends Component {
  componentDidMount() {
    this.props.onGetContractors();
  }

  onContractorSelectedHandler = (event, id) => {
    this.props.history.push(`/contractors/${id}`);
  };

  createNewContractorHandler = (event) => {
    this.props.history.push("/contractors/create");
  };

  render() {
    /* let contractorCells = null;
    if (!this.props.loading && !this.props.error) {
      contractorCells = this.props.contractors.map((contractor) => {
        const contractorName = (
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <Typography>{contractor.name}</Typography>
            </Grid>
            <Grid item>
              {contractor.mainContractor ? (
                <Tooltip title="This is your main contractor">
                  <Star />
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
        );
        const address = contractor.address;
        const addressLabel = address ? address.addressLabel || "" : "";
        return (
          <TableRow
            hover
            key={contractor.id}
            onClick={(event) =>
              this.onContractorSelectedHandler(event, contractor.id)
            }
          >
            <TableCell component="th" scope="row">
              {contractorName}
            </TableCell>
            <TableCell align="right">{contractor.email}</TableCell>
            <TableCell align="right">{addressLabel}</TableCell>
            <TableCell align="right">{contractor.officePhone}</TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Container maxWidth="lg">
        <Box py={2}>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h4">
                  <Box fontWeight="fontWeightBold">Contractors</Box>
                </Typography>
              </Grid>
              <Grid item>
                <NewButton onClick={this.createNewContractorHandler}>
                  New Contractor
                </NewButton>
              </Grid>
            </Grid>
            <Grid item>
              <Box pb={2}>
                <ContractorsPaper
                  loading={this.props.loading}
                  error={this.props.error}
                  contractors={this.props.contractors}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contractors: state.contractor.contractors,
    loading: state.contractor.loading,
    error: state.contractor.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetContractors: () => dispatch(actions.getAllContractors()),
  };
};
 */
const Contractors = () => {
  const history = useHistory();

  const createNewContractorHandler = (event) => {
    history.push("/contractors/create");
  };

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold">Contractors</Box>
              </Typography>
            </Grid>
            <Grid item>
              <NewButton onClick={createNewContractorHandler}>
                New Contractor
              </NewButton>
            </Grid>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <ContractorsPaper />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const ContractorsPaper = (props) => {
  const history = useHistory();
  const [contractors, setContractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // State of the query view
  const [searchParams, setSearchParams] = useState({
    name: "",
  });

  // State of the current API query
  const [queryData, setQueryData] = useState({
    params: {
      name: "",
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const onContractorSelectedHandler = (event, id) => {
    history.push("/contractors/" + id);
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const nameSearchQueryChangeHandler = (event) => {
    const updatedSearchParams = {
      ...searchParams,
      name: event.target.value,
    };

    setSearchParams(updatedSearchParams);
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const onSearchButtonClickHandler = (event) => {
    const updatedQueryData = { ...queryData };
    const params = {};

    if (searchParams.name !== "") {
      params.name = searchParams.name;
    }

    params.limit = rowsPerPage;
    params.offset = 0;

    updatedQueryData.params = params;

    setQueryData(updatedQueryData);
    setPage(0);
  };

  const onClearSearchHandler = () => {
    const updatedPage = 0;
    const updatedQueryData = {
      params: {
        name: "",
        limit: rowsPerPage,
        offset: rowsPerPage * updatedPage,
      },
    };
    const updatedSearchParams = {
      name: "",
    };

    setSearchParams(updatedSearchParams);
    setQueryData(updatedQueryData);
    setPage(updatedPage);
  };

  useEffect(() => {
    setError(false);
    setLoading(true);
    console.log(queryData.params);
    getAllContractors({ params: queryData.params })
      .then((response) => {
        setContractors(response.data.contractors);
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [queryData]);

  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                type="search"
                label="Search Contractors"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" c>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={nameSearchQueryChangeHandler}
                value={searchParams.name}
              />
            </Grid>
            {queryData.params.name && (
              <Grid item>
                <Chip
                  label={`Search: '${queryData.params.name}'`}
                  variant="outlined"
                  color="primary"
                  onDelete={onClearSearchHandler}
                />
              </Grid>
            )}
          </Grid>

          <Grid item>
            <Box py={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onSearchButtonClickHandler}
              >
                <Box px={2}>Search</Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <CenterBox pb={8}>
          <CircularProgress />
        </CenterBox>
      ) : (
        <ContractorsTable
          contractors={contractors}
          onRowSelected={onContractorSelectedHandler}
          total={total}
          rowsPerPage={rowsPerPage}
          page={page}
          pageChangeHandler={pageChangeHandler}
          rowsPerPageChangeHandler={rowsPerPageChangeHandler}
        />
      )}
    </Paper>
  );
};

const ContractorsTable = (props) => {
  let contractorCells;

  if (!props.contractors || props.contractors.length === 0) {
    contractorCells = (
      <TableRow>
        <Box p={2}>
          <Typography>No Contractors to Display</Typography>
        </Box>
      </TableRow>
    );
  } else {
    contractorCells = props.contractors.map((contractor) => {
      const contractorName = (
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography>{contractor.name}</Typography>
          </Grid>
          <Grid item>
            {contractor.mainContractor ? (
              <Tooltip title="This is your main contractor">
                <Star color="primary" />
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>
      );
      const address = contractor.address;
      const addressLabel = address ? address.addressLabel || "" : "";
      return (
        <TableRow hover key={contractor.id}>
          <TableCell component="th" scope="row">
            {contractorName}
          </TableCell>
          <TableCell align="left">{contractor.email}</TableCell>
          <TableCell align="left">{addressLabel}</TableCell>
          <TableCell align="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => props.onRowSelected(event, contractor.id)}
            >
              <Box px={2}>View</Box>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Fragment>
      <Table style={{ tableLayout: "fixed", minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Email</strong>
            </TableCell>

            <TableCell align="left">
              <strong>Address</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{contractorCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
      {/* {certificationCells.length === 0 ? (
          <Typography>No Cetifications found</Typography>
        ) : null} */}
    </Fragment>
  );
};

export default Contractors;
