import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import strings from "../../../strings";

const EquipmentForm = ({
  equipment,
  onTextChangeHandler,
  submitClickHandler,
  error,
  loading,
}) => {
  return (
    <Box p={3}>
      <Grid container direction="column">
        <Grid item>
          <Box pb={2}>
            <Typography variant="h6">
              <strong>{`${equipment.id ? "Edit" : "Create"}`} Equipment</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <TextField
              id="name"
              fullWidth
              variant="outlined"
              label="Name"
              value={equipment.name?.value}
              error={equipment.name?.error}
              onChange={onTextChangeHandler}
            />
          </Grid>
          <Grid item>
            <TextField
              id="unitNumber"
              variant="outlined"
              label="Unit Number"
              value={equipment.unitNumber?.value}
              error={equipment.unitNumber?.error}
              onChange={onTextChangeHandler}
            />
          </Grid>
        </Grid>
        {error && (
          <Grid item>
            <Box pt={1}>
              <Typography color="error">{error}</Typography>
            </Box>
          </Grid>
        )}

        <Grid item>
          <Box pt={4} display="flex" justifyContent="center">
            {loading && <CircularProgress />}

            {!loading && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={submitClickHandler}
              >
                Save
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EquipmentForm;
