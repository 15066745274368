import { Tooltip } from "@material-ui/core";
import React from "react";
import DeleteButton from "../../../../components/UI/DeleteButton";

const DeleteProjectButton = ({ projectState, onClick }) => {
  if (projectState === 1) {
    return (
      <Tooltip title="Cannot delete project that is currently in-progress">
        <span>
          <DeleteButton onClick={onClick} disabled />
        </span>
      </Tooltip>
    );
  }
  return <DeleteButton onClick={onClick} />;
};

export default DeleteProjectButton;
