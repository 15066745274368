import moment from "moment";

export const dateToISOStringNoTime = (date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() + offset * 60 * 1000);
  console.log(`newDate ${newDate}`);
  return newDate.toISOString().split("T")[0];
};

export const formatToDisplayDate = (date) => {
  if (date == null) return "";

  return moment(date).format("dddd, MMMM Do YYYY, h:mm a");
};

export const formatDateWithoutDay = (date) => {
  if (date == null) return "";

  return moment(date).format("MMMM Do YYYY, h:mm a");
};

export const formatDateShort = (date) => {
  if (date == null) return "";

  return moment(date).format("MMM D YYYY, h:mm a");
};

export const showCurrentShortMonth = () => {
  return moment().format("MMM YYYY");
};

export const formatPhoneNumberInput = (phoneNumber) => {
  return phoneNumber.replace(/-|_/gi, function (matched) {
    return "";
  });
};
