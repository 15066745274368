import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ChecklistInfo from "../../../components/Checklists/Checklist/ChecklistInfo";
import * as checklistsService from "../../../services/checklistService";
import ChecklistItems from "../../../components/Checklists/Checklist/ChecklistItems";
import CenterBox from "../../../components/UI/CenterBox";

const ChecklistDetail = (props) => {
  const { id } = useParams();
  const [checklist, setChecklist] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    checklistsService
      .getChecklistById(id)
      .then((response) => {
        console.log(response.data);
        setChecklist(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading || error || !checklist) {
    return (
      <Container>
        <CenterBox>
          {" "}
          {error ? (
            <Typography>Error Occurred</Typography>
          ) : (
            <CircularProgress />
          )}{" "}
        </CenterBox>
      </Container>
    );
  }

  let checklistData = null;
  if (!isLoading && !error && checklist) {
    checklistData = (
      <React.Fragment>
        <Grid item>
          <ChecklistInfo checklist={checklist} />
        </Grid>
        <Grid item>
          <ChecklistItems
            type={checklist.type}
            checklistItems={checklist.checklistItems}
          />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Checklists</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              <strong>{checklist.name}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Box pt={2} />
            <Grid container spacing={2} direction="column">
              {checklistData}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
    /*   <Box px={2}>
        <Box mb={2}>
        {`Checklists`}

        </Box>
      <Grid container spacing={2}>
        {checklistData}
      </Grid>
    </Box> */
  );
};

export default ChecklistDetail;
