import React, { useState } from "react";

export const AuthContext = React.createContext({
  login: (name, type, accountName) => {},
  logout: () => {},
  authState: null,
});

const AuthContextProvider = (props) => {
  const [authState, setAuthState] = useState(null);

  const loginHandler = (
    name,
    type,
    accountName,
    token,
    expirationDate,
    userId
  ) => {
    setAuthState({
      token,
      expirationDate,
      name,
      type,
      accountName,
      userId,
    });
  };

  const logoutHandler = () => {
    setAuthState(null);
  };

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        authState,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
