import React, { useState } from "react";
import strings from "../../../../../../strings";
import * as EquipmentService from "../../../../../../services/equipmentService";

const useDeleteEquipmentDialog = (id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendDeleteRequest = async () => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await EquipmentService.deleteEquipmentById(id);

      setLoading(false);
      return true;
    } catch (err) {
      setError(strings.ERRORS.DEFAULT_ERROR);
      setLoading(false);
    }
  };

  return { loading, error, sendDeleteRequest };
};

export default useDeleteEquipmentDialog;
