import React from "react";
import {
  ListItem,
  ListItemText,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Typography,
  Paper
} from "@material-ui/core";

const TalkMembers = ({ talkMembers }) => {
  const talkMemberRows = talkMembers.map(member => {
    const isSigned = member.signature !== null;

    return (
      <TableRow hover key={member.id}>
        <TableCell component="th" scope="row">
          {member.worker.name}
        </TableCell>
        <TableCell align="right">
          {isSigned ? "Signed" : "Not Signed"}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box mx={1}>
        <Typography variant="h5">Talk Members</Typography>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{talkMemberRows}</TableBody>
      </Table>
    </Paper>
  );
};

export default TalkMembers;
