import { Box, Grid, List, ListItem, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React from 'react';

const CATEGORY_TWO_TEXT = {
  1: 'Previously Trained',
  2: 'Requires Training',
  3: 'Training Arranged',
  4: 'Training Completed',
  5: 'N / A',
};

const OrientationChecklistBody = ({ checklist }) => {
  const sections = checklist.checklistItems.map((item) => {
    let items = item.items.map((listItem) => {
      return (
        <ListItem>
          <Grid container spacing={2} direction="row" justify="space-between">
            <Grid item>
              <Typography>{listItem.name}</Typography>
            </Grid>
            <Grid item>
              {listItem.category !== 2 ? (
                <Box>
                  {listItem.state === 1 ? (
                    <Check color="primary" />
                  ) : (
                    <Typography color="primary">N / A</Typography>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography color="primary">
                    {CATEGORY_TWO_TEXT[listItem.state]}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </ListItem>
      );
    });

    return (
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h5">{item.header}</Typography>
        </Grid>
        <Grid item>
          <List>{items}</List>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid item container direction="column">
      {sections}
    </Grid>
  );
};

export default OrientationChecklistBody;
