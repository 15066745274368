import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { getAllProjects } from "../../services/projectService";
import CustomOutlinedSelect from "../UI/CustomOutlinedSelect";

const useRequestHandler = () => {
  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  const getProjectsRequest = () => {
    setIsLoading(true);
    setHasError(null);

    return getAllProjects()
      .then(response => {
        setProjects(response.data);
      })
      .catch(err => {
        setHasError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return { projects, isLoading, hasError, getProjectsRequest };
};

const ProjectSelect = ({ onChange, error, selected }) => {
  const {
    projects,
    isLoading,
    hasError,
    getProjectsRequest
  } = useRequestHandler();

  useEffect(() => {
    const projectsRequest = getProjectsRequest();
  }, []);

  let menuItems;

  if (hasError) {
    menuItems = (
      <MenuItem disabled value="">
        <em>Error Loading Projects</em>
      </MenuItem>
    );
  } else if (projects && !isLoading) {
    menuItems = projects.map(project => {
      const label = `${project.name} (${project.address.addressLabel})`;
      return (
        <MenuItem key={project.id} value={project.id}>
          {label}
        </MenuItem>
      );
    });
  } else {
    // menuItems = (
    //   <MenuItem disabled value="">
    //     <em>Loading...</em>
    //   </MenuItem>
    // );
  }

  return (
    <CustomOutlinedSelect
      label="Project"
      fullWidth
      disabled
      error={error}
      onChange={onChange}
      selected={selected}
      menuItems={menuItems}
    />
  );
};

export default ProjectSelect;
