import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box
} from "@material-ui/core";
import {
  getProjectENumberForm,
  deleteEmergencyNumberById
} from "../../../services/emergencyNumberService";
import EditButton from "../../../components/UI/EditButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import EmergencyNumberDetail from "../../../components/EmergencyNumbers/EmergencyNumber/EmergencyNumberDetail";
import PrintPDFButton from "../../../components/EmergencyNumbers/EmergencyNumber/PrintPDFButton";

const EmergencyNumberDetailScreen = props => {
  const { id } = useParams();
  const history = useHistory();
  const [eNumberState, setENumberState] = useState(null);
  const [isLoadingState, setIsLoadingState] = useState(false);

  const onEditClickHandler = event => {
    history.push("/emergency-numbers/" + eNumberState.id + "/edit");
  };

  const onDeleteClickHandler = () => {
    deleteEmergencyNumberById(eNumberState.id)
      .then(response => {
        history.replace("/projects/" + id);
      })
      .catch(err => {});
  };

  useEffect(() => {
    setIsLoadingState(true);
    getProjectENumberForm(id)
      .then(response => {
        setENumberState(response.data);
        setIsLoadingState(false);
      })
      .catch(err => {});
  }, []);

  let view;

  

  if (eNumberState && !isLoadingState) {
    const formName = `${eNumberState.project.name} Emergency Numbers Form`;
    view = (
      <Fragment>
        <Grid item>
          <Typography variant="h4">{formName}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2} justify="flex-start">
            <Grid item>
              <EditButton onClick={onEditClickHandler} />
            </Grid>
            <Grid item>
              <DeleteButton onClick={onDeleteClickHandler} />
            </Grid>
            <Grid item>
              <PrintPDFButton projectId={id}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <EmergencyNumberDetail eNumberForm={eNumberState} />
        </Grid>
      </Fragment>
    );
  } else {
    view = (
      <Fragment>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Fragment>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
      <Grid container direction="column" spacing={2}>
        {view}
      </Grid>
      </Box>
      
    </Container>
  );
};

export default EmergencyNumberDetailScreen;
