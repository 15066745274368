import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  Card,
  TextField,
  MenuItem,
  Paper,
  CircularProgress,
  Chip,
  InputAdornment,
} from "@material-ui/core";

import * as resourcesService from "../../services/resourcesService";
import ResourcesTable from "../../components/Resources/ResourcesTable";
import ResourcesSearch from "../../components/Resources/ResourcesSearch";
import { AddCircle, Search } from "@material-ui/icons";
import CustomOutlinedSelect from "../../components/UI/CustomOutlinedSelect";
import CenterBox from "../../components/UI/CenterBox";

const ResourcesScreen = (props) => {
  const history = useHistory();
  const [resources, setResources] = useState([]);
  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // State of the query view
  const [searchParams, setSearchParams] = useState({
    resourceTypes: {
      hsPolicies: true,
      safetyTalkDocuments: true,
      sds: true,
    },
    type: {
      id: 0,
      //label: 'All Types'
    },
    name: "",
  });

  // State of the current API query
  const [queryData, setQueryData] = useState({
    params: {
      name: "",
      type: [],
    },
  });
  const [error, setError] = useState(null);

  const onResourceClickHandler = (event, id) => {
    history.push(`resources/${id}`);
  };

  const onCreateResourceButtonHandler = () => {
    history.push("resources/new");
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
  };

  const querySelectHandler = (event) => {
    const updatedSearchParams = {
      ...searchParams,
      /*  resourceTypes: {
        ...searchParams.resourceTypes,
        [id]: event.target.checked,
      }, */
      type: {
        id: event.target.value,
      },
    };

    setSearchParams(updatedSearchParams);
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const nameSearchQueryChangeHandler = (event) => {
    const updatedSearchParams = {
      ...searchParams,
      name: event.target.value,
    };

    setSearchParams(updatedSearchParams);
  };

  const onSearchButtonClickHandler = (event) => {
    const updatedQueryData = { ...queryData };
    const params = {};

    if (searchParams.name !== "") {
      params.name = searchParams.name;
    }

    const resourceType = searchParams.type.id;
    params.type = [];

    if (resourceType === 0) {
      params.type = [1, 2, 3];
    } else {
      params.type = [resourceType];
    }

    updatedQueryData.params = params;

    setQueryData(updatedQueryData);
    setPage(0);
  };

  const onClearSearchHandler = () => {
    const updatedPage = 0;
    const updatedQueryData = {
      params: {
        ...queryData.params,
        name: "",
        limit: rowsPerPage,
        offset: rowsPerPage * updatedPage,
      },
    };
    const updatedSearchParams = {
      ...searchParams,
      name: "",
    };

    setSearchParams(updatedSearchParams);
    setQueryData(updatedQueryData);
    setPage(updatedPage);
  };

  useEffect(() => {
    // API request to get data
    setError(null);
    setIsLoading(true);
    resourcesService
      .getResources(page * rowsPerPage, rowsPerPage, queryData.params)
      .then((response) => {
        setIsLoading(false);
        setResources(response.data.resources);
        setTotal(response.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(true);
      });
  }, [queryData]);

  let resourcesTable = null;
  if (resources && !isLoading && !error) {
    resourcesTable = (
      <ResourcesTable
        resources={resources}
        total={total}
        rowsPerPage={rowsPerPage}
        page={page}
        pageChangeHandler={pageChangeHandler}
        onResourceSelected={onResourceClickHandler}
        rowsPerPageChangeHandler={rowsPerPageChangeHandler}
      />
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column" justify="flex-start">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold">Resources</Box>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddCircle />}
                onClick={onCreateResourceButtonHandler}
              >
                <Box px={2}>New Resource</Box>
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            <Box py={2}>
              <ResourcesCardTable
                searchParams={searchParams}
                queryData={queryData}
                querySelectHandler={querySelectHandler}
                onNameQueryChange={nameSearchQueryChangeHandler}
                onSearchClick={onSearchButtonClickHandler}
                onClearSearchHandler={onClearSearchHandler}
              >
                {isLoading ? (
                  <CenterBox marginBottom={8}>
                    <CircularProgress />
                  </CenterBox>
                ) : (
                  resourcesTable
                )}
              </ResourcesCardTable>
            </Box>
          </Grid>

          {/* <Grid item>{resourcesTable}</Grid> */}
        </Grid>
      </Box>
    </Container>
  );
};

const ResourcesCardTable = (props) => {
  const queryTypesMenuItems = [
    {
      id: 0,
      label: "All Types",
    },
    {
      id: 1,
      label: "Health and Safety Policy",
    },
    {
      id: 2,
      label: "Safety Talk Document",
    },
    {
      id: 3,
      label: "SDS",
    },
  ].map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.label}
    </MenuItem>
  ));

  return (
    <Paper>
      <Grid container direction="column">
        <Box p={2}>
          <Grid
            item
            container
            spacing={2}
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid
              lg
              item
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  type="search"
                  label="Search Resources"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" c>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={props.onNameQueryChange}
                  value={props.searchParams.name}
                />
              </Grid>
              {props.queryData.params.name && (
                <Grid item>
                  <Chip
                    label={`Search: '${props.queryData.params.name}'`}
                    variant="outlined"
                    color="primary"
                    onDelete={props.onClearSearchHandler}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CustomOutlinedSelect
                label="Resource Type"
                fullWidth
                onChange={props.querySelectHandler}
                selected={props.searchParams.type.id}
                menuItems={queryTypesMenuItems}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Box py={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={props.onSearchClick}
              >
                <Box px={2}>Search</Box>
              </Button>
            </Box>
          </Grid>
        </Box>
        <Grid item>{props.children}</Grid>
      </Grid>
    </Paper>
  );
};

export default ResourcesScreen;
