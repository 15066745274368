import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { getIncidentsOnProject } from "../../services/incidentService";
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
} from "@material-ui/core";
import CenterBox from "../UI/CenterBox";
import moment from "moment";
import { formatToDisplayDate } from "../../utils/util";

const ProjectIncidents = (props) => {
  const history = useHistory();
  const [incidents, setIncidents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [queryData, setQueryData] = useState({
    params: {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const onIncidentSelectedHandler = (event, id) => {
    history.push(`/incidents/${id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    getIncidentsOnProject(props.projectId, {
      ...queryData.params,
      order_by: 'reportedDate.desc'
    })
      .then((response) => {
        setIncidents(response.data);
        setTotal(response.data.length);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [queryData]);

  let renderedView;

  if (isLoading || error || !incidents) {
    renderedView = (
      <CenterBox>
        {error ? <Typography>Error Occurred</Typography> : <CircularProgress />}
      </CenterBox>
    );
  } else {
    renderedView = (
      <IncidentsTable
        incidents={incidents}
        viewIncidentClick={onIncidentSelectedHandler}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        pageChangeHandler={pageChangeHandler}
        rowsPerPageChangeHandler={rowsPerPageChangeHandler}
      />
    );
  }

  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box m={1}>
        <Typography variant="h5">Incidents</Typography>
      </Box>
      {renderedView}
    </Paper>
  );
};

const IncidentsTable = ({
  incidents,
  viewIncidentClick,
  total,
  rowsPerPage,
  page,
  pageChangeHandler,
  rowsPerPageChangeHandler,
}) => {
  const safetyTalkRows = incidents.map((incident) => {
    return (
      <TableRow hover key={incident.id}>
        <TableCell component="th" scope="row">
          {`Incident #${incident.id}`}
        </TableCell>
        <TableCell align="left">
          {incident.state === 1 ? "Complete" : "Reported"}
        </TableCell>
        <TableCell align="left">
          {formatToDisplayDate(incident.reportedDate)}
        </TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => viewIncidentClick(event, incident.id)}
          >
            <Box px={2}>View</Box>
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Fragment>
      <Table style={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>State</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Date Reported</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{safetyTalkRows}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={pageChangeHandler}
        onChangeRowsPerPage={rowsPerPageChangeHandler}
      />
    </Fragment>
  );
};

export default ProjectIncidents;
