import { Box, Divider, Tab, Tabs } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Fragment } from "react";
import EquipmentChecklistItems from "./EquipmentChecklistItems";
import WorkAssignmentsTab from "./WorkAssignmentsTab";

const DetailsTab = ({ checklist }) => {
  const [tabValue, setTabValue] = useState(0);

  const tabValueChangeHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const memoizedChecklistItems = useMemo(() => {
    return (
      <EquipmentChecklistItems items={checklist.equipmentChecklistItems} />
    );
  }, [checklist]);

  return (
    <Fragment>
      <Box>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={tabValueChangeHandler}
        >
          <Tab label="Checklist Items" />
          <Tab label="Work Assignments" />
        </Tabs>
        <Divider style={{ marginBottom: "16px" }} />

        <div role="tabpanel" hidden={tabValue !== 0}>
          {memoizedChecklistItems}
        </div>
        <div role="tabpanel" hidden={tabValue !== 1}>
          <WorkAssignmentsTab />
        </div>
      </Box>
    </Fragment>
  );
};

export default DetailsTab;
