import React, { Fragment } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';
import CountrySelect from '../../UI/CountrySelect';
import ProvinceSelect from '../../UI/ProvinceSelect';
import SaveButton from '../../UI/SaveButton';
import InputMask from 'react-input-mask';
import CustomOutlinedSelect from '../../UI/CustomOutlinedSelect';
import PhoneNumberInputMask from '../../UI/PhoneNumberInputMask/PhoneNumberInputMask';

const WorkerForm = ({
  workerData,
  accountInfo,
  onTextChange,
  edit,
  onContractorSelectClick,
  onEmployeeTypeChange,
  onSaveClick,
}) => {
  let accountTypeView;
  accountTypeView = (
    <Fragment>
      <Grid item>
        <Typography>Account Type:</Typography>

        <RadioGroup
          value={workerData.isUser ? 'User' : 'Worker'}
          onChange={onEmployeeTypeChange}
        >
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <FormControlLabel
                value="Worker"
                control={<Radio />}
                label="Worker"
                disabled={workerData.currentUser}
              />
            </Grid>
            <Grid item>
              <Typography>*Regular worker that stores information</Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <FormControlLabel
                value="User"
                control={<Radio />}
                label="User"
                disabled={
                  workerData.currentUser ||
                  accountInfo.numUsers >= accountInfo.maxUsers
                }
              />
            </Grid>
            <Grid item>
              <Typography>
                *All features as a Worker, but with login access to the system
                for creating content
              </Typography>
            </Grid>

            {
              <Grid item>
                <Box border={1} p={1}>
                  <Typography
                    variant="h6"
                    color={
                      accountInfo.numUsers >= accountInfo.maxUsers
                        ? 'error'
                        : 'inherit'
                    }
                  >
                    Seats Remaining: {accountInfo.numUsers} /{' '}
                    {accountInfo.maxUsers}
                  </Typography>
                </Box>
              </Grid>
            }
          </Grid>
        </RadioGroup>
      </Grid>
      {workerData.isUser ? (
        <Fragment>
          <Grid item>
            <CustomOutlinedSelect
              label="Role"
              required
              fullWidth
              disabled={edit && workerData.role.value == 1}
              error={workerData.role.error}
              onChange={(event) => onTextChange(event, 'role')}
              selected={workerData.role.value}
              menuItems={[
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>,
                <MenuItem disabled={true} value="1">
                  Admin
                </MenuItem>,
                <MenuItem value="2">Safety Coordinator</MenuItem>,
                <MenuItem value="3">Employee</MenuItem>,
              ]}
            />
          </Grid>
          <Grid item>
            <Typography>
              {workerData.role.value == 2
                ? 'SAFETY COORDINATOR: This user role completes checklists, safety talks, incidents, and manages the site'
                : workerData.role.value == 3
                ? 'EMPLOYEE: This user role completes Inspection Findings and Incident Corrective Actions that are assigned to them'
                : ''}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="emailTextView"
              fullWidth
              required
              label="Email"
              value={workerData.email.value}
              error={workerData.email.error}
              helperText={workerData.email.helperText}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'email')}

              //onChange={event => onTextChangedHandler(event, "email")}
            />
          </Grid>

          {/* <Grid item>
            <TextField
              id="usernameTextView"
              fullWidth
              required
              disabled={edit && workerData.userExists}
              label="Username"
              value={workerData.username.value}
              error={workerData.username.error}
              variant="outlined"
              onChange={(event) => onTextChange(event, "username")}

              //onChange={event => onTextChangedHandler(event, "email")}
            />
          </Grid> */}

          {!edit || (edit && workerData.userExists === false) ? (
            <Grid item>
              <TextField
                id="passwordTextView"
                fullWidth
                required
                label="Password"
                value={workerData.password.value}
                error={workerData.password.error}
                variant="outlined"
                onChange={(event) => onTextChange(event, 'password')}

                //onChange={event => onTextChangedHandler(event, "email")}
              />
            </Grid>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h4">{edit ? 'Edit' : 'New'} Worker</Typography>
        <Box mb={2} />
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography>Personal Information</Typography>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                required
                id="firstnameTextField"
                label="First Name"
                value={workerData.firstName.value}
                error={workerData.firstName.error}
                variant="outlined"
                onChange={(event) => onTextChange(event, 'firstName')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                required
                id="lastnameTextView"
                label="Last Name"
                value={workerData.lastName.value}
                error={workerData.lastName.error}
                variant="outlined"
                onChange={(event) => onTextChange(event, 'lastName')}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              id="titleTextView"
              fullWidth
              label="Job Title"
              value={workerData.title.value}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'title')}
            />
          </Grid>
          <Grid item>
            <TextField
              id="emailTextView"
              fullWidth
              label="Email"
              value={workerData.email.value}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'email')}
            />
          </Grid>
          <Grid item>
            <PhoneNumberInputMask
              value={workerData.phoneNumber.value}
              onChange={(event) => onTextChange(event, 'phoneNumber')}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>Address</Typography>
          </Grid>
          <Grid item>
            <CountrySelect
              fullWidth
              error={workerData.country.error}
              selected={workerData.country.value}
              onChange={(event) => onTextChange(event, 'country')}
            />
          </Grid>
          <Grid item>
            <ProvinceSelect
              fullWidth
              error={workerData.state.error}
              selected={workerData.state.value}
              onChange={(event) => onTextChange(event, 'state')}
            />
          </Grid>
          <Grid item>
            <TextField
              id="cityTextView"
              fullWidth
              label="City"
              value={workerData.city.value}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'city')}
            />
          </Grid>
          <Grid item>
            <TextField
              id="addressTextView"
              fullWidth
              label="Address"
              value={workerData.address1.value}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'address1')}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>Emergency Contact</Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="eContactName"
              label="Emergency Contact Name"
              value={workerData.eContactName.value}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'eContactName')}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="eContactPhone"
              label="Emergency Contact Phone Number"
              value={workerData.eContactPhone.value}
              variant="outlined"
              onChange={(event) => onTextChange(event, 'eContactPhone')}
            />
          </Grid>
          <Grid item>
            <Typography>Account Information</Typography>
          </Grid>

          {accountTypeView}

          <Grid item>
            <TextField
              id="contractorTextView"
              fullWidth
              required
              value={
                workerData.contractor
                  ? workerData.contractor.name
                  : '[Select Contractor]'
              }
              disabled={workerData.isUser}
              InputProps={{ readOnly: true }}
              onClick={onContractorSelectClick}
              error={!workerData.contractor}
              label="Contractor"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <SaveButton
              onClick={onSaveClick}
              disabled={
                !edit &&
                accountInfo.numUsers >= accountInfo.maxUsers &&
                workerData.isUser
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default WorkerForm;
