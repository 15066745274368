import { Box, Container } from "@material-ui/core";
import React from "react";

const MainViewContainer = ({ children }) => {
  return (
    <Container maxWidth="lg">
      <Box py={3}>{children}</Box>
    </Container>
  );
};

export default MainViewContainer;
