import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import FileDownload from "js-file-download";

import {
  getResourceById,
  deleteResource,
} from "../../../services/resourcesService";

import { downloadWithResourceUrl } from "../../../services/downloadsService";
import ResourceDetail from "../../../components/Resources/Resource/ResourceDetail";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Grid,
} from "@material-ui/core";
import EditButton from "../../../components/UI/EditButton";
import DeleteButton from "../../../components/UI/DeleteButton";

const ResourceDetailScreen = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [resource, setResource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    getResourceById(id)
      .then(response => {
        setIsLoading(false);
        setResource(response.data);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
      });
  }, []);

  const deleteResourceClickHandler = () => {
    setIsLoading(true);
     deleteResource(id)
      .then((response) => {
        history.replace("/resources");
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
        console.log(err);
      }); 
  };

  const editResourceClickHandler = () => {
    history.push(`${history.location.pathname}/edit`);
  };

  const downloadResourceClickHandler = (event, url, filename) => {
    event.preventDefault();
    downloadWithResourceUrl(url).then((response) => {
      FileDownload(response.data, filename);
    });
  };

  if (hasError || isLoading || !resource) {
    return (
      <Box marginTop={16} display="flex" justifyContent="center">
        {isLoading || !resource? (
          <CircularProgress />
        ) : (
        <Typography>Error occurred...</Typography>
        )}
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Resources</Typography>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="h4">
                    <Box fontWeight="fontWeightBold">
                      {resource ? resource.name : ""}
                    </Box>
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <EditButton onClick={editResourceClickHandler} />
                    </Grid>
                    <Grid item>
                      <DeleteButton onClick={deleteResourceClickHandler} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item>
            <ResourceDetail
              resource={resource}
              deleteResourceClick={deleteResourceClickHandler}
              editResourceClick={editResourceClickHandler}
              viewFileClick={downloadResourceClickHandler}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ResourceDetailScreen;
