import {
  Box,
  Button,
  CircularProgress,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Assessment,
  Beenhere,
  Build,
  ExpandMore,
  Visibility,
} from "@material-ui/icons";

import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CenterBox from "../../components/UI/CenterBox";
import { getHazardActivitiesOnProject } from "../../services/riskAssessmentService";
import RatingBox from "./components/RatingBox";
import RiskAssessmentDetails from "./components/RiskAssessmentDetails";
import { formatDateShort } from "../../utils/util";

const RiskAssessments = () => {
  const [hazardActivitiesState, setHazardActivitiesState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getHazardActivitiesOnProject(id);
        setHazardActivitiesState(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    getData();
  }, [id]);

  if (loading)
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  if (error) {
    return (
      <CenterBox>
        <Typography>Something went wrong. Please try again later.</Typography>
      </CenterBox>
    );
  }

  let hazardActivities;

  if (hazardActivitiesState.activities.length === 0) {
    hazardActivities = (
      <Typography variant="h5">No Hazard Activities to display</Typography>
    );
  } else {
    hazardActivities = hazardActivitiesState.activities.map((entry) => {
      return (
        <ExpansionPanel
          key={entry.id}
          TransitionProps={{ unmountOnExit: true }}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item>
                <Typography variant="h5">{entry.name}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {formatDateShort(entry?.createdAt)}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <RiskAssessmentDetails hazardActivityId={entry.id} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  }

  return (
    <Container>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h4">Risk Assessment</Typography>
        </Grid>
        <Box height={8} />
        <Grid item>
          <Typography>Hazard Activities</Typography>
        </Grid>
        <Box height={8} />
        {loading ? <CircularProgress /> : <Grid item>{hazardActivities}</Grid>}
      </Grid>
    </Container>
  );
};

/* const RiskAssessmentDetails = ({ hazardActivityId }) => {
  const [hazardActivity, setHazardActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getHazardActivityDetails(hazardActivityId);
        setHazardActivity(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    getData();
  }, [hazardActivityId]);

  if (loading)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  if (error) {
    return (
      <Box>
        <Typography>Something went wrong. Please try again later.</Typography>
      </Box>
    );
  }

  return (
    <Grid container direction="column">
      {hazardActivity.hazardTasks.length === 0 ? (
        <Typography>No Tasks to Display</Typography>
      ) : (
        hazardActivity.hazardTasks.map((task) => {
          return (
            <Fragment>
              <Grid item>
                <Box px={2} bgcolor="primary.light">
                  <Typography>
                    <strong>{task.name}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Potential Hazards</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Risks</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Risk Rating</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Controls</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Residual Risk</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Monitor and Review</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {task.hazards.map((hazard) => {
                      return (
                        <TableRow key={hazard.id}>
                          <TableCell>{hazard.name}</TableCell>
                          <TableCell>{hazard.risk}</TableCell>
                          <TableCell>
                            <RatingBox
                              severity={hazard.riskSeverity}
                              probability={hazard.riskProbability}
                              frequency={hazard.riskFrequency}
                            />
                          </TableCell>
                          <TableCell>{hazard.controls}</TableCell>
                          <TableCell>
                            <RatingBox
                              severity={hazard.resRiskSeverity}
                              probability={hazard.resRiskProbability}
                              frequency={hazard.resRiskSeverity}
                            />
                          </TableCell>
                          <TableCell>{hazard.review}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Fragment>
          );
        })
      )}
    </Grid>
  );
}; */

export default RiskAssessments;
