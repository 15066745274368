import React, { useEffect, useState, Fragment } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Button,
  Link,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  CardMedia,
} from "@material-ui/core";
import CenterBox from "../../../components/UI/CenterBox";
import { useParams, useHistory } from "react-router-dom";
import {
  getIncidentById,
  getCorrectiveActionsOnIncident,
  getCorrectiveActionById,
} from "../../../services/incidentService";
import { formatToDisplayDate } from "../../../utils/util";
import EditButton from "../../../components/UI/EditButton";
import CustomPaginationTable from "../../../components/UI/CustomPaginationTable";
import { HourglassFull, CheckCircle } from "@material-ui/icons";
import { orange, green } from "@material-ui/core/colors";
import strings from "../../../strings";

const IncidentDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [incident, setIncident] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onEditIncidentClickHandler = () => {
    history.push(`/incidents/${id}/edit`);
  };

  const onViewWorkerClickHandler = (event, id) => {
    event.preventDefault();
    history.push(`/workers/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    getIncidentById(id)
      .then((response) => {
        setIncident(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (error || loading || !incident) {
    return (
      <CenterBox>
        {error ? <Typography>Error Occurred</Typography> : <CircularProgress />}
      </CenterBox>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography>Incidents</Typography>
          </Grid>
          <Grid container direction="row" spacing={2} justify="space-between">
            <Grid item>
              <Typography variant="h4">
                <strong>Incident Details</strong>
              </Typography>
            </Grid>
            <Grid item>
              <EditButton onClick={onEditIncidentClickHandler} />
            </Grid>
          </Grid>

          <Grid item>
            <Box pt={2} />
            <Paper>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="caption">Worker Involved</Typography>
                    <Typography variant="h6">
                      <Link
                        href={`/workers/${incident.workerInvolved.id}`}
                        onClick={(event) =>
                          onViewWorkerClickHandler(
                            event,
                            incident.workerInvolved.id
                          )
                        }
                      >
                        {`${incident.workerInvolved.firstName} ${incident.workerInvolved.lastName}`}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">State</Typography>
                    <Typography variant="h6">
                      {incident.state === 1
                        ? "Reviewed / Completed"
                        : "Reported"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Reported By</Typography>
                    <Typography variant="h6">
                      <Link
                        href={`/workers/${incident.reportedBy.id}`}
                        onClick={(event) =>
                          onViewWorkerClickHandler(
                            event,
                            incident.reportedBy.id
                          )
                        }
                      >
                        {`${incident.reportedBy.firstName} ${incident.reportedBy.lastName}`}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Date and Time of Incident
                    </Typography>
                    <Typography variant="h6">
                      {`${formatToDisplayDate(incident.incidentDate)}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Date and Time Last Worked
                    </Typography>
                    <Typography variant="h6">
                      {`${formatToDisplayDate(incident.lastWorkedDate)}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Date and Time Reported
                    </Typography>
                    <Typography variant="h6">
                      {`${formatToDisplayDate(incident.reportedDate)}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Were any sub-contractors involved
                    </Typography>
                    <Typography variant="h6">
                      {incident.subContractorsInvolved ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">MOL called</Typography>
                    <Typography variant="h6">
                      {incident.MOLCalled ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">MOL Rep</Typography>
                    <Typography variant="h6">{incident.MOLRep}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Location</Typography>
                    <Typography variant="h6">{incident.location}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Cause of Injury</Typography>
                    <Typography variant="h6">{incident.description}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Worker's Activities
                    </Typography>
                    <Typography variant="h6">
                      {incident.workerActivitiesAtTime}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Hospital Attended</Typography>
                    <Typography variant="h6">
                      {incident.hospitalAttended}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Physician Name</Typography>
                    <Typography variant="h6">
                      {incident.physicianName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Notes</Typography>
                    <pre>
                      <Typography>{incident.notes}</Typography>
                    </pre>
                  </Grid>
                  <Grid item>
                    <hr />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <strong>Body Parts Injured</strong>
                    </Typography>
                  </Grid>
                  {incident.bodyParts
                    .filter((part) => {
                      return part.selected === true;
                    })
                    .map((part) => {
                      return (
                        <Grid item key={part.id}>
                          <ul>
                            <Typography>{part.text}</Typography>
                          </ul>
                        </Grid>
                      );
                    })}

                  <Grid item>
                    <hr />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <strong>Incident Types</strong>
                    </Typography>
                  </Grid>
                  {incident.incidentTypes
                    .filter((type) => {
                      return type.selected === true;
                    })
                    .map((type) => {
                      return (
                        <Grid item key={type.id}>
                          <ul>
                            <Typography>{type.text}</Typography>
                          </ul>
                        </Grid>
                      );
                    })}
                  <Grid item>
                    <hr />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <strong>Witnesses</strong>
                    </Typography>
                  </Grid>
                  {incident.witnesses.map((witness) => {
                    return (
                      <ul key={witness.worker.id}>
                        <Grid
                          item
                          container
                          key={witness.worker.id}
                          component="div"
                          spacing={2}
                        >
                          <Grid item>
                            <Typography>
                              <strong>{`${witness.worker.firstName} ${witness.worker.lastName}`}</strong>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>{witness.statement}</Typography>
                          </Grid>
                        </Grid>
                      </ul>
                    );
                  })}

                  <Grid item>
                    <hr />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <strong>Causes</strong>
                    </Typography>
                  </Grid>
                  {incident.causes
                    .filter((cause) => {
                      return cause.selected === true;
                    })
                    .map((cause) => {
                      return (
                        <Grid item key={cause.id}>
                          <ul>
                            <Typography>{cause.text}</Typography>
                          </ul>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item>
            <IncidentCorrectiveActions id={id} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const IncidentCorrectiveActions = ({ id }) => {
  const [correctiveActions, setCorrectiveActions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCorrectiveAction, setSelectedCorrectiveAction] = useState({});

  useEffect(() => {
    getCorrectiveActionsOnIncident(id).then((response) => {
      setCorrectiveActions(response.data);
    });
  }, []);

  return (
    <Fragment>
      <CorrectiveActionDetails
        open={dialogOpen}
        actionId={selectedCorrectiveAction.id}
        onClose={() => {
          setDialogOpen(false);
        }}
      />

      <Paper>
        <Box p={2}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5">
                <strong>Corrective Actions</strong>
              </Typography>
            </Grid>
            <Grid item>
              <CustomPaginationTable
                headers={["Name", "State", "Action"]}
                paginationDisabled
              >
                {correctiveActions.length === 0 ? (
                  <TableRow>
                    <TableCell>
                      <Typography>No Corrective Actions to Display</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  correctiveActions.map((action) => {
                    return (
                      <TableRow key={action.id}>
                        <TableCell aligh="left">
                          <Typography>
                            {strings.CORRECTIVE_ACTIONS[action.actionTaken] ||
                              action.actionTaken}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {action.state === true ? (
                              <CheckCircle style={{ color: green[500] }} />
                            ) : (
                              <HourglassFull style={{ color: orange[600] }} />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setSelectedCorrectiveAction(action);
                              setDialogOpen(true);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </CustomPaginationTable>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
};

const CorrectiveActionDetails = ({ open, onClose, actionId }) => {
  const [correctiveAction, setCorrectiveAction] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!open) {
      setCorrectiveAction({});
      return;
    }
    setLoading(true);
    getCorrectiveActionById(actionId).then((response) => {
      setLoading(false);
      setCorrectiveAction(response.data);
    });
  }, [actionId, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box p={2}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" justify="space-between">
              <Grid item>
                <Typography variant="h5">
                  <strong>
                    {strings.CORRECTIVE_ACTIONS[correctiveAction.actionTaken] ||
                      correctiveAction.actionTaken}
                  </strong>
                </Typography>
              </Grid>
              <Grid item>
                {correctiveAction.state === true ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <HourglassFull style={{ color: orange[600] }} />
                )}
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="caption">Date Created</Typography>
                <Typography>
                  {formatToDisplayDate(correctiveAction.createdAt)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Date Completed</Typography>
                <Typography>
                  {correctiveAction.state === true ? (
                    formatToDisplayDate(correctiveAction.updatedAt)
                  ) : (
                    <i>Awaiting Completion</i>
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Assigned To</Typography>
                <Typography>
                  {correctiveAction.assignedUser &&
                    `${correctiveAction.assignedUser.firstName} ${correctiveAction.assignedUser.lastName}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Description</Typography>
                <Typography>{correctiveAction.description}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Picture</Typography>
                {correctiveAction.picture ? (
                  <Box>
                    <img
                      src={`${correctiveAction.pictureSignedUrl}`}
                      title="Corrective Action Picure"
                      alt="img"
                      style={{ height: 400, objectFit: "contain" }}
                    />
                  </Box>
                ) : (
                  <Typography>No picture to Display</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Dialog>
  );
};

export default IncidentDetails;
