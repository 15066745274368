import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import React from "react";
import BasePaper from "../../../../components/UI/BasePaper";
import EquipmentChecklistsTable from "./EquipmentChecklistsTable/EquipmentChecklistsTable";

const ChecklistsPaper = ({ equipment }) => {
  return (
    <BasePaper>
      <Grid container direction="column" spacing={1}>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h6">
              <strong>Checklists</strong>
            </Typography>
          </Grid>
          {/* <Grid item>
            <Button color="primary" startIcon={<AddBox />}>
              <Box pt={0.5}>Create Equipment/Vehicle Pre-Start Checklist</Box>
            </Button>
          </Grid> */}
        </Grid>
        <Grid item>
          <EquipmentChecklistsTable equipmentId={equipment.id} />
        </Grid>
      </Grid>
    </BasePaper>
  );
};

export default ChecklistsPaper;
