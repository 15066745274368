import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getProjectById,
  createProject,
  editProject,
} from "../../../services/projectService";
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CustomOutlinedSelect from "../../../components/UI/CustomOutlinedSelect";
import ProvinceSelect from "../../../components/UI/ProvinceSelect";
import CountrySelect from "../../../components/UI/CountrySelect";
import SaveButton from "../../../components/UI/SaveButton";
import CenterBox from "../../../components/UI/CenterBox";

const ProjectFormScreen = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [projectState, setProjectState] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [errorState, setErrorState] = useState(null);

  const onTextChangedHandler = (event, id) => {
    const updatedProjectState = {
      ...projectState,
      [id]: {
        ...projectState[id],
        value: event.target.value,
      },
    };

    setProjectState(updatedProjectState);
  };

  const onDateChangeHandler = (date, id) => {
    const updatedProjectState = {
      ...projectState,
      [id]: {
        ...projectState[id],
        value: date,
      },
    };

    setProjectState(updatedProjectState);
  };

  // const onSelectChangeHandler = (selected, id) => {
  //   const updatedProjectState = {
  //     ...projectState,
  //     [id]: {
  //       ...projectState[id],
  //       value: date
  //     }
  //   };

  //   setProjectState(updatedProjectState);
  // }

  const onSaveProjectButtonClickHandler = () => {
    const updatedProjectState = {
      ...projectState,
    };

    let hasError = false;
    if (projectState.name.value === "") {
      updatedProjectState.name.error = true;
      hasError = true;
    }

    if (projectState.startDate.value === "") {
      updatedProjectState.startDate.error = true;
      hasError = true;
    }

    if (projectState.endDate.value === "") {
      updatedProjectState.endDate.error = true;
      hasError = true;
    }
    if (!projectState.projectState.value) {
      updatedProjectState.projectState.error = true;
      hasError = true;
    }

    if (!projectState.country.value) {
      updatedProjectState.country.error = true;
      hasError = true;
    }

    if (!projectState.state.value) {
      updatedProjectState.state.error = true;
      hasError = true;
    }

    if (!projectState.city.value) {
      updatedProjectState.city.error = true;
      hasError = true;
    }

    if (!projectState.address1.value) {
      updatedProjectState.address1.error = true;
      hasError = true;
    }

    setProjectState(updatedProjectState);

    if (hasError) return;
    setLoadingState(true);

    const projectRequestEntity = {
      name: projectState.name.value,
      projectNumber: projectState.projectNumber.value,
      clientName: projectState.clientName.value,
      address: {
        address1: projectState.address1.value,
        city: projectState.city.value,
        addressStateId: projectState.state.value,
        addressCountryId: projectState.country.value,
      },
      startDate: projectState.startDate.value,
      endDate: projectState.endDate.value,
      state: projectState.projectState.value,
    };

    if (id) {
      editProject(projectRequestEntity, id)
        .then((response) => {
          history.push("/projects/" + response.data.id);
        })
        .catch((err) => {
          setErrorState(err);
          setLoadingState(false);
        });
    } else {
      createProject(projectRequestEntity)
        .then((response) => {
          history.push("/projects/" + response.data.id);
        })
        .catch((err) => {
          setErrorState(err);
          setLoadingState(false);
        });
    }
  };

  useEffect(() => {
    setLoadingState(true);

    // If theres an id key, edit that project
    if (id) {
      getProjectById(id)
        .then((response) => {
          const projectJSON = response.data;
          const addressJSON = projectJSON.address;
          const updatedProjectState = {
            name: {
              value: projectJSON.name || "",
              error: false,
            },
            projectNumber: {
              value: projectJSON.projectNumber || "",
            },
            clientName: {
              value: projectJSON.clientName || "",
            },
            projectState: {
              value: projectJSON.state || 0,
            },
            country: {
              value: addressJSON.country ? addressJSON.country.id : "" || "",
              error: false,
            },
            state: {
              value: addressJSON.state ? addressJSON.state.id : "" || "",
              error: false,
            },
            city: {
              value: addressJSON.city || "",
              error: false,
            },
            address1: {
              value: addressJSON.address1 || "",
              error: false,
            },
            startDate: {
              value: projectJSON.startDate || new Date(),
              error: false,
            },
            endDate: {
              value: projectJSON.endDate || new Date(),
              error: false,
            },
          };

          setProjectState(updatedProjectState);
          setLoadingState(false);
        })
        .catch((err) => {
          setErrorState(err);
          setLoadingState(false);
        });
    }

    // Otherwise, user is creating a project
    else {
      const updatedProjectState = {
        name: {
          value: "",
          error: false,
        },
        projectNumber: {
          value: "",
        },
        clientName: {
          value: "",
        },
        projectState: {
          value: null,
          error: false,
        },
        country: {
          value: 1,
          error: false,
        },

        state: {
          value: "",
          error: false,
        },
        city: {
          value: "",
          error: false,
        },
        address1: {
          value: "",
          error: false,
        },
        startDate: {
          value: new Date(),
          error: false,
        },
        endDate: {
          value: new Date(),
          error: false,
        },
      };

      setProjectState(updatedProjectState);
      setLoadingState(false);
    }
  }, []);

  let dataDisplay;

  if (loadingState || errorState || !projectState) {
    dataDisplay = (
      <CenterBox>
        {errorState ? (
          <Typography>Error Occurred</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  } else {
    dataDisplay = (
      <Paper>
        <Box p={2}>
          <Box pb={2}>
            <Typography variant="h4">
              {id ? "Edit" : "Create"} Project
            </Typography>
          </Box>
          <Grid container spacing={2} direction="column" alignItems="stretch">
            <Grid item>
              <TextField
                fullWidth
                required
                id="nameTextField"
                label="Name"
                value={projectState.name.value}
                error={projectState.name.error}
                variant="outlined"
                onChange={(event) => onTextChangedHandler(event, "name")}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="projectNumberTextField"
                label="Project Number"
                value={projectState.projectNumber.value}
                variant="outlined"
                onChange={(event) =>
                  onTextChangedHandler(event, "projectNumber")
                }
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="clientNameTextField"
                label="Client Name"
                value={projectState.clientName.value}
                variant="outlined"
                onChange={(event) => onTextChangedHandler(event, "clientName")}
              />
            </Grid>
            <Grid item>
              <CustomOutlinedSelect
                label="State"
                fullWidth
                required
                selected={projectState.projectState.value}
                error={projectState.projectState.error}
                onChange={(event) =>
                  onTextChangedHandler(event, "projectState")
                }
                menuItems={[
                  <MenuItem value={1}>In-Progress</MenuItem>,
                  <MenuItem value={2}>Complete</MenuItem>,
                ]}
              />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Typography>Location</Typography>
            </Grid>
            <Grid item>
              <CountrySelect
                fullWidth
                required
                error={projectState.country.error}
                selected={projectState.country.value}
                onChange={(event) => onTextChangedHandler(event, "country")}
              />
            </Grid>
            <Grid item>
              <ProvinceSelect
                fullWidth
                required
                error={projectState.state.error}
                selected={projectState.state.value}
                onChange={(event) => onTextChangedHandler(event, "state")}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                required
                id="cityTextField"
                label="City"
                value={projectState.city.value}
                error={projectState.city.error}
                variant="outlined"
                onChange={(event) => onTextChangedHandler(event, "city")}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                required
                id="addressTextField"
                label="Address"
                value={projectState.address1.value}
                error={projectState.address1.error}
                variant="outlined"
                onChange={(event) => onTextChangedHandler(event, "address1")}
              />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item container spacing={4}>
              <Grid item md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  variant="inline"
                  format="YYYY-MM-DD"
                  margin="normal"
                  label="Start Date"
                  value={projectState.startDate.value}
                  onChange={(date) => onDateChangeHandler(date, "startDate")}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="YYYY-MM-DD"
                  margin="normal"
                  label="End Date"
                  value={projectState.endDate.value}
                  onChange={(date) => onDateChangeHandler(date, "endDate")}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <SaveButton onClick={onSaveProjectButtonClickHandler} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }

  return (
    <Container maxWidth="md">
      <Box py={2}>{dataDisplay}</Box>
    </Container>
  );
};

export default ProjectFormScreen;
