import React, { Fragment } from "react";
import CustomTable from "../../UI/CustomTable";
import {
  TablePagination,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Box,
  Button,
  Typography,
  Icon,
  Tooltip,
} from "@material-ui/core";
import { Devices } from "@material-ui/icons";

const ContractorWorkersTable = ({ workers, ...props }) => {
  let workerCells;
  if (!workers || workers.length === 0) {
    workerCells = (
      <Box p={2}>
        <Typography>No records to display.</Typography>
      </Box>
    );
  } else {
    workerCells = workers.map((worker) => {
      return (
        <TableRow key={worker.id} hover>
          <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>

          <TableCell align="left">{worker.title}</TableCell>
          <TableCell align="left">{worker.email}</TableCell>
          <TableCell align="left">
            {worker.isUser ? (
              <Tooltip title="This worker has login credentials">
                <Devices color="primary" />
              </Tooltip>
            ) : null}
          </TableCell>
          <TableCell align="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => props.onRowSelected(event, worker.id)}
            >
              <Box px={2}>View</Box>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  /*  const tableHead = ["Name", "Title", "Email", "User"];
  const tableBody = props.workers.map(worker => {
    return {
      fields: [
        `${worker.firstName} ${worker.lastName}`,
        worker.title,
        worker.email,
        worker.isUser ? "Yes" : "No"
      ],
      key: worker.id,
      onSelected: (event) => {}
    };
  }); */

  return (
    <Fragment>
      <Table style={{ tableLayout: "fixed", minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Title</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Email</strong>
            </TableCell>
            <TableCell align="left">
              <strong>User</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{workerCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
      {/* {certificationCells.length === 0 ? (
          <Typography>No Cetifications found</Typography>
        ) : null} */}
    </Fragment>
  );

  //return <CustomTable tableHead={tableHead} tableBody={tableBody} />;
};

export default ContractorWorkersTable;
