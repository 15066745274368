import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Grid,
  Paper,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import EditButton from "../../../components/UI/EditButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import WorkerInfo from "../../../components/Workers/Worker/WorkerInfo";
import ProjectsTable from "../../../components/Project/ProjectsTable";
import ContractorCertifications from "../../../components/Contractors/Contractor/ContractorCertifications";
import { getWorkerById, deleteEmployee } from "../../../services/workerService";
import EmployeeProjects from "../../../components/Workers/Worker/EmployeeProjects";
import WorkerCertifications from "../../../components/Workers/Worker/WorkerCertifications";
import { Devices } from "@material-ui/icons";
import SaveButton from "../../../components/UI/SaveButton";
import { changePassword } from "../../../services/authService";

const EmployeeDetailScreen = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [worker, setWorker] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  const onContractorLinkClickHandler = (event, id) => {
    event.preventDefault();
    history.push(`/contractors/${id}`);
  };

  const onEditWorkerClickHandler = (event) => {
    history.push("/workers/" + id + "/edit");
  };

  const onDeleteWorkerClickHandler = () => {
    setLoading(true);
    deleteEmployee(id)
      .then((response) => {
        history.push("/workers");
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    getWorkerById(id)
      .then((response) => {
        setWorker(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (error || loading || !worker) {
    return (
      <Box marginTop={16} display="flex" justifyContent="center">
        {error ? (
          <Typography>Error occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <ChangePasswordDialog
        open={passwordDialogOpen}
        onClose={() => {
          setPasswordDialogOpen(false);
        }}
        workerId={id}
      />
      <Box py={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Workers</Typography>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="h4">
                    <strong>
                      {worker
                        ? `${worker.firstName} ${worker.lastName} `
                        : null}
                    </strong>
                    {worker.isUser ? <Devices color="primary" /> : null}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {worker.currentUser && (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setPasswordDialogOpen(true);
                          }}
                        >
                          Change Password
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <EditButton onClick={onEditWorkerClickHandler} />
                    </Grid>
                    <Grid item>
                      <DeleteButton
                        onClick={onDeleteWorkerClickHandler}
                        disabled={worker.currentUser}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <WorkerInfo
                worker={worker}
                onContractorSelected={onContractorLinkClickHandler}
              />
            </Grid>
            {worker.user ? <Container /> : null}
            <Grid item>
              <EmployeeProjects projects={worker.projects} />
            </Grid>
            <Grid item>
              <WorkerCertifications workerId={id} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const ChangePasswordDialog = ({ open, onClose, workerId }) => {
  const [fieldState, setFieldState] = useState({
    current: {
      value: "",
      error: null,
    },
    new: {
      value: "",
      error: null,
    },
    confirm: {
      value: "",
      error: null,
    },
    error: false,
    updateSuccess: false,
  });
  const [loading, setLoading] = useState(false);

  const textChangeHandler = (event, id) => {
    const updated = {
      ...fieldState,
      [id]: {
        value: event.target.value,
      },
    };

    setFieldState(updated);
  };

  const confirmClickHandler = () => {
    let hasError = false;
    const stateData = {
      ...fieldState,
    };

    if (!stateData.current.value) {
      hasError = true;
      stateData.current.error = true;
    }

    if (!stateData.new.value) {
      hasError = true;
      stateData.new.error = true;
    }

    if (!stateData.confirm.value) {
      hasError = true;
      stateData.confirm.error = true;
    }

    if (hasError) {
      setFieldState(stateData);
      return;
    }

    if (stateData.confirm.value !== stateData.new.value) {
      stateData.error = "Passwords do not match";
      setFieldState(stateData);
      return;
    }

    setLoading(true);

    changePassword({
      currentPass: stateData.current.value,
      newPass: stateData.new.value,
      confirmPass: stateData.confirm.value,
    })
      .then((response) => {
        const state = {
          ...fieldState,
          updateSuccess: true,
        };
        setLoading(false);
        setFieldState(state);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Change Password</Typography>
        {fieldState.error && (
          <Typography color="error">Passwords do not match</Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={fieldState.current.value}
              error={fieldState.current.error}
              label="Current Password"
              onChange={(event) => textChangeHandler(event, "current")}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={fieldState.new.value}
              error={fieldState.new.error}
              label="New Password"
              onChange={(event) => textChangeHandler(event, "new")}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={fieldState.confirm.value}
              error={fieldState.confirm.error}
              label="Confirm New Password"
              onChange={(event) => textChangeHandler(event, "confirm")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress />}
        {fieldState.updateSuccess && !loading ? (
          <Typography color="primary">Password Update Successful!</Typography>
        ) : (
          !loading && <SaveButton onClick={confirmClickHandler} />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeDetailScreen;
