import React from "react";
import {
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  List,
  TableRow,
  TableCell,
  Checkbox,
  TableHead,
  TableBody,
  Table,
  Divider,
  Button,
  Grid
} from "@material-ui/core";
import { Fragment } from "react";

const SelectWorkersDialog = props => {
  if (!props.workersList) {
    return <Fragment />;
  }
  const workerListItems = props.workersList.map(worker => {
    return (
      <TableRow
        key={worker.id}
        onClick={
          props.single ? event => props.onWorkerSelected(event, worker) : null
        }
        hover={props.single}
      >
        {!props.single ? (
          <TableCell>
            <Checkbox
              checked={worker.isSelected}
              onChange={event => props.onWorkerSelected(event, worker)}
              value={true}
            />
          </TableCell>
        ) : null}

        <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>
        <TableCell>{ worker.contractor && worker.contractor.name}</TableCell>
      </TableRow>
    );
  });

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Select Worker{props.single ? "" : "(s)"}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  {!props.single ? <TableCell /> : null}

                  <TableCell>Worker Name</TableCell>
                  <TableCell>Contractor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{workerListItems}</TableBody>
            </Table>
          </Grid>
          {!props.single ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onSaveClick}
              >
                Save
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectWorkersDialog;
