import React from 'react';

import { TablePagination, TableBody, TableCell, TableRow, TableHead, Table } from "@material-ui/core";

const CustomPaginationTable = ({
    children,
    headers,
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    paginationDisabled,
    ...other
  }) => {
    return (
      <Table {...other}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return (
                <TableCell
                  align={index === headers.length - 1 ? "right" : "left"}
                  key={index}
                >
                  <strong>{header}</strong>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {!paginationDisabled && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </Table>
    );
  };

  export default CustomPaginationTable;