import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Check, Report } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import BasePaper from "../../components/UI/BasePaper";
import CenterBox from "../../components/UI/CenterBox";
import { EquipmentWorkAssignmentContext } from "../../context/equipment-work-assignment-context";
import MainViewContainer from "../../hoc/MainViewContainer";
import strings from "../../strings";
import { formatDateShort } from "../../utils/util";
import DeleteWorkAssignmentDialog from "./components/DeleteWorkAssignmentDialog";
import WorkAssignmentFormDialog from "./components/WorkAssignmentFormDialog";

const WorkAssignmentDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { getAssignmentById } = useContext(EquipmentWorkAssignmentContext);
  const [assignment, setAssignment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    const currentAssignment = getAssignmentById(+id);

    if (!currentAssignment) {
      setError(strings.ERRORS.DEFAULT_ERROR);
    } else {
      setAssignment(currentAssignment);
    }
    setLoading(false);
  }, [id, getAssignmentById]);

  const deleteButtonClickHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteDialogCloseHandler = (result) => {
    if (result) return history.goBack();

    setDeleteDialogOpen(false);
  };

  const editButtonClickHandler = () => {
    setEditDialogOpen(true);
  };

  const editDialogCloseHandler = (result) => {
    setEditDialogOpen(false);
  };

  return (
    <MainViewContainer>
      {loading && (
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      )}
      {!loading && error && (
        <CenterBox>
          <Typography>{error}</Typography>
        </CenterBox>
      )}
      {!loading && !error && assignment && (
        <BasePaper>
          {deleteDialogOpen && (
            <DeleteWorkAssignmentDialog
              id={id}
              open={deleteDialogOpen}
              onClose={deleteDialogCloseHandler}
            />
          )}
          {editDialogOpen && (
            <WorkAssignmentFormDialog
              assignment={assignment}
              open={editDialogOpen}
              onClose={editDialogCloseHandler}
            />
          )}

          <Grid container direction="column" spacing={1}>
            <Grid
              container
              item
              direction="row"
              spacing={1}
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h5">
                  <strong>Work Assignment</strong>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={deleteButtonClickHandler}
                      color="secondary"
                    >
                      Delete
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={editButtonClickHandler} color="primary">
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                {assignment.completedAt ? (
                  <Fragment>
                    <Check style={{ color: "#46B83D" }} />
                    <Typography style={{ color: "#46B83D" }}>
                      Complete
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Report style={{ color: "#EE7302" }} />
                    <Typography style={{ color: "#EE7302" }}>
                      Not Complete
                    </Typography>
                  </Fragment>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item lg={12}>
              <WorkAssignmentDetails assignment={assignment} />
            </Grid>
          </Grid>
        </BasePaper>
      )}
    </MainViewContainer>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    "table-cell": {
      paddingLeft: "0px",
      borderBottom: "none",
    },
  })
);

const WorkAssignmentDetails = ({ assignment }) => {
  const classes = useStyles();
  return (
    <Table size="small" style={{ tableLayout: "fixed" }}>
      <TableBody>
        <TableRow>
          <TableCell width="20%" className={classes["table-cell"]}>
            <Typography>
              <strong>Work Required</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{assignment?.workRequired}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width="20%" className={classes["table-cell"]}>
            <Typography>
              <strong>Assigned To</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{assignment?.assignedTo}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width="20%" className={classes["table-cell"]}>
            <Typography>
              <strong>Date Created</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{formatDateShort(assignment?.createdAt)}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width="20%" className={classes["table-cell"]}>
            <Typography>
              <strong>Date Completed</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{formatDateShort(assignment?.completedAt)}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default WorkAssignmentDetailsPage;
