import axios from '../axios';

export const getChecklistById = (id) => {
  return axios.get('/checklists/' + id);
};

export const getChecklistsOnProject = (projectId, type, query) => {
  return axios.get(`/projects/${projectId}/checklists`, {
    params: {
      type: type,
      ...query,
    },
  });
};

export const getCovidScreeningById = (id) => {
  return axios.get(`/covid-19-checklists/${id}`);
};
