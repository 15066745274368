import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { formatToDisplayDate } from '../../../../utils/util';

const OrientationChecklistHeader = ({
  checklistName,
  workerName,
  dateCompleted,
}) => {
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item container direction="row" justify="space-between">
        <Grid item>
          <Typography>Worker Orientation</Typography>
        </Grid>
        <Grid item>
          <Typography>{checklistName}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <strong>{workerName}</strong>
        </Typography>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="caption">Date Completed</Typography>
        </Grid>
        <Grid item>
          <Typography>{formatToDisplayDate(dateCompleted)}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default OrientationChecklistHeader;
