import axios from "../axios";

export const getAllAccounts = () => {
  return axios.get("super-admin/accounts");
  /*   return Promise.resolve([
    {
      id: 1,
      accountName: "AccountName",
      type: 1,
      active: 1,
      createdAt: "2020-05-19T00:53:08.000Z",
    },
  ]); */
};

export const getAccountById = (id) => {
  return axios.get("/super-admin/accounts/" + id);
  /* return Promise.resolve({
    id: 3,
    accountName: "Testing Account",
    billingAddress: null,
    email: null,
    active: 1,
    type: 1,
    createdAt: "2020-05-19T00:53:08.000Z",
    updatedAt: "2020-05-19T00:53:08.000Z",
    adminUserId: 16,
    mainContractorId: 5,
    workers: [
      {
        id: 16,
        firstName: "Conner",
        lastName: "Keating",
        title: "Admin",
        email: "ckeat62@gmail.com",
        phoneMobile: "519-123-1234",
        isUser: true,
        emergencyContactName: "Austen Keating",
        emergencyContactPhone: "519-123-1234",
        createdAt: "2020-05-19T00:53:08.000Z",
        updatedAt: "2020-07-10T00:51:31.000Z",
        deletedAt: null,
        addressId: 42,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 16,
          email: "ckeat62@gmail.com",
          enabled: true,
          type: 1,
        },
      },
      {
        id: 18,
        firstName: "test",
        lastName: "worker",
        title: "Title",
        email: "conner@sitesafety",
        phoneMobile: "",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-05-21T13:40:06.000Z",
        updatedAt: "2020-07-20T17:15:13.000Z",
        deletedAt: null,
        addressId: 33,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 18,
          email: "conner@sitesafety",
          enabled: true,
          type: 3,
        },
      },
      {
        id: 19,
        firstName: "Austen",
        lastName: "Keating",
        title: "Title",
        email: "conner_rocks157@hotmail.com",
        phoneMobile: "123-123-1234",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-05-22T21:12:17.000Z",
        updatedAt: "2020-07-15T18:35:16.000Z",
        deletedAt: null,
        addressId: 35,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 19,
          email: "conner_rocks157@hotmail.com",
          enabled: true,
          type: 3,
        },
      },
      {
        id: 23,
        firstName: "Bill",
        lastName: "Keating",
        title: "Safety Coordinator",
        email: "",
        phoneMobile: "",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-06-06T01:36:33.000Z",
        updatedAt: "2020-06-06T01:36:33.000Z",
        deletedAt: null,
        addressId: 44,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 23,
          email: "t",
          enabled: true,
          type: 2,
        },
      },
      {
        id: 24,
        firstName: "Conner",
        lastName: "Keating",
        title: "Supervisor",
        email: "12",
        phoneMobile: "",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-06-23T20:44:24.000Z",
        updatedAt: "2020-06-23T20:47:36.000Z",
        deletedAt: null,
        addressId: 49,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 24,
          email: "12",
          enabled: true,
          type: 2,
        },
      },
      {
        id: 25,
        firstName: "Austen",
        lastName: "Keating",
        title: "",
        email: "conner.keating@sitesafetysolutions.c1a",
        phoneMobile: "",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-06-23T20:48:27.000Z",
        updatedAt: "2020-06-23T20:55:47.000Z",
        deletedAt: null,
        addressId: 50,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 25,
          email: "conner.keating@sitesafetysolutions.c1a",
          enabled: true,
          type: 2,
        },
      },
      {
        id: 26,
        firstName: "John",
        lastName: "Smith",
        title: "Welder",
        email: "jsmith@welding.company",
        phoneMobile: "123-456-7890",
        isUser: true,
        emergencyContactName: "Jane Smith",
        emergencyContactPhone: "",
        createdAt: "2020-07-02T18:41:07.000Z",
        updatedAt: "2020-07-02T18:53:54.000Z",
        deletedAt: null,
        addressId: 54,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 26,
          email: "jsmith@welding.company",
          enabled: true,
          type: 2,
        },
      },
      {
        id: 30,
        firstName: "Rnadom",
        lastName: "Worker",
        title: "",
        email: "randomWorker@sitesafetysolutions.ca",
        phoneMobile: "",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-07-13T13:34:03.000Z",
        updatedAt: "2020-07-13T13:34:03.000Z",
        deletedAt: null,
        addressId: 60,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 30,
          email: "randomWorker@sitesafetysolutions.ca",
          enabled: true,
          type: 3,
        },
      },
      {
        id: 31,
        firstName: "Randomw",
        lastName: "worker2",
        title: "Job Titile",
        email: "Email@sitesafetysolutions.ca",
        phoneMobile: "519-123-1234",
        isUser: true,
        emergencyContactName: "Random Worker",
        emergencyContactPhone: "519-123-1234",
        createdAt: "2020-07-13T13:34:54.000Z",
        updatedAt: "2020-07-13T13:34:54.000Z",
        deletedAt: null,
        addressId: 61,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 31,
          email: "Email@sitesafetysolutions.ca",
          enabled: true,
          type: 3,
        },
      },
      {
        id: 32,
        firstName: "New ",
        lastName: "Worker",
        title: "",
        email: "newworker@sitesafetysolutions.ca",
        phoneMobile: "",
        isUser: true,
        emergencyContactName: "",
        emergencyContactPhone: "",
        createdAt: "2020-07-13T13:35:19.000Z",
        updatedAt: "2020-07-13T13:35:19.000Z",
        deletedAt: null,
        addressId: 62,
        accountId: 3,
        contractorId: 5,
        user: {
          id: 32,
          email: "newworker@sitesafetysolutions.ca",
          enabled: true,
          type: 3,
        },
      },
    ],
  }); */
};

export const createAccount = (account) => {
  return axios.post("super-admin/accounts", account);
  /*   return new Promise((resolve) => setTimeout(resolve, 2000)).then((resolve) => {
    return true;
  }); */
};

export const loginAsSuperAdmin = (email, password) => {
  return axios.post("super-admin/login", {
    email,
    password,
  });
};
