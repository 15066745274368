export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const GET_ALL_PROJECTS_REQUEST = 'GET_ALL_PROJECTS_REQUEST';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_ALL_PROJECTS_FAILURE = 'GET_ALL_PROJECTS_FAILURE';

export const GET_ALL_WORKERS_REQUEST = 'GET_ALL_WORKERS_REQUEST';
export const GET_ALL_WORKERS_SUCCESS = 'GET_ALL_WORKERS_SUCCESS';
export const GET_WORKER_DETAILS_SUCCESS = 'GET_WORKER_DETAILS_SUCCESS';
export const GET_ALL_WORKERS_FAILURE = 'GET_ALL_WORKERS_FAILURE';

// 
export const GET_ALL_CONTRACTORS_REQUEST = 'GET_ALL_CONTRACTORS_REQUEST';
export const GET_ALL_CONTRACTORS_SUCCESS = 'GET_ALL_CONTRACTORS_SUCCESS';
export const GET_CONTRACTOR_DETAILS_SUCCESS = 'GET_CONTRACTOR_DETAILS_SUCCESS';
export const GET_ALL_CONTRACTORS_FAILURE = 'GET_ALL_CONTRACTORS_FAILURE';

export const GET_ALL_USERS_ON_ACCOUNT_REQUEST = 'GET_ALL_USERS_ON_ACCOUNT_REQUEST';
export const GET_ALL_USERS_ON_ACCOUNT_SUCCESS = 'GET_ALL_USERS_ON_ACCOUNT_SUCCESS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_ALL_USERS_ON_ACCOUNT_FAILURE = 'GET_ALL_USERS_ON_ACCOUNT_FAILURE';

