import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Link,
  Grid,
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

const WorkerInfo = ({ worker, onContractorSelected }) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption">Title</Typography>
            <Typography variant="h6">{worker.title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Contractor</Typography>
            <Typography variant="h6">
              {worker.contractor ? (
                <Link
                  href="#"
                  onClick={(event) =>
                    onContractorSelected(event, worker.contractor.id)
                  }
                >
                  {worker.contractor.name}
                </Link>
              ) : null}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Email</Typography>
            <Typography variant="h6">{worker.email}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Mobile Phone</Typography> <br />
            <NumberFormat
              format="###-###-####"
              value={worker.phoneMobile}
              displayType="text"
              renderText={(text) => (
                <Typography variant="h6">{text}</Typography>
              )}
            />
          </Grid>
          <Grid item>
            <Typography variant="caption">Address</Typography>
            <Typography variant="h6">{worker.address.addressLabel}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Emergency Contact Name</Typography>
            <Typography variant="h6">{worker.emergencyContactName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              Emergency Contact Phone Number
            </Typography>
            <Typography variant="h6">{worker.emergencyContactPhone}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default WorkerInfo;
