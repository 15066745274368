import React, { useContext, useEffect, useState } from "react";
import { EquipmentWorkAssignmentContext } from "../../../../context/equipment-work-assignment-context";
import { getEquipmentChecklistById } from "../../../../services/equipmentService";
import strings from "../../../../strings";

const useEquipmentChecklistDetails = (id) => {
  const equipmentWorkAssignmentContext = useContext(
    EquipmentWorkAssignmentContext
  );
  const [equipmentChecklist, setEquipmentChecklist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getChecklistRequest = async (id) => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await getEquipmentChecklistById(id);

      setEquipmentChecklist(data);
      equipmentWorkAssignmentContext.setAssignments(
        data.equipmentChecklistWorkAssignments
      );
      setLoading(false);
    } catch (err) {
      setError(strings.ERRORS.DEFAULT_ERROR);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChecklistRequest(id);
  }, [id]);

  return {
    equipmentChecklist,
    loading,
    error,
  };
};
export default useEquipmentChecklistDetails;
