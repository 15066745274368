import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { CheckCircleOutline, Warning } from "@material-ui/icons";
import React, { useEffect } from "react";
import useNetworkRequest from "../../../utils/useNetworkRequest";
import * as ProjectService from "../../../services/projectService";
import { useHistory } from "react-router-dom";

const CurrentProjects = () => {
  const history = useHistory();
  const { loading, error, data, handleRequest } = useNetworkRequest(true);

  useEffect(() => {
    handleRequest(() =>
      ProjectService.getAllProjects({ state: "IN_PROGRESS" })
    );
  }, [handleRequest]);

  const cellClickHandler = (id) => history.push(`/projects/${id}`);

  let contents;
  if (loading || error || (!loading && data?.length === 0)) {
    contents = (
      <Box py={2} display="flex" justifyContent="center">
        {loading && <CircularProgress />}
        {error && <Typography>{error}</Typography>}
        {!loading && data?.length === 0 && (
          <Typography>No items to display.</Typography>
        )}
      </Box>
    );
  } else {
    contents = (
      <Table size="small" style={{ border: "1px solid #d3d3d3" }}>
        <TableBody>
          {data.map((project) => (
            <TableRow
              hover
              key={project.id}
              style={{ height: "64px", cursor: "pointer" }}
            >
              <TableCell
                width={"5%"}
                onClick={() => cellClickHandler(project.id)}
              >
                {project.projectStatus === 0 ? (
                  <CheckCircleOutline
                    fontSize="large"
                    style={{ color: "#46B83D" }}
                  />
                ) : (
                  <Warning fontSize="large" style={{ color: "#EE7302" }} />
                )}
              </TableCell>
              <TableCell onClick={() => cellClickHandler(project.id)}>
                <Typography>
                  <strong>{project.name}</strong>
                </Typography>
                <Typography variant="body2">
                  {project.projectStatus === 0
                    ? "Operating without issues"
                    : "Project has Issues"}
                </Typography>
              </TableCell>
              <TableCell onClick={() => cellClickHandler(project.id)}>
                <Typography>{project.clientName}</Typography>
              </TableCell>
              <TableCell
                align="right"
                onClick={() => cellClickHandler(project.id)}
              >
                <Typography>{project.address?.addressLabel}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return <Paper elevation={0}>{contents}</Paper>;
};

export default CurrentProjects;
