import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  getWorkerById,
  updateEmployee,
  createEmployee,
} from '../../../services/workerService';
import { getAllContractors } from '../../../services/contractorService';
import { getRemainingSeats } from '../../../services/accountService';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import WorkerForm from '../../../components/Workers/Worker/WorkerForm';
import SelectContractorsDialog from '../../Contractors/SelectContractorsDialog';
import CenterBox from '../../../components/UI/CenterBox';
import { formatPhoneNumberInput } from '../../../utils/util';

const WorkerFormContainer = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [workerData, setWorkerData] = useState({
    firstName: {
      value: '',
      error: false,
    },
    lastName: {
      value: '',
      error: false,
    },
    title: {
      value: '',
      error: false,
    },
    phoneNumber: {
      value: '',
    },
    eContactPhone: {
      value: '',
    },
    eContactName: {
      value: '',
    },
    email: {
      value: '',
    },
    country: {
      value: '',
      error: false,
    },
    state: {
      value: '',
      error: false,
    },
    city: {
      value: '',
      error: false,
    },
    address1: {
      value: '',
      error: false,
    },
    username: {
      value: '',
      error: false,
    },
    password: {
      value: '',
      error: false,
    },
    role: {
      value: '',
      error: false,
    },
    contractor: '',
    isUser: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [contractors, setContractors] = useState([]);
  const [accountInfoState, setAccountInfoState] = useState(null);
  const [isNewUser, setNewUser] = useState(id ? false : true);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    // // Get contractors to link worker
    // getAllContractors()
    //   .then(response => {
    //     setContractors(response.data);
    //     setIsLoading(false);
    //   })
    //   .catch(err => {});

    if (props.edit) {
      getAllContractors()
        .then((response) => {
          setContractors(response.data.contractors);
          return getWorkerById(id);
        })
        .then((response) => {
          const workerJson = response.data;
          const userInfoJson = workerJson.user;
          const addressJSON = workerJson.address;

          let username = userInfoJson ? userInfoJson.username : null;
          let role = userInfoJson ? userInfoJson.type : '';

          const updatedWorkerState = {
            firstName: {
              value: workerJson.firstName ? workerJson.firstName : '',
              error: false,
            },
            lastName: {
              value: workerJson.lastName ? workerJson.lastName : '',
              error: false,
            },
            title: {
              value: workerJson.title ? workerJson.title : '',
              error: false,
            },
            email: {
              value: workerJson.email ? workerJson.email : '',
            },
            country: {
              value: addressJSON.country ? addressJSON.country.id : '' || '',
              error: false,
            },
            state: {
              value: addressJSON.state ? addressJSON.state.id : '' || '',
              error: false,
            },
            city: {
              value: addressJSON.city || '',
              error: false,
            },
            address1: {
              value: addressJSON.address1 || '',
              error: false,
            },
            phoneNumber: {
              value: workerJson.phoneMobile || '',
            },
            eContactPhone: {
              value: workerJson.emergencyContactPhone || '',
            },
            eContactName: {
              value: workerJson.emergencyContactName || '',
            },
            username: {
              value: username ? username : '',
              error: false,
            },
            password: {
              value: '',
              error: false,
            },
            role: {
              value: role || '',
              error: false,
            },
            isUser: workerJson.isUser ? workerJson.isUser : false,
            userExists: workerJson.isUser || false,
            contractor: workerJson.contractor ? workerJson.contractor : '',
            currentUser: workerJson.currentUser || false,
          };

          setWorkerData(updatedWorkerState);
          return getAllContractors();
        })
        .then((response) => {
          setContractors(response.data.contractors);
          return getRemainingSeats();
        })
        .then((response) => {
          setAccountInfoState(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      getAllContractors()
        .then((response) => {
          setContractors(response.data.contractors);
          return getRemainingSeats();
        })
        .then((response) => {
          setAccountInfoState(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  const onTextChangedHandler = (event, idName) => {
    const updatedState = {
      ...workerData,
      [idName]: {
        //...workerData[idName],
        value: event.target.value,
        error: false,
      },
    };

    setWorkerData(updatedState);
  };
  const openDialogClickHandler = () => {
    setDialogOpen(true);
  };

  const dialogCloseHandler = () => {
    setDialogOpen(false);
  };

  const onContractorSelectedHandler = (contractor) => {
    dialogCloseHandler();

    setWorkerData({
      ...workerData,
      contractor: contractor,
    });
  };

  const onEmployeeTypeChangedHandler = (event) => {
    const isUser = event.target.value === 'Worker' ? false : true;

    const updatedState = {
      ...workerData,
      isUser: isUser,
    };

    if (isUser) {
      const mainContractor = contractors.find((contractor) => {
        return contractor.mainContractor === true;
      });

      if (mainContractor) {
        updatedState.contractor = mainContractor;
      }
    }

    setWorkerData(updatedState);
  };

  const onCreateWorkerClickHandler = () => {
    let hasError = false;

    const fields = { ...workerData };

    fields.phoneNumber.value = formatPhoneNumberInput(fields.phoneNumber.value);
    console.log(fields.phoneNumber.value);

    if (workerData.firstName.value === '') {
      fields.firstName.error = true;
      hasError = true;
    }

    if (workerData.lastName.value === '') {
      fields.lastName.error = true;
      hasError = true;
    }

    if (workerData.contractor === null) {
      hasError = true;
    }

    if (workerData.isUser && !workerData.email.value) {
      fields.email.error = true;
      hasError = true;
    }

    // Check only if in user creation
    if (workerData.isUser) {
      if (!props.edit && !workerData.password.value) {
        fields.password.error = true;
        hasError = true;
      }

      if (!workerData.role.value) {
        fields.role.error = true;
        hasError = true;
      }
    }

    setWorkerData(fields);

    if (hasError) return;

    setIsLoading(true);

    const employeeRequest = {
      firstName: workerData.firstName.value,
      lastName: workerData.lastName.value,
      title: workerData.title.value,
      email: workerData.email.value,
      address: {
        address1: workerData.address1.value,
        city: workerData.city.value,
        addressStateId: workerData.state.value,
        addressCountryId: workerData.country.value,
      },
      phoneMobile: workerData.phoneNumber.value,
      emergencyContactName: workerData.eContactName.value,
      emergencyContactPhone: workerData.eContactPhone.value,
      contractorId: workerData.contractor.id,
    };

    let requestPromise;

    if (props.edit) {
      let userData = {};
      if (workerData.isUser) {
        userData = {
          isUser: workerData.isUser,
          password: workerData.password.value,
          role: workerData.role.value,
        };
      }

      let updateRequest = { ...employeeRequest, ...userData };

      requestPromise = updateEmployee(id, updateRequest);
    } else {
      let userData = {};
      if (workerData.isUser) {
        userData = {
          isUser: workerData.isUser,
          username: workerData.username.value,
          password: workerData.password.value,
          role: workerData.role.value,
        };
      }

      let createRequest = { ...employeeRequest, ...userData };

      requestPromise = createEmployee(createRequest);
    }

    requestPromise
      .then((response) => {
        history.push('/workers/' + response.data.id);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          console.log(err.response);
          if (err.response.status === 409) {
            const updatedState = {
              ...workerData,
              email: {
                ...workerData.email,
                error: true,
                helperText: 'This email is already in use',
              },
            };

            setWorkerData(updatedState);
          }
        } else {
          setError(err);
        }
      });
  };

  let dataDisplay;

  if (isLoading) {
    dataDisplay = (
      <CenterBox pb={8}>
        {error ? (
          <Typography>Error Occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  } else {
    dataDisplay = (
      <Fragment>
        <SelectContractorsDialog
          open={dialogOpen}
          onClose={dialogCloseHandler}
          onContractorSelectedHandler={onContractorSelectedHandler}
          loading={isLoading}
          contractorsList={contractors}
        />
        {!isLoading ? (
          <WorkerForm
            edit={props.edit}
            accountInfo={accountInfoState}
            workerData={workerData}
            onTextChange={onTextChangedHandler}
            onContractorSelectClick={openDialogClickHandler}
            onSaveClick={onCreateWorkerClickHandler}
            onEmployeeTypeChange={onEmployeeTypeChangedHandler}
            error={error}
          />
        ) : null}
      </Fragment>
    );
  }

  return (
    <Container maxWidth="md">
      <Box py={2}>{dataDisplay}</Box>
    </Container>
  );
};

export default WorkerFormContainer;
