import React from "react";
import {
  Paper,
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import strings from "../../../strings";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CustomOutlinedSelect from "../../UI/CustomOutlinedSelect";
import SaveButton from "../../UI/SaveButton";

const EditResourceForm = ({
  create,
  resourceData,
  onSaveClick,
  onTextChange,
  onFileChange,
  onDateChange,
}) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box pb={1}>
              <Typography variant="h4">
                {create ? "New" : "Edit"} Resource
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Name"
              value={resourceData.name.value}
              error={resourceData.name.error}
              onChange={(event) => onTextChange(event, "name")}
            />
          </Grid>
          <Grid item>
            <CustomOutlinedSelect
              label="Type"
              fullWidth
              required
              error={resourceData.type.error}
              onChange={(event) => onTextChange(event, "type")}
              selected={resourceData.type.value}
              menuItems={[
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>,
                <MenuItem value="1">{strings.RESOURCE_TYPES[0]}</MenuItem>,
                <MenuItem value="2">{strings.RESOURCE_TYPES[1]}</MenuItem>,
                <MenuItem value="3">{strings.RESOURCE_TYPES[2]}</MenuItem>,
              ]}
            />
            {/*        <FormControl
              variant="filled"
              fullWidth
              required
              error={resourceData.type.error}
            >
              <InputLabel>Type</InputLabel>

              <Select
                value={resourceData.type.value}
                onChange={event => onTextChange(event, "type")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="1">{strings.RESOURCE_TYPES[0]}</MenuItem>
                <MenuItem value="2">{strings.RESOURCE_TYPES[1]}</MenuItem>
                <MenuItem value="3">{strings.RESOURCE_TYPES[2]}</MenuItem>
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              label="Description"
              value={resourceData.description.value}
              onChange={(event) => onTextChange(event, "description")}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              required
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="Publish Date"
              value={resourceData.publishDate.value}
              onChange={(date) => onDateChange(date, "publishDate")}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              required
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="Expire Date"
              value={resourceData.expireDate.value}
              onChange={(date) => onDateChange(date, "expireDate")}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="baseline">
              <Grid item>
                <Button
                  onError
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  Upload File
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  color={resourceData.file.error ? "error" : "textPrimary"}
                >
                  File Selected: {resourceData.file.value}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <SaveButton onClick={onSaveClick} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default EditResourceForm;
