import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import FileDownload from "js-file-download";

import {
  Container,
  CircularProgress,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import CertificationDetails from "../../../components/Certifications/Certification/CertificationDetails";
import {
  getCertificationById,
  deleteCertificationById,
} from "../../../services/certificationsService";
import EditButton from "../../../components/UI/EditButton";
import { downloadWithResourceUrl } from "../../../services/downloadsService";
import DeleteButton from "../../../components/UI/DeleteButton";

const CertificationDetailScreen = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [certification, setCertification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onViewFileClickHandler = (event, resourceUrl, filename) => {
    event.preventDefault();
    downloadWithResourceUrl(resourceUrl).then((response) => {
      FileDownload(response.data, filename);
    });
  };

  const onOwnerLinkClickHandler = (event) => {
    event.preventDefault();

    if (certification.owner?.type === 1) {
      history.push(`/workers/${certification.owner.id}`);
    } else if (certification.owner?.type === 2) {
      history.push(`/contractors/${certification.owner.id}`);
    }
  };

  const onEditButtonClickHandler = () => {
    history.push("/certifications/" + id + "/edit");
  };

  const onDeleteButtonClickHandler = () => {
    setLoading(true);
    deleteCertificationById(id)
      .then((response) => {
        history.replace("/certifications");
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    getCertificationById(id)
      .then((response) => {
        setLoading(false);
        setCertification(response.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, []);

  if (error || loading || !certification) {
    return (
      <Box marginTop={16} display="flex" justifyContent="center">
        {error ? (
          <Typography>Error occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Certifications</Typography>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="h4">
                    <strong>{certification ? certification.name : ""}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <EditButton onClick={onEditButtonClickHandler} />
                    </Grid>
                    <Grid item>
                      <DeleteButton onClick={onDeleteButtonClickHandler} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <CertificationDetails
              certification={certification}
              viewFileClick={onViewFileClickHandler}
              ownerLinkClick={onOwnerLinkClickHandler}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CertificationDetailScreen;
