import React, { useState, useEffect, Fragment } from 'react';
import {
  Paper,
  Typography,
  CircularProgress,
  Box,
  Button,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  Link,
  Grid,
} from '@material-ui/core';
import * as workerService from '../../../services/workerService';
import SelectWorkers from '../../Workers/SelectWorkers';
import CenterBox from '../../UI/CenterBox';
import { useHistory } from 'react-router-dom';
import OrientationChecklist from '../../../containers/Checklists/OrientationChecklist/OrientationChecklist';
import { Check, Warning } from '@material-ui/icons';
import CovidScreening from '../../CovidScreening/CovidScreening';

const ProjectWorkers = (props) => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [projectWorkers, setProjectWorkers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projectWorkersUpdated, setProjectWorkersUpdated] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [orientationDialog, setOrientationDialog] = useState(false);
  const [orientationChecklistId, setOrientationChecklistId] = useState(null);
  const [screeningDialog, setScreeningDialog] = useState(false);
  const [screeningId, setScreeningId] = useState(null);

  const [queryData, setQueryData] = useState({
    params: {
      name: '',
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const workerSelectedHandler = (event, workerId) => {
    history.push('/workers/' + workerId);
  };

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const onSelectWorkersDialogCloseHandler = () => {
    setShowDialog(false);
  };

  const onWorkersSelectedHandler = (workerIds) => {
    workerService
      .addWorkersToProject(
        {
          workerIds: workerIds,
        },
        props.projectId
      )
      .then((response) => {
        setProjectWorkersUpdated(projectWorkersUpdated + 1);
        setShowDialog(false);
      });
  };

  const onAddRemoveButtonClickHandler = (event) => {
    setShowDialog(true);
  };

  const openOrientationDialog = () => {
    setOrientationDialog(true);
  };

  const orientationDialogCloseHandler = () => setOrientationDialog(false);

  const viewWorkerOrientationClickHandler = (checklistId) => {
    setOrientationChecklistId(checklistId);
    openOrientationDialog();
  };

  const openScreeningDialog = () => {
    setScreeningDialog(true);
  };

  const screeningDialogCloseHandler = () => setScreeningDialog(false);

  const viewWorkerDailyScreeningClickHandler = (id) => {
    setScreeningId(id);
    openScreeningDialog();
  };

  useEffect(() => {
    setIsLoading(true);
    workerService
      .getWorkersOnProject(props.projectId)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setProjectWorkers(response.data.workers);
        setTotal(response.data.total);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [projectWorkersUpdated, queryData]);

  return (
    <Paper>
      <OrientationChecklist
        open={orientationDialog}
        onClose={orientationDialogCloseHandler}
        checklistId={orientationChecklistId}
      />
      <CovidScreening
        open={screeningDialog}
        onClose={screeningDialogCloseHandler}
        id={screeningId}
      />
      <SelectWorkers
        projectWorkers={projectWorkers}
        open={showDialog}
        onClose={onSelectWorkersDialogCloseHandler}
        onSave={onWorkersSelectedHandler}
      />
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box p={2}>
        <Typography variant="h5">Project Workers</Typography>

        <Button
          variant="outlined"
          color="primary"
          onClick={onAddRemoveButtonClickHandler}
        >
          Manage Workers
        </Button>

        {isLoading ? (
          <CenterBox pb={4}>
            <CircularProgress />
          </CenterBox>
        ) : (
          <WorkersTable
            workers={projectWorkers}
            onRowSelected={workerSelectedHandler}
            total={total}
            rowsPerPage={rowsPerPage}
            page={page}
            pageChangeHandler={pageChangeHandler}
            rowsPerPageChangeHandler={rowsPerPageChangeHandler}
            viewWorkerOrientationClickHandler={
              viewWorkerOrientationClickHandler
            }
            viewScreeningClickHandler={viewWorkerDailyScreeningClickHandler}
          />
        )}
      </Box>
    </Paper>
  );
};

const WorkersTable = ({ workers, ...props }) => {
  let workerCells;
  if (!workers || workers.length === 0) {
    workerCells = (
      <Box p={2}>
        <Typography>No records to display.</Typography>
      </Box>
    );
  } else {
    workerCells = workers.map((worker) => {
      let screeningStatus;
      let screeningIcon;
      if (worker.covidScreeningStatus == null) {
        screeningStatus = (
          <Grid container direction="column">
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Warning />
              </Grid>
              <Grid item>Incomplete</Grid>
            </Grid>
          </Grid>
        );
      } else if (worker.covidScreeningStatus === 0) {
        screeningStatus = (
          <Grid container direction="column">
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Check />
              </Grid>
              <Grid item>Passed</Grid>
            </Grid>
            <Grid item>
              <Link
                component="button"
                onClick={(event) =>
                  props.viewScreeningClickHandler(worker.covidScreeningId)
                }
              >
                View Screening
              </Link>
            </Grid>
          </Grid>
        );
      } else if (worker.covidScreeningStatus === 1) {
        screeningStatus = (
          <Grid container direction="column">
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Warning />
              </Grid>
              <Grid item>Failed</Grid>
            </Grid>
            <Grid item>
              <Link
                component="button"
                onClick={(event) =>
                  props.viewScreeningClickHandler(worker.covidScreeningId)
                }
              >
                View Screening
              </Link>
            </Grid>
          </Grid>
        );
      }

      const covidScreeningData = (
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item>{screeningIcon}</Grid>
          </Grid>
        </Grid>
      );

      return (
        <TableRow key={worker.id} hover>
          <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>
          <TableCell align="left">
            {worker.contractor && worker.contractor.name}
          </TableCell>

          <TableCell align="left">{worker.title}</TableCell>
          <TableCell align="left">{worker.email}</TableCell>
          <TableCell align="left">
            <Box>{screeningStatus}</Box>
          </TableCell>
          <TableCell align="left">
            {worker.orientationChecklist &&
            worker.orientationChecklist.state === 3 ? (
              <Grid container direction="column">
                <Grid item>Complete</Grid>
                <Grid item>
                  <Link
                    onClick={(event) =>
                      props.viewWorkerOrientationClickHandler(
                        worker.orientationChecklist.id
                      )
                    }
                    component="button"
                  >
                    View Orientation
                  </Link>
                </Grid>
              </Grid>
            ) : (
              'Incomplete'
            )}
          </TableCell>
          <TableCell align="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => props.onRowSelected(event, worker.id)}
            >
              <Box px={2}>View</Box>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }
  return (
    <Fragment>
      <Table style={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Contractor</strong>
            </TableCell>

            <TableCell align="left">
              <strong>Title</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Email</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Daily COVID-19 Screening</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Orientation</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{workerCells}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
    </Fragment>
  );
};

export default ProjectWorkers;
