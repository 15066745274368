import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React, { useEffect } from "react";
import useNetworkRequest from "../../../utils/useNetworkRequest";
import { formatDateShort } from "../../../utils/util";
import * as WorkerService from "../../../services/workerService";
import { useHistory } from "react-router-dom";

const userTypeDisplay = (type) => {
  switch (type) {
    case 1:
      return "Admin";
    case 2:
      return "Safety Coordinator";
    case 3:
      return "Worker";
    default:
      return "";
  }
};

const Users = () => {
  const { loading, error, data, handleRequest } = useNetworkRequest(true);

  const history = useHistory();

  useEffect(() => {
    handleRequest(() => WorkerService.getAllWorkers({ isUser: 1 }));
  }, [handleRequest]);

  const cellClickHandler = (id) => history.push(`/workers/${id}`);

  let contents;
  if (loading || error || (!loading && data?.workers.length === 0)) {
    contents = (
      <Box py={2} display="flex" justifyContent="center">
        {loading && <CircularProgress />}
        {error && <Typography>{error}</Typography>}
        {!loading && data?.workers?.length === 0 && (
          <Typography>No items to display.</Typography>
        )}
      </Box>
    );
  } else {
    contents = (
      <Table size="small" style={{ border: "1px solid #d3d3d3" }}>
        <TableBody>
          {data.workers?.map((worker) => (
            <TableRow
              hover
              key={worker.id}
              style={{ height: "64px", cursor: "pointer" }}
            >
              <TableCell
                width={"5%"}
                onClick={() => cellClickHandler(worker.id)}
              >
                <Person fontSize="large" color="primary" />
              </TableCell>
              <TableCell onClick={() => cellClickHandler(worker.id)}>
                <Typography>
                  <strong>{`${worker.firstName} ${worker.lastName}`}</strong>
                </Typography>
                <Typography variant="body2">
                  {userTypeDisplay(worker?.user?.type)}
                </Typography>
              </TableCell>
              <TableCell onClick={() => cellClickHandler(worker.id)}>
                <Typography>{worker.title}</Typography>
              </TableCell>
              <TableCell
                align="right"
                onClick={() => cellClickHandler(worker.id)}
              >
                <Typography>{`Last Login:  ${formatDateShort(
                  worker.user?.lastLogin
                )}`}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return <Paper elevation={0}>{contents}</Paper>;
};

export default Users;
