import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import {
  Container,
  CssBaseline,
  Typography,
  Button,
  makeStyles,
  Paper,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { Star } from "@material-ui/icons";
import {
  deleteContractor,
  getContractorById,
} from "../../../services/contractorService";
import ContractorCertifications from "../../../components/Contractors/Contractor/ContractorCertifications";
import ContractorDetail from "../../../components/Contractors/Contractor/ContractorDetail";
import EditButton from "../../../components/UI/EditButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import ContractorWorkers from "../../../components/Contractors/Contractor/ContractorWorkers";
import { useParams, useHistory } from "react-router-dom";
import CenterBox from "../../../components/UI/CenterBox";

const ContractorDetailScreen = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [contractor, setContractor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onEditButtonClickHandler = (event) => {
    history.push("/contractors/" + id + "/edit");
  };

  const onDeleteButtonClickHandler = () => {
    setLoading(true);
    deleteContractor(id)
      .then((response) => {
        history.push("/contractors");
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    getContractorById(id)
      .then((response) => {
        setContractor(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const classes = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(8),
    },
    paper: {
      padding: theme.spacing(3, 2),
    },
  }));

  if (error || loading || !contractor) {
    return (
      <CenterBox>
        {error ? (
          <Typography>Error occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  }
  let contractorWorkersTable = null;
  const contractorName = (
    <Typography variant="h3">
      {`${contractor.name}  `}
      {contractor.mainContractor ? (
        <Tooltip title="This is your main contractor">
          <Star />
        </Tooltip>
      ) : null}
    </Typography>
  );
  const contractorDetails = <ContractorDetail contractor={contractor} />;

  if (contractor.workers) {
    contractorWorkersTable = <ContractorWorkers/>;
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Contractors</Typography>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4">
                    <strong>{contractorName}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <EditButton onClick={onEditButtonClickHandler} />
                    </Grid>
                    <Grid item>
                      <DeleteButton
                        disabled={contractor.mainContractor}
                        onClick={onDeleteButtonClickHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Paper className={classes.paper}>{contractorDetails}</Paper>
            </Grid>
            <Grid item>{contractorWorkersTable}</Grid>
            <Grid item>
              <ContractorCertifications contractorId={id} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContractorDetailScreen;
