import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CenterBox from "../../../components/UI/CenterBox";
import { EquipmentWorkAssignmentContext } from "../../../context/equipment-work-assignment-context";
import WorkAssignmentFormDialog from "./WorkAssignmentFormDialog";
import WorkAssignmentsTable from "./WorkAssignmentsTable";

const WorkAssignmentsTab = () => {
  const { id } = useParams();
  const { workAssignments } = useContext(EquipmentWorkAssignmentContext);
  const [workAssignmentDialogOpen, setWorkAssignmentDialogOpen] = useState(
    false
  );

  const createWorkAssignmentClickHandler = () => {
    setWorkAssignmentDialogOpen(true);
  };

  const workAssignmentFormDialogCloseHandler = () => {
    setWorkAssignmentDialogOpen(false);
  };

  return (
    <Grid container direction="column" spacing={1}>
      <WorkAssignmentFormDialog
        equipmentChecklistId={id}
        open={workAssignmentDialogOpen}
        onClose={workAssignmentFormDialogCloseHandler}
      />
      <Grid item>
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={createWorkAssignmentClickHandler}
            color="primary"
            startIcon={<AddBox color="primary" />}
          >
            Create Work Assignment
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Paper elevation={0}>
          <WorkAssignmentsTable workAssignments={workAssignments} />
          {workAssignments.length === 0 && (
            <CenterBox marginTop={4} pb={4}>
              <Typography>No work assignments to display.</Typography>
            </CenterBox>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default WorkAssignmentsTab;
