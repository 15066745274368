import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditResourceForm from "../../../components/Resources/Resource/EditResourceForm";
import {
  CircularProgress,
  Container,
  Typography,
  Box,
} from "@material-ui/core";

import {
  createResource,
  getResourceById,
  editResource,
} from "../../../services/resourcesService";
import CenterBox from "../../../components/UI/CenterBox";
import { dateToISOStringNoTime } from "../../../utils/util";

const ResourceFormScreen = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [resourceData, setResourceData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(null);

  const onFileSelectedHandler = (event) => {
    console.log(event.target.files[0]);
    const updatedData = {
      ...resourceData,
      file: {
        value: event.target.files[0].name,
        data: event.target.files[0],
        error: false,
      },
    };

    setResourceData(updatedData);
  };

  const onValueChangedHandler = (event, id) => {
    const updatedData = {
      ...resourceData,
      [id]: {
        ...resourceData[id],
        value: event.target.value,
        error: false,
      },
    };

    setResourceData(updatedData);
  };

  const onDateChangeHandler = (date, id) => {
    const updatedResourceData = {
      ...resourceData,
      [id]: {
        value: date,
      },
    };

    setResourceData(updatedResourceData);
  };

  const onSaveButtonClickHandler = () => {
    let hasError = false;
    const updatedState = {
      ...resourceData,
    };

    if (resourceData.name.value === "") {
      updatedState.name.error = true;
      hasError = true;
    }

    if (resourceData.type.value === "") {
      updatedState.type.error = true;
      hasError = true;
    }

    if (!resourceData.file.value) {
      updatedState.file.value = "FILE REQUIRED";
      updatedState.file.error = true;
      hasError = true;
    }

    setResourceData(updatedState);

    if (hasError) return;
    // Validation

    const publishDate = dateToISOStringNoTime(
      new Date(resourceData.publishDate.value)
    );
    const expireDate = dateToISOStringNoTime(
      new Date(resourceData.expireDate.value)
    );

    setLoading(true);
    setError(false);
    const formData = new FormData();
    formData.append("name", resourceData.name.value);
    formData.append("type", resourceData.type.value);
    formData.append("description", resourceData.description.value);
    formData.append("active", 1);
    formData.append("publishDate", publishDate);
    formData.append("expireDate", expireDate);
    formData.append("resource-file", resourceData.file.data);

    const makeRequest = props.create
      ? createResource(formData)
      : editResource(id, formData);

    makeRequest
      .then((response) => {
        history.replace("/resources/" + response.data.id);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.create) {
      const todaysDate = dateToISOStringNoTime(new Date());
      setResourceData({
        name: {
          value: "",
          error: false,
        },
        type: {
          value: "",
          error: false,
        },
        description: {
          value: "",
        },
        publishDate: {
          value: todaysDate,
          error: false,
        },
        expireDate: {
          value: todaysDate,
          error: false,
        },
        file: {
          value: "",
          data: null,
          error: false,
        },
      });
    } else {
      getResourceById(id)
        .then((response) => {
          setResourceData({
            name: {
              value: response.data.name,
              error: false,
            },
            type: {
              value: response.data.type,
              error: false,
            },
            description: {
              value: response.data.description,
            },
            publishDate: {
              value: response.data.publishDate
                ? dateToISOStringNoTime(new Date(response.data.publishDate))
                : new Date(),
              //value: response.data.publishDate || new Date(),
              error: false,
            },
            expireDate: {
              value: response.data.expireDate
                ? dateToISOStringNoTime(new Date(response.data.expireDate))
                : new Date(),

              //value: response.data.expireDate || new Date(),
              error: false,
            },
            file: {
              value: response.data.filename,
              data: null,
              error: false,
            },
          });
        })
        .catch((err) => {
          //
        });
    }
  }, []);

  let displayData;

  if (resourceData) {
    displayData = (
      <EditResourceForm
        create={props.create}
        resourceData={resourceData}
        onSaveClick={onSaveButtonClickHandler}
        onTextChange={onValueChangedHandler}
        onFileChange={onFileSelectedHandler}
        onDateChange={onDateChangeHandler}
      />
    );
  } else {
    displayData = (
      <CenterBox>
        {hasError ? (
          <Typography>Error Occurred...</Typography>
        ) : (
          <CircularProgress />
        )}
      </CenterBox>
    );
  }

  return (
    <Container maxWidth="md">
      <Box py={2}>{displayData}</Box>
    </Container>
  );
};

export default ResourceFormScreen;
