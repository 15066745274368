import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { formatToDisplayDate } from "../../../../utils/util";
import { getAccountById } from "../../../../services/superAdminService";
import { useParams } from "react-router-dom";

const AccountDetails = () => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);

    getAccountById(id).then((response) => {
      setAccount(response.data);
      setLoading(false);
    });
  }, []);

  var view;
  if (loading) {
    view = <CircularProgress />;
  } else {
    view = (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3">{account.accountName}</Typography>
        </Grid>
        <Grid item>
          <Paper>
            <Box m={1}></Box>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="caption">Type</Typography>
                <Typography>{account.type === 1 ? "REGULAR" : ""}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Status</Typography>
                <Typography>
                  {account.active === 1 ? "ACTIVE" : "DISABLED"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Created</Typography>
                <Typography>
                  {formatToDisplayDate(account.createdAt)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Grid container direction="column">
              <Grid item>
                <Box p={2}>
                  <Typography variant="h4">Users</Typography>
                </Box>
              </Grid>
              <Grid item>
                <UsersTable workers={account.workers} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
  return <Container maxWidth="lg">{view}</Container>;
};

const UsersTable = ({ workers }) => {
  const getUserType = (type) => {
    switch (type) {
      case 1:
        return "ADMIN";
      case 2:
        return "SAFETY COORDINATOR";
      case 3:
        return "EMPLOYEE";
      default:
        return "";
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Title</TableCell>
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Type</TableCell>
          <TableCell align="right">Last Login</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {workers.map(({ user, ...worker }) => (
          <TableRow key={worker.id}>
            <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>
            <TableCell align="right">{worker.title}</TableCell>
            <TableCell align="right">
              {user?.enabled ? "ENABLED" : "DISABLED"}
            </TableCell>
            <TableCell align="right">{getUserType(user?.type)}</TableCell>
            <TableCell align="right">
              {user?.lastLogin
                ? formatToDisplayDate(user?.lastLogin)
                : "NO LOGIN FOUND"}
            </TableCell>

            <TableCell align="right">
              {/*  <Button variant="outlined" color="primary">
                Edit User
              </Button> */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AccountDetails;
