import React from "react";

import { Paper, Box, Grid, Typography, Divider } from "@material-ui/core";
import FirstAidersTable from "./FirstAidersTable";

const EmergencyNumberDetail = ({ eNumberForm }) => {
  const numbersList = eNumberForm.emergencyNumbers.map(number => {
    return (
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={number.id}>
        <Typography variant="caption">{number.name}</Typography>
        <Typography variant="h6">{number.primaryNumber}</Typography>
      </Grid>
    );
  });

  const formName = `${eNumberForm.project.name} Emergency Numbers Form`;

  return (
    <Paper>
      <Box p={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography>Form Information</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Project</Typography>
            <Typography variant="h6">{eNumberForm.project.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Safety Coordinator</Typography>
            <Typography variant="h6">{`${eNumberForm.coordinator.firstName} ${eNumberForm.coordinator.lastName}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Nearest Hospital</Typography>
            <Typography variant="h6">{`${eNumberForm.hospital.name} (${eNumberForm.hospital.address})`}</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>Emergency Numbers</Typography>
          </Grid>
          

          <Grid item>
            <Grid container spacing={3}>
              {numbersList}
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>First Aiders</Typography>
          </Grid>
          <Grid item>
            <FirstAidersTable firstAiders={eNumberForm.firstAiders} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default EmergencyNumberDetail;
