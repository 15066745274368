import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Paper, Typography, CircularProgress, Box } from "@material-ui/core";
import * as checklistsService from "../../services/checklistService";
import FirstAidChecklistsTable from "../Checklists/FirstAidChecklistsTable";
import InpsectionChecklistsTable from "../Checklists/InspectionChecklistsTable";
import InspectionChecklistsTable from "../Checklists/InspectionChecklistsTable";
import CenterBox from "../UI/CenterBox";

const ProjectChecklists = (props) => {
  const history = useHistory();
  const [projectChecklists, setProjectChecklists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [queryData, setQueryData] = useState({
    params: {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  });

  const pageChangeHandler = (event, newPage) => {
    setPage(newPage);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setQueryData({
      ...queryData,
      params: {
        ...queryData.params,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const onChecklistSelectedHandler = (event, id) => {
    history.push(`/checklists/${id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    checklistsService
      .getChecklistsOnProject(props.projectId, props.checklistType, queryData.params)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setProjectChecklists(response.data.checklists);
        setTotal(response.data.total);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [queryData]);

  let checklistsTable = null;

  if (!isLoading) {
    if (props.checklistType == 3) {
      checklistsTable = (
        <FirstAidChecklistsTable
          checklists={projectChecklists}
          onChecklistSelected={onChecklistSelectedHandler}
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          pageChangeHandler={pageChangeHandler}
          rowsPerPageChangeHandler={rowsPerPageChangeHandler}
        
        />
      );
    } else {
      checklistsTable = (
        <InspectionChecklistsTable
          checklists={projectChecklists}
          onChecklistSelected={onChecklistSelectedHandler}
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          pageChangeHandler={pageChangeHandler}
          rowsPerPageChangeHandler={rowsPerPageChangeHandler}
        />
      );
    }
  }

  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box m={1}>
        <Typography variant="h5">{props.name}</Typography>
      </Box>

      {isLoading ? (
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      ) : (
        checklistsTable
      )}
      {/* 
      {checklistsTable}
      {isLoading && <CircularProgress />}
      {error && <div>{error}</div>} */}
    </Paper>
  );
};

export default ProjectChecklists;
