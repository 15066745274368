import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initState = {
    users: [],
    selectedUser: {},
    loading: false,
    error: false
}

const reducer = (state = initState, action ) => {
    switch (action.type) {
        case actionTypes.GET_ALL_USERS_ON_ACCOUNT_REQUEST: return updateObject(state, { loading: true});
        case actionTypes.GET_ALL_USERS_ON_ACCOUNT_SUCCESS: return updateObject(state, {
            loading: false,
            users: action.users
        });
        case actionTypes.GET_USER_DETAILS_SUCCESS: return updateObject(state, {
            loading: false,
            selectedUser: action.selectedUser
        });
        case actionTypes.GET_ALL_USERS_ON_ACCOUNT_FAILURE: return updateObject(state, { 
            loading: false,
            error: true
        
        });
        default: return state;
    }
};

export default reducer;