import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import BasePaper from "../../../../components/UI/BasePaper";
import { formatDateWithoutDay } from "../../../../utils/util";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      tableLayout: "fixed",
    },
    "table-cell": {
      paddingLeft: "0px",
      borderBottom: "none",
    },
  })
);

const DetailsPaper = ({
  equipment = { unitNumber: "1231", createdAt: "September 11, 2001" },
  onDeleteClick,
  onEditClick,
}) => {
  const classes = useStyles();

  const details = (
    <Table size="small" className={classes.table}>
      <colgroup>
        <col width="15%" />
        <col width="85%" />
      </colgroup>
      <TableBody>
        <TableRow>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              <strong>Unit Number:</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>{equipment?.unitNumber}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              <strong>Created At:</strong>
            </Typography>
          </TableCell>
          <TableCell className={classes["table-cell"]}>
            <Typography>
              {formatDateWithoutDay(equipment?.createdAt)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <BasePaper>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          spacing={2}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h6">
              <strong>Details</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="secondary" onClick={onDeleteClick}>
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onEditClick} color="primary">
                  <strong>Edit</strong>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {details}
      </Grid>
    </BasePaper>
  );
};

export default DetailsPaper;
