import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

import MainViewContainer from "../../hoc/MainViewContainer";
import { showCurrentShortMonth } from "../../utils/util";
import AccountActivityCards from "./components/AccountActivityCards";
import CurrentProjects from "./components/CurrentProjects";
import IncidentsToReview from "./components/IncidentsToReview";
import Users from "./components/Users";

const Dashboard = () => {
  return (
    <MainViewContainer>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">
            <strong>Dashboard</strong>
          </Typography>
        </Grid>

        <Grid item lg={9} md={12} sm={6}>
          <Box pb={1}>
            <Typography display="inline" variant="h6">
              <strong>Activity This Month</strong>
            </Typography>
            <Typography display="inline">{` (${showCurrentShortMonth()})`}</Typography>
          </Box>
          <AccountActivityCards />
        </Grid>

        <Grid item lg={9} md={12}>
          <Box pb={1}>
            <Typography variant="h6">
              <strong>Current Projects</strong>
            </Typography>
          </Box>
          <CurrentProjects />
        </Grid>

        <Grid item lg={9} md={12}>
          <Box pb={1}>
            <Typography variant="h6">
              <strong>Incidents To Review</strong>
            </Typography>
          </Box>
          <IncidentsToReview />
        </Grid>

        <Grid item lg={9} md={12}>
          <Box pb={1}>
            <Typography variant="h6">
              <strong>Users</strong>
            </Typography>
          </Box>
          <Users />
        </Grid>
      </Grid>
    </MainViewContainer>
  );
};

export default Dashboard;
