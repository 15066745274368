import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import authReducer from "./store/reducers/auth";
import projectReducer from "./store/reducers/project";
import workerReducer from "./store/reducers/worker";
import contractorReducer from "./store/reducers/contractor";
import userReducer from "./store/reducers/user";

import Projects from "./containers/Projects/Projects";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import customTheme from "./hoc/Theme/customTheme";

import AuthContextProvider from "./context/auth-context";
import EquipmentWorkAssignmentProvider from "./context/equipment-work-assignment-context";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  worker: workerReducer,
  contractor: contractorReducer,
  user: userReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <ThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <CssBaseline />
          <AuthContextProvider>
            <EquipmentWorkAssignmentProvider>
              <App />
            </EquipmentWorkAssignmentProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
