import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import CenterBox from "../../../../../components/UI/CenterBox";
import useDeleteEquipmentDialog from "./hooks/useDeleteEquipmentDialog";

const DeleteEquipmentDialog = ({ open, onClose, id }) => {
  const { loading, error, sendDeleteRequest } = useDeleteEquipmentDialog(id);

  const deleteClickHandler = async () => {
    const result = await sendDeleteRequest();

    if (result) onClose(result);
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={() => onClose()}>
      <DialogTitle>
        <strong>Delete Equipment</strong>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Deleting this equipment record will delete all associated checklists
          with it.
        </Typography>
        <Typography>
          Are you sure you want to delete this equipment record?
        </Typography>
        {!loading && error && (
          <CenterBox marginTop={2}>
            <Typography color="error">{error}</Typography>
          </CenterBox>
        )}
      </DialogContent>

      {loading && (
        <CenterBox marginTop={0}>
          <CircularProgress />
        </CenterBox>
      )}
      <DialogActions>
        {!loading && (
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            onClick={deleteClickHandler}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEquipmentDialog;
