import axios from "../axios";

export const getCertifications = params => {
  return axios.get("/certifications", { params: { ...params } });
};

export const getCertificationById = id => {
  return axios.get("/certifications/" + id);
};

export const createCertification = data => {
  return axios.post("/certifications", data);
};

export const editCertification = (id, data) => {
  return axios.put("/certifications/" + id, data);
};

export const deleteCertificationById = id => {
  return axios.delete("/certifications/" + id);
};

// export const getChecklistsOnProject = (projectId, type) => {
//     return axios.get(`/projects/${projectId}/checklists`, {
//         params: {
//             type: type
//         }
//     });
// }
