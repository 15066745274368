import React, { Fragment } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Box,
} from "@material-ui/core";
import strings from "../../strings";
import { formatToDisplayDate } from "../../utils/util";

const SafetyTalksTable = (props) => {
  const safetyTalkRows = props.safetyTalks.map((talk) => {
    return (
      <TableRow hover key={talk.id}>
        <TableCell component="th" scope="row">
          {talk.name}
        </TableCell>
        <TableCell align="left">
          {strings.SAFETY_TALK_STATES[talk.state]}
        </TableCell>
        <TableCell align="left">{formatToDisplayDate(talk.createdDate)}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => props.safetyTalkSelected(event, talk.id)}
          >
            <Box px={2}>View</Box>
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Fragment>
      <Table style={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>State</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Date Created</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{safetyTalkRows}</TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.pageChangeHandler}
        onChangeRowsPerPage={props.rowsPerPageChangeHandler}
      />
    </Fragment>
  );
};

export default SafetyTalksTable;
