import React from "react";
import { Button, Box } from "@material-ui/core";
import { Save } from "@material-ui/icons";

const SaveButton = ({ onClick, disabled }) => {
  return (
    <Button
      startIcon={<Save />}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      <Box px={2}>Save</Box>
    </Button>
  );
};

export default SaveButton;
