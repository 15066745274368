import React, { useEffect, useState } from "react";
import { useHistory, Switch, Route, useRouteMatch } from "react-router-dom";
import { Container, Typography, Button, Box } from "@material-ui/core";

import * as resourcesService from "../../services/resourcesService";
import ResourcesTable from "../../components/Resources/ResourcesTable";
import ResourcesSearch from "../../components/Resources/ResourcesSearch";
import ResourcesScreen from "./ResourcesScreen";
import ResourceFormScreen from "./Resource/ResourceFormScreen";
import ResourceDetailScreen from "./Resource/ResourceDetailScreen";

const Resources = props => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/new`}
        exact
        render={props => <ResourceFormScreen {...props} create />}
      />
      <Route path={`${path}/:id/edit`} component={ResourceFormScreen} />
      <Route path={`${path}/:id`} component={ResourceDetailScreen} />

      <Route exact path={path} component={ResourcesScreen} />
    </Switch>
  );
};

export default Resources;
