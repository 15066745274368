import axios from "../axios";

export const getIncidentsOnProject = (projectId, params) => {
  return axios.get(`/projects/${projectId}/incidents`, {
    params,
  });
};

export const getIncidents = (params = {}) => {
  return axios.get(`/incidents`, {
    params,
  });
};

export const getIncidentById = (incidentId) => {
  return axios.get(`/incidents/${incidentId}`);
};

export const updateIncidentById = ({ id, incidentData }) => {
  return axios.patch(`/incidents/${id}`, incidentData);
};

export const getCorrectiveActionsOnIncident = (id) => {
  return axios.get(`/incidents/${id}/corrective-actions`);
};

export const getCorrectiveActionById = (id) => {
  return axios.get("/corrective-actions/" + id);
};
