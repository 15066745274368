import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getCovidScreeningById } from '../../services/checklistService';
import CenterBox from '../UI/CenterBox';

const itemToDisplayItemMap = {
  fever: 'Fever or chills',
  difficulty_breathing: 'Difficulty breathing or shortness of breath',
  cough: 'Cough',
  sore_throat: 'Sore throat, trouble swallowing',
  runny_nose: 'Runny nose / stuffy nose or nasal congestion',
  lost_taste_smell: 'Decrease or loss of smell or taste',
  nausea: 'Nausea, vomiting, diarrhea, abdominal pain',
  extreme_tiredness: 'Not feeling well, extreme tiredness, sore muscles',
  travelled_outside:
    'Have you travelled outside of Canada in the past 14 days?',
  close_contact:
    'Have you had close contact with a confirmed or probable case of COVID-19?',
};

const CovidScreening = ({ id, open, onClose }) => {
  const [screening, setScreening] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getScreeningDetails = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getCovidScreeningById(id);

      setScreening(response.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open === true) {
      getScreeningDetails(id);
    }
  }, [id, open]);

  let display;

  if (loading || error || !screening) {
    display = (
      <CenterBox>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography>Error occurred</Typography>
        )}
      </CenterBox>
    );
  } else {
    display = <ScreeningBody items={screening.items} />;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box p={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5">
              <strong>Daily COVID-19 Screening</strong>
            </Typography>
          </Grid>
          <Grid item>{display}</Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

const ScreeningBody = ({ items = {} }) => {
  const displayItems = Object.keys(items).map((key, idx) => {
    return (
      <ListItem>
        <Grid container justify="space-between">
          <Grid item>
            <Typography>{itemToDisplayItemMap[key]}</Typography>
          </Grid>
          <Grid item>
            {items[key] === true ? (
              <Typography>Yes</Typography>
            ) : (
              <Typography>No</Typography>
            )}
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return <List>{displayItems}</List>;
};

export default CovidScreening;
