import React, { useEffect } from "react";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton,
} from "@material-ui/core";
import HospitalSelect from "../HospitalSelect";
import ProjectSelect from "../../Project/ProjectSelect";
import WorkerSelect from "../../Workers/Worker/WorkerSelect";
import CustomTable from "../../UI/CustomTable";
import useRequestHandler from "../../../utils/requestHandler";
import { getWorkersOnProject } from "../../../services/workerService";
import FirstAidersTable from "./FirstAidersTable";
import { Add } from "@material-ui/icons";

const EmergencyNumberForm = ({
  eNumberData,
  onTextChange,
  onSaveClick,
  onNumberChange,
  onAddFirstAidersClick,
  onCreateHospitalClick,
  edit,
  hospitalsRefresh,
}) => {
  const numbersList = eNumberData.emergencyNumbers.map((number, index) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={number.name}>
        <TextField
          id="outlined-basic"
          label={number.name}
          onChange={(event) => onNumberChange(event, number.name)}
          variant="outlined"
          value={eNumberData.emergencyNumbers[index].primaryNumber}
          fullWidth
          inputProps
          placeholder="(000)-000-0000"
        />
      </Grid>
    );
  });

  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              {edit ? "Edit" : "New"} Emergency Numbers Form
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Form Information</Typography>
          </Grid>
          <Grid item>
            <ProjectSelect
              onChange={(event) => onTextChange(event, "project")}
              error={eNumberData.project.error}
              selected={eNumberData.project.value}
            />
          </Grid>
          <Grid item>
            <WorkerSelect
              onChange={(event) => onTextChange(event, "coordinator")}
              error={eNumberData.coordinator.error}
              selected={eNumberData.coordinator.value}
              projectId={eNumberData.project.value}
            />
          </Grid>

          <Grid item container direction="row" spacing={2} alignItems="center">
            <Grid md={9} lg={9} sm={9} xs={9}>
              <HospitalSelect
                onChange={(event) => onTextChange(event, "hospital")}
                error={eNumberData.hospital.error}
                selected={eNumberData.hospital.value}
                refresh={hospitalsRefresh}
              />
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={onCreateHospitalClick}>
                <Add />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Typography>Emergency Numbers</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              {numbersList}
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>First Aiders</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={onAddFirstAidersClick}
            >
              Add First Aider
            </Button>
          </Grid>
          <Grid item>
            <FirstAidersTable
              projectId={eNumberData.project.value}
              firstAiders={eNumberData.firstAiders}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" onClick={onSaveClick}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default EmergencyNumberForm;
