import React, {
  Component,
  useEffect,
  useContext,
  Fragment,
  useState,
} from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions/index";

import Auth from "./containers/Auth/Auth";
import Projects from "./containers/Projects/Projects";
import Home from "./containers/Home/Home";
import { createMuiTheme } from "@material-ui/core/styles";

import logo from "./logo.svg";
import "./App.css";
import Layout from "./hoc/Layout/Layout";
import Workers from "./containers/Workers/Workers";
import WorkerDetail from "./containers/Workers/Worker/Worker";
import Contractors from "./containers/Contractors/Contractors";
import ContractorDetails from "./containers/Contractors/Contractor/Contractor";
import Users from "./containers/Users/Users";
import ChecklistDetail from "./containers/Checklists/Checklist/Checklist";
import InspectionFinding from "./containers/InspectionFindings/InspectionFinding/InspectionFinding";
import Resources from "./containers/Resources/Resources";
import Resource from "./containers/Resources/Resource/Resource";
import SafetyTalkDetail from "./containers/SafetyTalks/SafetyTalk/SafetyTalk";
import CreateEmployee from "./components/Workers/Worker/CreateEmployee";
import CreateContractorForm from "./components/Contractors/Contractor/CreateContractorForm";
import CreateProjectContainer from "./containers/Projects/Project/CreateProject";
import EditResourceForm from "./components/Resources/Resource/EditResourceForm";
import CreateResourceContainer from "./containers/Resources/Resource/CreateResourceContainer";
import ContractorContainer from "./containers/Contractors/Contractor/ContractorContainer";
import WorkerFormContainer from "./containers/Workers/Worker/WorkerForm";
import ResourceFormScreen from "./containers/Resources/Resource/ResourceFormScreen";
import WorkersScreen from "./containers/Workers/WorkersScreen";
import EmployeeDetailScreen from "./containers/Workers/Worker/EmployeeDetailScreen";
import CertificationsScreen from "./containers/Certifications/CertificationsScreen";
import CertificationDetailScreen from "./containers/Certifications/Certification/CertificationDetailScreen";
import CertificationFormScreen from "./containers/Certifications/Certification/CertificationFormScreen";
import ProjectDetailScreen from "./containers/Projects/Project/ProjectDetailScreen";
import ProjectFormScreen from "./containers/Projects/Project/ProjectFormScreen";
import EmergencyNumbersScreen from "./containers/EmergencyNumbers/EmergencyNumbersScreen";
import EmergencyNumberDetailScreen from "./containers/EmergencyNumbers/EmergencyNumber/EmergencyNumberDetailScreen";
import EmergencyNumberFormScreen from "./containers/EmergencyNumbers/EmergencyNumber/EmergencyNumberFormScreen";
import { lightBlue } from "@material-ui/core/colors";
import IncidentDetails from "./containers/Incidents/IncidentDetails/IncidentDetails";
import IncidentForm from "./containers/Incidents/IncidentDetails/IncidentForm";
import LogoutPage from "./containers/Auth/Logout/Logout";
import { AuthContext } from "./context/auth-context";
import axios from "./axios";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import PasswordReset from "./containers/Auth/PasswordReset/PasswordReset";
import SuperAdminLoginScreen from "./containers/SuperAdmin/Login/Login";
import Accounts from "./containers/SuperAdmin/Accounts/Accounts";
import AccountDetails from "./containers/SuperAdmin/Accounts/Account/AccountDetails";
import FAQPage from "./containers/FAQ/FAQPage";
import RiskAssessments from "./containers/RiskAssessments/RiskAssessments";
import Equipment from "./containers/Equipment/Equipment";
import EquipmentDetails from "./containers/Equipment/EquipmentDetails/EquipmentDetails";
import EquipmentChecklistDetails from "./containers/EquipmentChecklists/EquipmentChecklistDetails/EquipmentChecklistDetails";
import WorkAssignmentDetailsPage from "./containers/EquipmentChecklists/WorkAssignmentDetailsPage";
import Dashboard from "./containers/Dashboard/Dashboard";

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Switch>
        <Route path="/login" component={Auth} />

        <Route path="/" exact render={(props) => <Redirect to="/login" />} />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      console.log("Auth!");
      routes = (
        <Layout>
          <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/login" component={Auth} />
            <Route
              path="/projects/create"
              exact
              component={ProjectFormScreen}
            />
            <Route path="/projects/:id/edit" component={ProjectFormScreen} />
            <Route
              path="/projects/:id/emergency-numbers"
              component={EmergencyNumberDetailScreen}
            />

            <Route path="/projects/:id" component={ProjectDetailScreen} />
            <Route path="/projects" component={Projects} />

            <Route
              path="/workers/create"
              exact
              render={(props) => <WorkerFormContainer {...props} />}
            />
            <Route
              path="/workers/:id/edit"
              render={(props) => <WorkerFormContainer {...props} edit />}
            />

            <Route path="/workers/:id" component={EmployeeDetailScreen} />
            <Route path="/workers" component={WorkersScreen} />
            {/* <Route path="/contractors/create" exact component={CreateContractorForm} />
            <Route path="/contractors/:id" component={ContractorDetails} /> */}
            <Route path="/contractors" component={ContractorContainer} />
            <Route path="/users" component={Users} />
            <Route path="/checklists/:id" component={ChecklistDetail} />
            <Route
              path="/inspection-findings/:id"
              component={InspectionFinding}
            />
            {/* <Route path="/resources/new" exact component={CreateResourceContainer} />
            <Route path="/resources/:id" component={Resource} /> */}

            <Route path="/resources" component={Resources} />
            <Route path="/safety-talks/:id" component={SafetyTalkDetail} />
            <Route
              path="/certifications/create"
              exact
              component={CertificationFormScreen}
            />
            <Route
              path="/certifications/:id/edit"
              exact
              component={CertificationFormScreen}
            />

            <Route
              path="/certifications/:id"
              component={CertificationDetailScreen}
            />
            <Route path="/certifications" component={CertificationsScreen} />
            <Route
              path="/emergency-numbers/create"
              exact
              component={EmergencyNumberFormScreen}
            />

            <Route
              path="/emergency-numbers/:id/edit"
              exact
              component={EmergencyNumberFormScreen}
            />

            <Route
              path="/emergency-numbers/:id"
              exact
              component={EmergencyNumberDetailScreen}
            />

            {/* <Route path="/emergency-numbers" component={EmergencyNumbersScreen} /> */}

            <Route path="/incidents/:id/edit" exact component={IncidentForm} />

            <Route path="/incidents/:id" exact component={IncidentDetails} />

            <Route path="/logout" component={LogoutPage} />
            <Route
              path="/"
              exact
              render={(props) => <Redirect to="/projects" />}
            />
          </Switch>
        </Layout>
      );
    }

    return <div>{routes}</div>;
  }
}

const AppComp = () => {
  const authContext = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("userData");
      authContext.logout();
    } else {
      const expDate = new Date(localStorage.getItem("expirationDate"));
      if (expDate > new Date()) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const { name, type, accountName, userId } = JSON.parse(
          localStorage.getItem("userData")
        );
        authContext.login(name, type, accountName, token, expDate, userId);
      } else {
        authContext.logout();
      }
    }

    setLoaded(true);
  }, []);

  if (!loaded) {
    return <div></div>;
  }

  let routes = (
    <Switch>
      <Route path="/login" component={Auth} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/password-reset" component={PasswordReset} />

      <Route path="/" exact render={(props) => <Redirect to="/login" />} />

      <Route path="/super-admin/login" component={SuperAdminLoginScreen} />
      <Route path="/faqs" component={FAQPage} />
    </Switch>
  );

  console.log(localStorage.getItem("token"));

  if (authContext.authState) {
    console.log("Auth!");

    if (authContext.authState.type === "ADMIN") {
      routes = (
        <Switch>
          <Route path="/super-admin/accounts/:id" component={AccountDetails} />

          <Route path="/super-admin/accounts" component={Accounts} />
          <Route path="/faqs" component={FAQPage} />
        </Switch>
      );
    } else {
      routes = (
        <Layout>
          <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/login" component={Auth} />
            <Route
              path="/projects/create"
              exact
              component={ProjectFormScreen}
            />
            <Route path="/projects/:id/edit" component={ProjectFormScreen} />
            <Route
              path="/projects/:id/emergency-numbers"
              component={EmergencyNumberDetailScreen}
            />

            <Route
              path="/projects/:id/risk-assessments"
              component={RiskAssessments}
            />

            <Route path="/projects/:id" component={ProjectDetailScreen} />
            <Route path="/projects" component={Projects} />

            <Route
              path="/workers/create"
              exact
              render={(props) => <WorkerFormContainer {...props} />}
            />
            <Route
              path="/workers/:id/edit"
              render={(props) => <WorkerFormContainer {...props} edit />}
            />

            <Route path="/workers/:id" component={EmployeeDetailScreen} />
            <Route path="/workers" component={WorkersScreen} />
            {/* <Route path="/contractors/create" exact component={CreateContractorForm} />
            <Route path="/contractors/:id" component={ContractorDetails} /> */}
            <Route path="/contractors" component={ContractorContainer} />
            <Route path="/users" component={Users} />
            <Route path="/checklists/:id" component={ChecklistDetail} />
            <Route
              path="/inspection-findings/:id"
              component={InspectionFinding}
            />
            {/* <Route path="/resources/new" exact component={CreateResourceContainer} />
            <Route path="/resources/:id" component={Resource} /> */}

            <Route path="/resources" component={Resources} />
            <Route path="/safety-talks/:id" component={SafetyTalkDetail} />
            <Route
              path="/certifications/create"
              exact
              component={CertificationFormScreen}
            />
            <Route
              path="/certifications/:id/edit"
              exact
              component={CertificationFormScreen}
            />

            <Route
              path="/certifications/:id"
              component={CertificationDetailScreen}
            />
            <Route path="/certifications" component={CertificationsScreen} />
            <Route
              path="/emergency-numbers/create"
              exact
              component={EmergencyNumberFormScreen}
            />

            <Route
              path="/emergency-numbers/:id/edit"
              exact
              component={EmergencyNumberFormScreen}
            />

            <Route
              path="/emergency-numbers/:id"
              exact
              component={EmergencyNumberDetailScreen}
            />

            {/* <Route path="/emergency-numbers" component={EmergencyNumbersScreen} /> */}

            <Route path="/incidents/:id/edit" exact component={IncidentForm} />

            <Route path="/incidents/:id" exact component={IncidentDetails} />

            <Route path="/equipment/:id" exact component={EquipmentDetails} />

            <Route path="/equipment" exact component={Equipment} />

            <Route
              path="/equipment-checklists/:id"
              exact
              component={EquipmentChecklistDetails}
            />

            <Route path="/dashboard" exact component={Dashboard} />
            <Route
              path="/equipment-checklist-work-assignments/:id"
              exact
              component={WorkAssignmentDetailsPage}
            />

            <Route path="/logout" component={LogoutPage} />
            <Route path="/faqs" component={FAQPage} />

            <Route
              path="/"
              exact
              render={(props) => <Redirect to="/dashboard" />}
            />
          </Switch>
        </Layout>
      );
    }
  }

  return <Fragment>{routes}</Fragment>;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
export default AppComp;
