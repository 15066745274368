import React from "react";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Typography,
  Box,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  Button,
  ListSubheader,
  Grid,
} from "@material-ui/core";
import ChecklistItem from "./ChecklistItem";
import GenericChecklistItem from "./GenericChecklistItem";
import { Check, Warning } from "@material-ui/icons";
import { green, orange } from "@material-ui/core/colors";

const ChecklistItems = (props) => {
  const history = useHistory();
  const onViewInspectionFindingHandler = (event, id) => {
    history.push(`/inspection-findings/${id}`);
  };

  let itemsList = null;
  if (props.type === 1) {
    itemsList = props.checklistItems.map((item) => {
      return <InspectionChecklstItem item={item} />;
    });
  } else if (props.type === 2) {
    itemsList = props.checklistItems.map((sections) => {
      const items = sections.items.map((item) => {
        return (
          <InspectionChecklstItem item={item} />
          /*  <ChecklistItem
            item={item}
            onViewInspectionFindingHandler={onViewInspectionFindingHandler}
          /> */
        );
      });

      return (
        <React.Fragment>
          <ListSubheader>{sections.header}</ListSubheader>
          {items}
        </React.Fragment>
      );
    });
  } else if (props.type === 3) {
    itemsList = props.checklistItems.map((item) => {
      return <FirstAidChecklistItem item={item} />;
    });
  }

  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box p={2}>
        <Typography variant="h5">Checklist Items</Typography>
      </Box>

      <List>{itemsList}</List>
    </Paper>
  );
};

const FirstAidChecklistItem = ({ item }) => {
  let icon = null;

  if (item.state === 1) {
    icon = <Check color="primary" />;
  } else {
    icon = <Check />;
  }
  return (
    <ListItem key={item.id} alignItems="center" divider>
      <ListItemText primary={item.name} />
      {icon}
    </ListItem>
  );
};

const InspectionChecklstItem = ({ item }) => {
  return (
    <ListItem key={item.id} divider>
      <Grid container direction="column">
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <Typography>{item.name}</Typography>
          </Grid>
          <Grid item>
            {item.state === 1 && (
              <Check style={{ color: green[500] }} fontSize="large" />
            )}
            {item.state === 2 && (
              <Warning style={{ color: orange[600] }} fontSize="large" />
            )}
            {item.state === 3 && <Typography color="primary">N / A</Typography>}
          </Grid>
        </Grid>
        {item.inspectionFindings.length > 0 && (
          <Grid item>
            <List component="div">
              {item.inspectionFindings.map((inspection) => {
                return (
                  <ListItem>
                    <InspectionChecklistFindingListItem
                      inspectionFinding={inspection}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

const InspectionChecklistFindingListItem = ({ inspectionFinding }) => {
  const history = useHistory();

  const onViewInspectionFinding = (id) => {
    history.push("/inspection-findings/" + id);
  };

  return (
    <Grid container direction="row" spacing={3} alignItems="center">
      <Grid item>
        {inspectionFinding.state === 0 && (
          <Warning style={{ color: orange[600] }} />
        )}
        {inspectionFinding.state === 1 && (
          <Check style={{ color: green[500] }} />
        )}
      </Grid>
      <Grid item>
        <Typography>{inspectionFinding.name}</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={(event) => onViewInspectionFinding(inspectionFinding.id)}
        >
          View
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChecklistItems;
