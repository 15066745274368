import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from "@material-ui/core";
import CustomTable from "../UI/CustomTable";

const WorkersTable = props => {
  const tableHead = ["Name", "Contractor", "Title", "Email", "User"];
  const tableBody = props.workers.map(worker => {
    const fieldsArray = [
      `${worker.firstName} ${worker.lastName}`,
      worker.contractor ? worker.contractor.name : "",
      worker.title,
      worker.email,
      worker.isUser ? "Yes" : "No"
    ];
    if (props.isProjectWorkers) {
      const orientationState =
        worker.orientationChecklist && worker.orientationChecklist.state === 3
          ? "Complete"
          : "Incomplete";
      fieldsArray.splice(4, 0, orientationState);
    }
    return {
      fields: fieldsArray,
      key: worker.id,
      onSelected: event => props.workerViewClicked(event, worker.id)
    };
  });

  if (props.isProjectWorkers) {
    tableHead.splice(4, 0, "Orientation");
  }

  return <CustomTable tableHead={tableHead} tableBody={tableBody} />;
};

export default WorkersTable;
