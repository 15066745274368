import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { deleteProjectById } from "../../../../services/projectService";
import useNetworkRequest from "../../../../utils/useNetworkRequest";

const ConfirmDeleteProjectDialog = ({ open, onClose, projectName, id }) => {
  const {
    loading,
    error,
    data,
    handleRequest,
    setLoading,
  } = useNetworkRequest();
  const [formState, setFormState] = useState({
    projectName: {
      value: "",
      error: null,
    },
  });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const textChangeHandler = (event) => {
    const { target } = event;
    setFormState((formState) => ({
      ...formState,
      projectName: {
        value: target.value,
      },
    }));

    const valueMatches = target.value === projectName;
    setSubmitButtonEnabled(valueMatches);
  };

  const deleteButtonClickHandler = async () => {
    // Do network request
    const result = await handleRequest(() => deleteProjectById(id));

    if (result) {
      onClose(true);
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={() => onClose(false)}>
      <DialogTitle>
        <strong>Delete Project</strong>
      </DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this project?</Typography>
        <Typography>
          Enter in the project name <strong>{projectName}</strong> to delete
          this project.
        </Typography>
        <Box mt={2} />
        <TextField
          fullWidth
          variant="outlined"
          label="Project Name"
          helperText={projectName}
          onChange={textChangeHandler}
          value={formState.projectName.value}
        />
        {error && <Typography color="error">{error}</Typography>}
        {loading && <CircularProgress />}
      </DialogContent>
      <DialogActions>
        {!loading && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={deleteButtonClickHandler}
            disabled={!submitButtonEnabled}
          >
            Delete Project
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteProjectDialog;
