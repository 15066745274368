import axios from "../axios";

export const getAllProjects = (params = {}) => {
  return axios.get("/projects", { params });
};

export const getProjectById = (pId) => {
  return axios.get("/projects/" + pId);
};

export const createProject = (project) => {
  return axios.post("/projects", project);
};

export const editProject = (projectEntity, id) => {
  return axios.put("/projects/" + id, projectEntity);
};

export const getProjectIssues = (id) => {
  return axios.get(`/projects/${id}/issues`);
};

export const deleteProjectById = (id) => {
  return axios.delete(`/projects/${id}`);
};
