import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const FAQPage = () => {
  return (
    <Container maxWidth="lg">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h3">Frequently Asked Questions</Typography>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography>
              <strong>Rebar Web Portal Tutorials</strong>
            </Typography>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/raiPWZe96UdHXZ8rTfnwuY?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Login to Rebar Web Portal
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/4aRvJLu9jUDSEGDAEMXuH8?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Add a Contractor in Rebar Web Portal
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/RDP8hV9FG26CbBAyUksm7o?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Add a Worker in Rebar Web Portal
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/EuzL7RoCG8HG17kvKUDBud?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Create and Manage Projects in Rebar Web Portal
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/qyR19XU7zTAtUqxDLR4oaP?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Add Worker and Contractor Certifications in Rebar Web
              Portal
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/Gd1AqaJ8ejBqcmw6WbZEyr?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Add Resources (Health and Safety Policies, Safety Talk
              Documents, Safety Data Sheets) in Rebar Web Portal
            </a>
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography>
              <strong>Rebar Mobile App Tutorials</strong>
            </Typography>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/A46SFacYj1ohypF2kiP5xk?"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rebar Mobile Login and Home Screen
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/uMWGo6bgJ5DjcMUQjGpdof"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Complete a Checklist on Rebar Mobile
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/63BgGHti4gs4auiAiKeDT4?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Complete a Safety Talk on Rebar Mobile
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/5snzKwNSfMW2DAGd6DpTyh?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Report an Incident on Rebar Mobile
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/LB7i7PeEsWuGFqMHvKQjf4?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Complete a Worker Orientation on Rebar Mobile
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://share.vidyard.com/watch/3RvvdjVHHzG2FhMhg6QmfU?"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to View Emergency Numbers and Resources on Rebar Mobile
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQPage;
