import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React from "react";

const EquipmentChecklistItem = ({ item }) => {
  return (
    <Box py={1} px={3} style={{ border: "1px solid #D3D3D3" }}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography>{item.label}</Typography>
        </Grid>
        <Grid item>
          <Box px={4}>
            <Grid container spacing={4}>
              <Grid item>
                <Tooltip title="Not Applicable">
                  <Typography
                    variant="h5"
                    style={
                      item.value === "NOT_APPLICABLE"
                        ? { color: "#01579B" }
                        : { color: "#D3D3D3" }
                    }
                  >
                    <strong>N / A</strong>
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Passed">
                  <Typography
                    variant="h5"
                    style={
                      item.value === "PASSED"
                        ? { color: "#46B83D" }
                        : { color: "#D3D3D3" }
                    }
                  >
                    <strong>P</strong>
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Maintenance Required">
                  <Typography
                    variant="h5"
                    style={
                      item.value === "MAINTENANCE_REQUIRED"
                        ? { color: "#EE7302" }
                        : { color: "#D3D3D3" }
                    }
                  >
                    <strong>M</strong>
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Rejected">
                  <Typography
                    variant="h5"
                    style={
                      item.value === "REJECTED"
                        ? { color: "#EE0202" }
                        : { color: "#D3D3D3" }
                    }
                  >
                    <strong>R</strong>
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EquipmentChecklistItem;
