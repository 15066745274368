import React, { useEffect, useState, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as safetyTalksService from "../../../services/safetyTalksService";
import { Container, Grid, Box } from "@material-ui/core";
import SafetyTalkDetails from "../../../components/SafetyTalks/SafetyTalk/SafetyTalkDetails";
import TalkMembers from "../../../components/SafetyTalks/SafetyTalk/TalkMembers";

const SafetyTalkDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const [safetyTalk, setSafetyTalk] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onResourceLinkClickHandler = (event, id) => {
    event.preventDefault();
    history.push('/resources/' + id);
  }

  useEffect(() => {
    setIsLoading(true);
    safetyTalksService
      .getSafetyTalkDetails(id)
      .then(response => {
        setSafetyTalk(response.data);
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);

        setIsLoading(false);
      });
  }, []);

  let talkView = null;
  if (safetyTalk) {
    talkView = (
      <Fragment>
        <Grid item xs>
          <SafetyTalkDetails safetyTalk={safetyTalk} resourceLinkClick={onResourceLinkClickHandler} />
        </Grid>
        <Grid item xs>
          <TalkMembers talkMembers={safetyTalk.talkMembers} />
        </Grid>
      </Fragment>
    );
  }

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        {talkView}
      </Grid>
    </Box>
  );
};

export default SafetyTalkDetail;
