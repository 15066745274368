import { TextField } from '@material-ui/core';
import React from 'react';
import InputMask from 'react-input-mask';

const PhoneNumberInputMask = ({ onChange, value }) => {
  return (
    <InputMask mask="999-999-9999" value={value} onChange={onChange}>
      {() => <TextField variant="outlined" fullWidth label="Phone Number" />}
    </InputMask>
  );
};

export default PhoneNumberInputMask;
