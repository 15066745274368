import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as inspectionFindingService from "../../../services/inspectionFindingService";
import InspectionFindingInfo from "../../../components/InspectionFindings/FindingInfo";
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Box,
} from "@material-ui/core";
import CenterBox from "../../../components/UI/CenterBox";

const InspectionFinding = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [inspectionFinding, setInspectionFinding] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onAssignedUserClick = (event, id) => {
    history.push(`/workers/${id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    inspectionFindingService
      .getInspectionFindingById(id)
      .then((response) => {
        setInspectionFinding(response.data);

        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading || error || !inspectionFinding) {
    return (
      <CenterBox>
        {error ? <Typography>Error occurred</Typography> : <CircularProgress />}
      </CenterBox>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Inspection Findings</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              <strong>{inspectionFinding.name}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Box pt={2}>
            <InspectionFindingInfo
              finding={inspectionFinding}
              onAssignedUserClick={onAssignedUserClick}
            />
            </Box>
           
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default InspectionFinding;
