import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getChecklistById } from '../../../services/checklistService';
import OrientationChecklistBody from './components/OrientationChecklistBody/OrientationChecklistBody';
import OrientationChecklistHeader from './components/OrientationChecklistHeader';
import CenterBox from '../../../components/UI/CenterBox';

const OrientationChecklist = ({ open, onClose, checklistId }) => {
  const [checklist, setChecklist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getChecklistDetails = async (checklistId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getChecklistById(checklistId);

      setChecklist(response.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open === true) {
      getChecklistDetails(checklistId);
    }
  }, [open, checklistId]);

  let data;

  if (loading || error) {
    data = (
      <CenterBox pb={2}>
        {error ? <Typography>Error Occurred</Typography> : <CircularProgress />}
      </CenterBox>
    );
  } else if (checklist) {
    data = (
      <Box p={2}>
        <Grid container>
          <OrientationChecklistHeader
            checklistName={checklist.name}
            workerName={checklist.worker?.fullName}
            dateCompleted={checklist.modifiedDate}
          />
          <OrientationChecklistBody checklist={checklist} />
        </Grid>
      </Box>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {data}
    </Dialog>
  );
};

export default OrientationChecklist;
