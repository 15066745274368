import React, { useState } from "react";

/*
 *
 *  Handles network requests and state regarding the request
 *
 */
const useRequestHandler = (initLoading = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(initLoading);
  const [hasError, setHasError] = useState(null);

  const handleRequest = (request) => {
    setIsLoading(true);
    setHasError(null);

    console.log("Making request...");

    return request
      .then((response) => {
        console.log("Request complete");
        setData(response.data.workers);
      })
      .catch((err) => {
        console.log(err);
        setHasError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return { data, isLoading, hasError, handleRequest };
};

export default useRequestHandler;
