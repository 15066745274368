import React, { Fragment } from "react";
import { Box, Typography, Paper, Link, Grid } from "@material-ui/core";
import moment from "moment";
import { formatToDisplayDate } from "../../utils/util";

const priorityText = ["None", "Low", "Medium", "High"];

const InspectionFindingInfo = ({ finding, onAssignedUserClick }) => {
  const itemComplete = finding.state == 1 ? "Completed" : "Incomplete";
  const priorityLabel = priorityText[finding.priority];

  const createdDate = formatToDisplayDate(finding.createdDate);

  const assignedUser = finding.assignedUser;

  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption">Assigned User</Typography>
            <Typography variant="h6">
              <Link
                href="#"
                onClick={(event) => onAssignedUserClick(event, assignedUser.id)}
              >
                {assignedUser.name}
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">State</Typography>
            <Typography variant="h6">{itemComplete}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Priority</Typography>
            <Typography variant="h6">{priorityLabel}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Created Date</Typography>
            <Typography variant="h6">{`${formatToDisplayDate(
              finding.createdDate
            )}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Complete By Date</Typography>
            <Typography variant="h6">{`${formatToDisplayDate(
              finding.completeByDate
            )}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Completed Date</Typography>
            <Typography variant="h6">{`${formatToDisplayDate(
              finding.completedDate
            )}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Description</Typography>
            <Typography variant="h6">{finding.findingDescription}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Location</Typography>
            <Typography variant="h6">{finding.findingLocation}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Image</Typography>
            {finding.findingPictureSignedUrl && <Box>
              <img
                src={finding.findingPictureSignedUrl}
                alt="Finding-IMG"
                style={{ height: 250, objectFit: "contain" }}
              />
            </Box>}
         
          </Grid>
          {finding.state === 1 && (
            <Fragment>
              <Grid item>
                <hr />
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  <strong>Action</strong>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Description</Typography>
                <Typography variant="h6">
                  {finding.actionDescription}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Image</Typography>
                {finding.actionPictureSignedUrl &&    <Box>
                  <img
                    src={finding.actionPictureSignedUrl}
                    alt="Action-IMG"
                    style={{ height: 250, objectFit: "contain" }}
                  />
                </Box> }
             
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default InspectionFindingInfo;
