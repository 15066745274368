import { Box, Paper } from "@material-ui/core";
import React from "react";

const BasePaper = ({ children }) => {
  return (
    <Paper elevation={0}>
      <Box p={3}>{children}</Box>
    </Paper>
  );
};

export default BasePaper;
