import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import useNetworkRequest from "../../../utils/useNetworkRequest";
import ActivityCard from "./ActivityCard";
import { getDashboardActivity } from "../../../services/accountService";

const AccountActivityCards = () => {
  const { loading, data, handleRequest } = useNetworkRequest(true);

  useEffect(() => {
    handleRequest(() => getDashboardActivity());
  }, [handleRequest]);

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item>
        <ActivityCard
          name="Checklists"
          loading={loading}
          value={data?.checklistsCount}
        />
      </Grid>
      <Grid item>
        <ActivityCard
          name="Safety Talks"
          loading={loading}
          value={data?.safetyTalksCount}
        />
      </Grid>
      <Grid item>
        <ActivityCard
          name="Incidents"
          loading={loading}
          value={data?.incidentsCount}
        />
      </Grid>
    </Grid>
  );
};

export default AccountActivityCards;
