import React from "react";

import CustomTable from "../../UI/CustomTable";

const FirstAidersTable = ({ firstAiders, projectId }) => {
  //const { data, isLoading, hasError, handleRequest } = useRequestHandler();

  // useEffect(() => {
  //   if (!projectId) return;
  //   const getFirstAiders = handleRequest(getWorkersOnProject(projectId));
  // }, [firstAiders]);

  const tableHead = ["Name", "Contractor", "Title"];

  let tableBody = [];

  if (firstAiders) {
    tableBody = firstAiders.map(worker => {
      const name = `${worker.firstName} ${worker.lastName}`;
      return {
        fields: [name, worker.contractor.name, worker.title],
        key: worker.id,
        onSelected: event => {}
      };
    });
  } else {
  }

  return <CustomTable tableHead={tableHead} tableBody={tableBody} />;
};

export default FirstAidersTable;
