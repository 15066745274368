import React from "react";
import { Button, Box } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const EditButton = ({onClick}) => {
  return (
    <Button
      style={{paddingInline: 36}}
      variant="contained"
      color="primary"
      startIcon={<Edit />}
      onClick={onClick}
    >
      <Box px={2}>
      Edit
      </Box>
     
    </Button>
  );
};

export default EditButton;
