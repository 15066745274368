import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { getWorkersOnProject } from "../../../services/workerService";
import CustomOutlinedSelect from "../../UI/CustomOutlinedSelect";

const useRequestHandler = () => {
  const [workers, setWorkers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  const getWorkersRequest = projectId => {
    setIsLoading(true);
    setHasError(null);

    return getWorkersOnProject(projectId)
      .then(response => {
        setWorkers(response.data.workers);
      })
      .catch(err => {
        setHasError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return { workers, isLoading, hasError, getWorkersRequest };
};

const WorkerSelect = ({ onChange, error, selected, projectId }) => {
  const {
    workers,
    isLoading,
    hasError,
    getWorkersRequest
  } = useRequestHandler();

  useEffect(() => {
    // Dont do request if its null
    if (!projectId) return;

    const workersRequest = getWorkersRequest(projectId);
  }, [projectId]);

  let menuItems;

  if (hasError) {
    menuItems = (
      <MenuItem disabled value="">
        <em>Error Loading Projects</em>
      </MenuItem>
    );
  } else if (workers && !isLoading) {
    if (workers.length === 0) {
      menuItems = <MenuItem disabled value=""> No Safety Coordinators</MenuItem>;
    } else {
      menuItems = workers.map(worker => {
        const label = `${worker.firstName} ${worker.lastName} (${worker.contractor.name})`;
        return (
          <MenuItem key={worker.id} value={worker.id}>
            {label}
          </MenuItem>
        );
      });
    }
  } else {
    menuItems = (
      <MenuItem disabled value="">
        <em>Loading...</em>
      </MenuItem>
    );
  }

  return (
    <CustomOutlinedSelect
      disabled={!projectId}
      label="Safety Coordinator"
      fullWidth
      error={error}
      onChange={onChange}
      selected={selected}
      menuItems={menuItems}
    />
  );
};

export default WorkerSelect;
