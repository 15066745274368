import React, { Component } from 'react';

import Layout from '../../hoc/Layout/Layout';

class Home extends Component {

    render() {

        return (
                <div>
                    <p>Home Page</p>
                </div>
          
        );

    }
}

export default Home;