import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CenterBox from "../../../components/UI/CenterBox";
import * as EquipmentService from "../../../services/equipmentService";
import strings from "../../../strings";

const DeleteEquipmentChecklistDialog = ({ open, onClose, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteChecklistRequest = async () => {
    setLoading(true);
    setError(null);
    try {
      await EquipmentService.deleteEquipmentChecklistById(id);

      setLoading(false);
      return true;
    } catch (err) {
      setError(strings.ERRORS.DEFAULT_ERROR);
      setLoading(false);
    }
  };

  const deleteClickHandler = async () => {
    const result = await deleteChecklistRequest();
    if (result) onClose(result);
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={() => onClose()}>
      <DialogTitle>
        <strong>Delete Equipment Checklist</strong>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Deleting this equipment checklist record will delete all associated
          work assignments.
        </Typography>
        <Typography>
          Are you sure you want to delete this equipment checklist?
        </Typography>
        {!loading && error && (
          <CenterBox marginTop={2}>
            <Typography color="error">{error}</Typography>
          </CenterBox>
        )}
      </DialogContent>
      {loading && (
        <CenterBox marginTop={0}>
          <CircularProgress />
        </CenterBox>
      )}
      <DialogActions>
        {!loading && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={deleteClickHandler}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEquipmentChecklistDialog;
