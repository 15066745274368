import { Box, List, ListItem, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Fragment } from "react";
import CenterBox from "../../../components/UI/CenterBox";
import EquipmentChecklistItem from "./EquipmentChecklistItem";
import EquipmentChecklistItemCategory from "./EquipmentChecklistItemCategory";

const ITEM_CATEGORIES = {
  REAR_END: { category: 0, label: "Rear End" },
  MOTOR_OIL: { category: 0, label: "Motor Oil" },
  AIR_FILTER: { category: 0, label: "Air Filter" },
  RADIATOR: { category: 0, label: "Radiator" },
  BRAKE_FLUID: { category: 0, label: "Brake Fluid" },
  POWER_STEERING: { category: 0, label: "Power Steering" },
  WINDSHIELD_WASHER: { category: 0, label: "Windshield Washer" },
  GREASING_REQUIRED: { category: 0, label: "Greasing Required" },
  OIL_CHANGE_REQUIRED: { category: 0, label: "Oil Change Required?" },
  OIL_FILTER_CHANGED: { category: 0, label: "Oil Filter Changed?" },
  SUN_VISORS: { category: 1, label: "Sun Visors" },
  HORN_AND_SWITCHES: { category: 1, label: "Horn and Switches" },
  STEERING_POWER_ASSIST: { category: 1, label: "Steering Power Assist" },
  WINDSHIELD_WIPERS: { category: 1, label: "Windshield Wipers" },
  SIDE_WINDOWS: { category: 1, label: "Side Windows" },
  PEDAL_PADS: { category: 1, label: "Pedal Pads" },
  SEATS_AND_SEATBELTS: { category: 1, label: "Seats and Seatbelts" },
  SPEEDOMETER: { category: 1, label: "Speedometer" },
  COMPRESSOR_BUILDUPS: { category: 1, label: "Compressor Buildups" },
  AIR_LEAKAGE: { category: 1, label: "Air Leakage" },
  WINDSHIELD_DEFROST: { category: 1, label: "Windshield Defrost" },
  BEAM_INDICATOR: { category: 1, label: "Beam Indicator" },
  FIRE_EXTINGUISHER: { category: 1, label: "Fire Extinguisher" },
  FIRST_AID_KIT: { category: 1, label: "First Aid Kit" },
  SURVIVAL_KIT: { category: 1, label: "Survival Kit" },
  ACC_PEDAL_AND_AIR_THROTTLE: {
    category: 1,
    label: "Acc. Pedal and Air Throttle",
  },
  COMPRESSED_AIR: { category: 1, label: "Compressed Air" },
  WINDSHIELD: { category: 1, label: "Windshield" },
  INSTRUMENT_LAMPS: { category: 1, label: "Instrument Lamps" },
  HAZARD_WARNING_KIT: { category: 1, label: "Hazard Warning Kit/Flares" },
  AIR_PRESSURE_GAUGE: { category: 1, label: "Air Pressure Gauge" },
  CELL_PHONE: { category: 1, label: "Cell Phone" },
  BOOSTER_CABLE: { category: 1, label: "Booster Cable" },
  STEERING_COLUMN_SECURITY: {
    category: 1,
    label: "Steering Column Security",
  },
  HEAD_LAMP_OPERATION: { category: 2, label: "Head Lamp Operation/Aim" },
  TAIL_LAMPS: { category: 2, label: "Tail Lamps" },
  MARKER_LAMPS: { category: 2, label: "Marker Lamps" },
  TRAILER_HITCH: { category: 2, label: "Trailer Hitch" },
  TRAILER_CORD: { category: 2, label: "Trailer Cord" },
  TIRE_PRESSURE: { category: 2, label: "Tire Pressure" },
  GLAD_HANDS_AND_AIR_SYSTEMS: {
    category: 2,
    label: "Glad Hands & Air Systems",
  },
  CLEARANCE_LAMPS: { category: 2, label: "Clearance Lamps" },
  STOP_LAMPS: { category: 2, label: "Stop Lamps" },
  HAZARD_LAMPS: { category: 2, label: "Hazard Lamps" },
  TDG_PLACARDS: { category: 2, label: "TDG Placards" },
  PAINT: { category: 2, label: "Paint" },
  HEADACHE_RACK_OR_CHAIN: {
    category: 2,
    label: "Headache Rack or Chain",
  },
  RESERVOIRS_BRACKETS_STRAPS: {
    category: 2,
    label: "Reservoirs/Brackets/Straps",
  },
  ID_LAMPS: { category: 2, label: "Identification Lamps" },
  TURN_SIGNAL_LAMPS: { category: 2, label: "Turn Signal Lamps" },
  REFLEX_REFLECTORS: { category: 2, label: "Reflex Reflectors" },
  FENDERS_MUD_FLAPS: { category: 2, label: "Fenders/Mub Flaps" },
  AIR_LINES: { category: 2, label: "Air Lines" },
  BODY_AND_DOORS: { category: 2, label: "Body and Doors" },
  BUMPERS_AND_CABS: { category: 2, label: "Bumpers and Cabs" },
  HOOD: { category: 3, label: "Hood" },
  POWER_STEERING_SYSTEM: { category: 3, label: "Power Steering System" },
  AIR_FILTER_UNDER: { category: 3, label: "Air Filter" },
  COOLING_SYSTEM: { category: 3, label: "Cooling System" },
  EXHAUST_SYSTEM: { category: 3, label: "Exhaust System" },
  AIR_COMPRESSOR_BELT: { category: 3, label: "Air Compressor Belt" },
  FUEL_PUMP_AND_SYSTEM: { category: 3, label: "Fuel Pump and System" },
  FAN_AND_BELT: { category: 3, label: "Fan and Belt" },
  WINDSHIELD_WASHER_PUMP: {
    category: 3,
    label: "Windshield Washer Pump",
  },
  WINDSHIELD_WASH_CONTAINER: {
    category: 3,
    label: "Windshield Wash Container",
  },
  AIR_COMPRESSOR: { category: 3, label: "Air Compressor" },
  BATTERY_AND_WIRING: { category: 3, label: "Battery and Wiring" },
  CARBURETOR: { category: 3, label: "Carburetor" },
  DISTRIBUTOR: { category: 3, label: "Distributor" },
  PIN_AND_BUSHING_WEAR: { category: 4, label: "Pin and Bushing Wear" },
  LINK_WEAR: { category: 4, label: "Link Wear" },
  ROLLER_WEAR: { category: 4, label: "Roller Wear" },
  IDLER_WEAR: { category: 4, label: "Idler Wear" },
  TRACK_WEAR: { category: 4, label: "Track Wear" },
  ROLLER_GUARDS: { category: 4, label: "Roller Guards" },
  SPROCKET: { category: 4, label: "Sprocket" },
  SHOCK_ABSORBERS: { category: 4, label: "Shock Absorbers" },
  OIL_PAN: { category: 4, label: "Oil Pan" },
  DRAG_LINK: { category: 4, label: "Drag Link" },
  TIE_ROD: { category: 4, label: "Tie Rod" },
  FRAME_RAILS: { category: 4, label: "Frame Rails" },
  SPRINGS: { category: 4, label: "Springs" },
  MUFFLER: { category: 4, label: "Muffler" },
  PITTMAN_ARM: { category: 4, label: "Pittman Arm" },
  DIFFERENTIAL: { category: 4, label: "Differentail" },
  SUSPENSION: { category: 4, label: "Suspension" },
  AXLES: { category: 4, label: "Axles" },
  BRAKE_COMPONENTS: { category: 5, label: "Brake Components" },
  SPRING_CAGING_BOLTS: { category: 5, label: "Spring Caging Bolts" },
  DISC_BRAKES: { category: 5, label: "Disc Brakes" },
  RESERVOIRS_AND_VALVES: { category: 5, label: "Reservoirs and Valves" },
  WHEEL_BEARINGS: { category: 5, label: "Wheel Bearings" },
  PROPORTIONING_VALVE: { category: 5, label: "Proportioning Valve" },
  BRAKE_CAMSHAFTS_AND_TRAVEL: {
    category: 5,
    label: "Brake Camshafts and Travel",
  },
  TIRE_IRON: { category: 5, label: "Tire Iron" },
  CHOCK_BLOCK: { category: 5, label: "Chock Block" },
  BRAKE_DRUM_CONDITION: { category: 5, label: "Brake Drum Condition" },
  BRAKE_LINES_AND_HOSES: { category: 5, label: "Brake Lines and Hoses" },
  TIRE_PRESSURE_BRAKE: { category: 5, label: "Tire Pressure" },
  VACUUM_SYSTEM: { category: 5, label: "Vacuum System, Reserve" },
  PUMP_OPERATOR: { category: 5, label: "Pump Operator" },
  TIRE_WEAR: { category: 5, label: "Tire Wear" },
  SPARE_TIRE: { category: 5, label: "Spare Tire" },
  ROAD_CLEARANCE: { category: 5, label: "Road Clearance" },
  BRAKE_LINING_THICKNESS: {
    category: 5,
    label: "Brake Lining Thickness",
  },
  BRAKE_FAILURE_INDICATOR: {
    category: 5,
    label: "Brake Failure Indicator",
  },
  PARK_BRAKE: { category: 5, label: "Park Brake" },
  EMERGENCY_BRAKE: { category: 5, label: "Emergency Brake" },
  BRAKE_OPERATION: { category: 5, label: "Brake Operation" },
  JACK: { category: 5, label: "Jack" },
  CHAINS: { category: 5, label: "Chains" },
};

const EquipmentChecklistItems = ({ items }) => {
  console.log("Equipment Checklist Items render!");
  const [itemsWithCategories, setItemsWithCategories] = useState([
    {
      header: "Fluid Levels",
      items: [],
    },
    {
      header: "Driver's Compartment",
      items: [],
    },
    { header: "Body Exterior", items: [] },
    { header: "Under The Hood", items: [] },

    { header: "Undercarriage", items: [] },
    { header: "Brakes, Tires, and Wheels", items: [] },
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const state = [...itemsWithCategories];
    items.forEach((item) => {
      if (state[ITEM_CATEGORIES[item.name].category] === undefined)
        console.log(item.name);
      state[ITEM_CATEGORIES[item.name].category].items.push({
        ...item,
        label: ITEM_CATEGORIES[item.name].label,
      });
    });
    console.log(state);
    setItemsWithCategories(state);
    setLoading(false);
  }, []);

  return (
    <Paper elevation={0}>
      {loading && (
        <CenterBox marginTop={0}>
          <Typography>Loading...</Typography>
        </CenterBox>
      )}
      {!loading &&
        itemsWithCategories.map((category) => {
          return (
            <Fragment key={category.header}>
              <EquipmentChecklistItemCategory
                key={category.header}
                header={category.header}
              />
              {category.items.map((item) => {
                return <EquipmentChecklistItem key={item.name} item={item} />;
              })}
            </Fragment>
          );
        })}
    </Paper>
  );
};

export default EquipmentChecklistItems;
