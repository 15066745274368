import React, { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import EmergencyNumberForm from "../../../components/EmergencyNumbers/EmergencyNumber/EmergencyNumberForm";
import {
  postEmergencyNumber,
  putEmergencyNumber,
  getENumberById,
  getEmergencyNumbersFormTemplate,
} from "../../../services/emergencyNumberService";
import SelectFirstAidersDialog from "../../../components/EmergencyNumbers/SelectFirstAidersDialog";
import HospitalsDialog from "../../../components/EmergencyNumbers/EmergencyNumber/HospitalsDialog";

const EmergencyNumberFormScreen = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [eNumberState, setENumberState] = useState(null);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hospitalsDialogOpen, setHospitalsDialogOpen] = useState(false);

  const onTextChangeHandler = (event, id) => {
    const updatedENumberState = {
      ...eNumberState,
      [id]: {
        value: event.target.value,
      },
    };

    // Since the project is changing, clear the coordinator
    if (id === "project") {
      updatedENumberState.coordinator.value = "";
    }

    console.log(event.target.value);

    setENumberState(updatedENumberState);
  };

  const onNumberChangeHandler = (event, id) => {
    const updatedNumbers = [...eNumberState.emergencyNumbers];

    // Find number that is being changed
    updatedNumbers.forEach((number) => {
      if (number.name === id) {
        number.primaryNumber = event.target.value;
      }
    });

    const updatedENumberState = {
      ...eNumberState,
      emergencyNumbers: updatedNumbers,
    };

    setENumberState(updatedENumberState);
  };

  const onAddFirstAidersClickHandler = () => {
    setDialogOpen(true);
  };

  const onDialogSaveClickHandler = (firstAiders) => {
    const updatedENumberState = { ...eNumberState, firstAiders: firstAiders };

    setENumberState(updatedENumberState);
    setDialogOpen(false);
  };

  const onCreateHospitalClickHandler = () => {
    setHospitalsDialogOpen(true);
  };

  const onSaveClickHandler = () => {
    let hasError;
    const updatedENumberState = {
      ...eNumberState,
    };

    if (!eNumberState.hospital.value) {
      updatedENumberState.hospital.error = true;
      hasError = true;
    }

    if (!eNumberState.project.value) {
      updatedENumberState.project.error = true;
      hasError = true;
    }

    if (!eNumberState.coordinator.value) {
      updatedENumberState.coordinator.error = true;
      hasError = true;
    }

    setENumberState(updatedENumberState);

    if (hasError) return;

    const requestEntity = {
      emergencyNumbers: updatedENumberState.emergencyNumbers,
      nearestHospitalId: updatedENumberState.hospital.value,
      projectId: updatedENumberState.project.value,
      coordinatorId: updatedENumberState.coordinator.value,
      firstAiders: updatedENumberState.firstAiders.map(
        (firstAider) => firstAider.id
      ),
    };

    if (id) {
      putEmergencyNumber(requestEntity, id)
        .then((response) => {
          const eNumberId = response.data.id;
          history.replace(
            `/projects/${requestEntity.projectId}/emergency-numbers`
          );
        })
        .catch((err) => {});
    } else {
      postEmergencyNumber(requestEntity)
        .then((response) => {
          const eNumberId = response.data.id;
          history.replace(
            `/projects/${requestEntity.projectId}/emergency-numbers`
          );
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    setIsLoadingState(true);

    // Edit mode
    if (id) {
      getENumberById(id).then((response) => {
        const responseJson = response.data;
        const updatedENumberState = {
          hospital: {
            value: responseJson.hospital.id || "",
            error: false,
          },
          project: {
            value: responseJson.project.id || "",
            error: false,
          },
          coordinator: {
            value: responseJson.coordinator.id || "",
            error: false,
          },
          firstAiders: responseJson.firstAiders,
          emergencyNumbers: responseJson.emergencyNumbers,
        };

        setENumberState(updatedENumberState);
        setIsLoadingState(false);
      });
    } else {
      getEmergencyNumbersFormTemplate().then((response) => {
        const historyState = history.location.state;

        let projectIdParam;
        if (historyState) {
          projectIdParam = history.location.state.projectId;
        }

        const updatedENumberState = {
          name: {
            value: "",
            error: false,
          },
          number: {
            value: "",
            error: false,
          },
          address: {
            value: "",
          },
          hospital: {
            value: "",
            error: false,
          },
          project: {
            value: projectIdParam || "",
            error: false,
          },
          coordinator: {
            value: "",
            error: false,
          },
          firstAiders: [],
          emergencyNumbers: response.data.emergencyNumbers,
        };
        setENumberState(updatedENumberState);
        setIsLoadingState(false);
      });
    }
  }, []);

  return (
    <Container>
      {eNumberState && !isLoadingState ? (
        <Fragment>
          <SelectFirstAidersDialog
            open={dialogOpen}
            projectId={eNumberState.project.value}
            onClose={() => setDialogOpen(false)}
            onSaveClick={onDialogSaveClickHandler}
            selectedWorkerList={eNumberState.firstAiders}
          />
          <HospitalsDialog
            open={hospitalsDialogOpen}
            onClose={() => {
              setHospitalsDialogOpen(false);
            }}
            onSaveButtonClick={() => {
              setHospitalsDialogOpen(false);
            }}
          />
          <EmergencyNumberForm
            eNumberData={eNumberState}
            onTextChange={onTextChangeHandler}
            onNumberChange={onNumberChangeHandler}
            onSaveClick={onSaveClickHandler}
            onAddFirstAidersClick={onAddFirstAidersClickHandler}
            onCreateHospitalClick={onCreateHospitalClickHandler}
            hospitalsRefresh={hospitalsDialogOpen}
            edit={id ? true : false}
          />
        </Fragment>
      ) : null}
    </Container>
  );
};

export default EmergencyNumberFormScreen;
