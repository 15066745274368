import axios from '../axios';

export const download = (type) => {
    return axios.get('/downloads?module=checklists&id=2&filename=2_safety_signature.png');
}

export const downloadWithResourceUrl = (url) => {
    return axios.get(url, {
        responseType: 'blob'
    });
}