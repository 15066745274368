import React, { Component, useState, useEffect } from "react";
import {
  makeStyles,
  ListItem,
  ListItemText,
  List,
  Container,
  Typography,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableBody,
  Button,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import strings from "../../strings";
import { useHistory } from "react-router-dom";
import { getAllProjects } from "../../services/projectService";
import CenterBox from "../../components/UI/CenterBox";
import { AddCircle, Warning, CheckCircle } from "@material-ui/icons";
import { orange, green } from "@material-ui/core/colors";

const Projects = (props) => {
  const history = useHistory();
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const projectRowClickHandler = (event, id) => {
    history.push("/projects/" + id);
  };

  const createProjectButtonClickHandler = () => {
    history.push("/projects/create");
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    getAllProjects()
      .then((response) => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  let dataDisplay;

  if (loading || error || !projects) {
    dataDisplay = (
      <CenterBox p={4}>
        {error ? <Typography>Error Occurred</Typography> : <CircularProgress />}
      </CenterBox>
    );
  } else {
    dataDisplay = (
      <ProjectsCard
        projects={projects}
        loading={loading}
        error={error}
        projectRowClickHandler={projectRowClickHandler}
      />
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Grid container direction="column">
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold">Projects</Box>
              </Typography>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddCircle />}
                onClick={createProjectButtonClickHandler}
              >
                <Box px={2}>New Project</Box>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Box py={2}>
              <Paper>{dataDisplay}</Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const ProjectsCard = ({ projects, loading, error, projectRowClickHandler }) => {
  let dataDisplay;

  if (loading || error || !projects) {
    dataDisplay = (
      <CenterBox>
        {error ? <Typography>Error Occurred</Typography> : <CircularProgress />}
      </CenterBox>
    );
  } else if (projects.length === 0) {
    dataDisplay = (
      <TableRow>
        <TableCell>
          <Typography>No Projects to Display</Typography>
        </TableCell>
      </TableRow>
    );
  } else {
    dataDisplay = projects.map((project) => (
      <TableRow key={project.id} hover>
        <TableCell component="th" scope="row">
          {project.name}
        </TableCell>
        <TableCell align="left">{project.startDate}</TableCell>
        <TableCell align="left">{project.endDate}</TableCell>
        <TableCell align="left">{project.clientName}</TableCell>
        <TableCell align="left">
          {strings.PROJECT_STATES[project.state - 1]}
        </TableCell>
        <TableCell align="left">
          {project.projectStatus === 0 ? <CheckCircle style={{color: green[500]}}/> : <Warning style={{color: orange[600]}}/>}
        </TableCell>
        <TableCell align="right">
          <Button
            color="primary"
            variant="outlined"
            onClick={(event) => {
              projectRowClickHandler(event, project.id);
            }}
          >
            <Box px={2}>View</Box>
          </Button>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Project Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Start Date</strong>
            </TableCell>
            <TableCell align="left">
              <strong>End Date</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Client Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>State</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Status</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{dataDisplay}</TableBody>
      </Table>
    </Paper>
  );
};

export default Projects;
