import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";

const SearchBox = ({ onChange, value, label, placeholder }) => {
  return (
    <TextField
      type="search"
      label={label}
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" c>
            <Search color="primary" />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      value={value}
    />
  );
};

export default SearchBox;
