import React, { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ListItem,
  List,
  CircularProgress,
  LinearProgress
} from "@material-ui/core";

const SelectContractorsDialog = props => {
  if (!props.contractorsList) {
    return (<Fragment />);
  }
  const listItems = props.contractorsList.map(
    contractor => {
      return (
        <ListItem key={contractor.id} button onClick={(event) => props.onContractorSelectedHandler(contractor)}>
          {contractor.name}
        </ListItem>
      );
    }
  );

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Select Contractor</DialogTitle>
      <DialogContent>
        {props.loading ? <LinearProgress /> : <List>{listItems}</List>}
      </DialogContent>
    </Dialog>
  );
};

export default SelectContractorsDialog;
