import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@material-ui/core";

const CustomTable = props => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {props.tableHead.map(headerName => (
            <TableCell key={headerName}>
              <strong>{headerName}</strong>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.tableBody.length === 0 ? (
          <TableRow>
            <TableCell>
              <Typography><em>No records to display.</em></Typography>
            </TableCell>
          </TableRow>
        ) : (
          props.tableBody.map(tableObject => {
            return (
              <TableRow
                key={tableObject.key}
                onClick={tableObject.onSelected}
                hover
              >
                {tableObject.fields.map((objectField, index) => {
                  const key = `${tableObject.key}.${index}`;
                  return <TableCell key={key}>{objectField}</TableCell>;
                })}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
