import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const CustomBreadcrumbs = ({ name }) => {
  const history = useHistory();

  const equipmentClickHandler = (event) => {
    event.preventDefault();
    history.replace("/equipment");
  };

  return (
    <Breadcrumbs separator={<Typography variant="h5">/</Typography>}>
      <Link href="/equipment" onClick={equipmentClickHandler}>
        <Typography variant="h5">
          <strong>Equipment</strong>
        </Typography>
      </Link>
      <Typography color="textPrimary" variant="h5">
        <strong>{name}</strong>
      </Typography>
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
