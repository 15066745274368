import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox
} from "@material-ui/core";
import useRequestHandler from "../../utils/requestHandler";
import { getWorkersOnProject } from "../../services/workerService";

const SelectFirstAidersDialog = ({
  open,
  onClose,
  onSaveClick,
  selectedWorkerList,
  projectId
}) => {
  const [firstAiders, setFirstAiders] = useState(null);
  const { data, isLoading, hasError, handleRequest } = useRequestHandler();

  const onWorkerSelectedHandler = (event, worker) => {
    const updatedFirstAiders = [...firstAiders];

    for (const firstAider of updatedFirstAiders) {
      if (firstAider.id === worker.id) {
        firstAider.isSelected = event.target.checked;
      }
    }

    setFirstAiders(updatedFirstAiders);
  };

  const onSaveButtonClickHandler = () => {
    if (!firstAiders) return;
    //const firstAidersList = [...firstAiders];
    const newFirstAiders = [];

    for (const firstAider of firstAiders) {
      console.log(firstAider);
      if (firstAider.isSelected) {
        newFirstAiders.push(firstAider);
      }
    }

    console.log(newFirstAiders);

    onSaveClick(newFirstAiders);
  };

  useEffect(() => {
    if (!projectId || !open) return;

    setFirstAiders(null);
    const getWorkersRequest = handleRequest(getWorkersOnProject(projectId));
  }, [projectId, open]);

  let workersTableList;

  if (hasError) {
  } else if (data && !isLoading && !firstAiders) {
    const updatedFirstAiders = data.map(worker => {
      worker.isSelected = false;

      selectedWorkerList.forEach(selectedWorker => {
          if (selectedWorker.id === worker.id) {
            worker.isSelected = true;
          }
      });
      return worker;
    });

    setFirstAiders(updatedFirstAiders);

    //   data.forEach(worker => {
    //       worker.isSelected = false;

    //       selectedWorkerList.forEach(selectedWorker => {
    //           if (selectedWorker.id === worker.id) {
    //             worker.isSelected = true;
    //           }
    //       })
    //   });

    //   selectedWorkerList.forEach(selectWorker => {

    //   })
  } else {
  }

  if (firstAiders) {
    workersTableList = data.map(worker => {
      return (
        <TableRow key={worker.id}>
          <TableCell>
            <Checkbox
              checked={worker.isSelected}
              onChange={event => onWorkerSelectedHandler(event, worker)}
              value={true}
            />
          </TableCell>
          <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>
          <TableCell>{worker.contractor.name}</TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select First Aiders</DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Worker Name</TableCell>
              <TableCell>Contractor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{workersTableList}</TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSaveButtonClickHandler} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectFirstAidersDialog;
