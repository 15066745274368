import React from 'react';
import { Paper, Typography, Box, Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const ContractorDetail = ({ contractor }) => {
  const address = contractor.address
    ? contractor.address.addressLabel || ''
    : '';
  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="caption">Name</Typography>
            <Typography variant="h6">{contractor.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Description</Typography>
            <Typography variant="h6">{contractor.description}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Email</Typography>
            <Typography variant="h6">{contractor.email}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Office Phone</Typography>
            <NumberFormat
              format="###-###-####"
              value={contractor.officePhone}
              displayType="text"
              renderText={(text) => (
                <Typography variant="h6">{text}</Typography>
              )}
            />
          </Grid>
          <Grid item>
            <Typography variant="caption">Address</Typography>
            <Typography variant="h6">{address}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ContractorDetail;
