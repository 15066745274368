import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import RatingBox from "./RatingBox";

const Hazards = ({ hazards = [] }) => {
  return (
    <Table style={{ border: "1px solid #d3d3d3" }}>
      <TableHead style={{ backgroundColor: "#d3d3d3" }}>
        <TableRow>
          <TableCell>
            <strong>Potential Hazards</strong>
          </TableCell>
          <TableCell>
            <strong>Risks</strong>
          </TableCell>
          <TableCell width="10%">
            <strong>Risk Rating</strong>
          </TableCell>
          <TableCell>
            <strong>Controls</strong>
          </TableCell>
          <TableCell width="10%">
            <strong>Residual Risk</strong>
          </TableCell>
          <TableCell>
            <strong>Monitor and Review</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {hazards.map((hazard) => {
          return (
            <TableRow key={hazard.id}>
              <TableCell>{hazard.name}</TableCell>
              <TableCell>{hazard.risk}</TableCell>
              <TableCell>
                <RatingBox
                  severity={hazard.riskSeverity}
                  probability={hazard.riskProbability}
                  frequency={hazard.riskFrequency}
                />
              </TableCell>
              <TableCell>{hazard.controls}</TableCell>
              <TableCell>
                <RatingBox
                  severity={hazard.resRiskSeverity}
                  probability={hazard.resRiskProbability}
                  frequency={hazard.resRiskSeverity}
                />
              </TableCell>
              <TableCell>{hazard.review}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default Hazards;
