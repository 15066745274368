import axios from "../axios";

export const login = (email, password) => {
  return axios.post("auth/login", {
    email,
    password: password,
  });
};

export const changePassword = ({ currentPass, newPass, confirmPass }) => {
  return axios.post("auth/change-password", {
    currentPass,
    newPass,
    confirmPass,
  });
};

export const requestPasswordReset = (email) => {
  return axios.post("auth/forgot-password", {
    email,
  });
};

export const validatePasswordResetToken = (token) => {
  return axios.get("auth/reset", { params: { token } });
};

export const makePasswordReset = ({ token, password, confirmPassword }) => {
  return axios.post(
    "auth/password-reset",
    { password, confirmPassword },
    {
      params: {
        token,
      },
    }
  );
};
