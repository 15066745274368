import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Container, Typography, Button, Paper, Box } from "@material-ui/core";
import WorkersTable from "../../components/Workers/WorkersTable";

class Workers extends Component {
  componentDidMount() {
    this.props.onGetWorkers();
  }

  onWorkerViewClickHandler = (event, id) => {
    this.props.history.push(`/workers/${id}`);
  };

  onCreateEmployeeClickHandler = event => {
    this.props.history.push("/workers/create");
  };

  render() {
    let workersTable = null;
    if (!this.props.loading && !this.props.error) {
      workersTable = (
        <WorkersTable
          workers={this.props.workers}
          workerViewClicked={this.onWorkerViewClickHandler}
        />
      );
    }

    return (
      <Container maxWidth="xl">
        <Typography variant="h4">Employees</Typography>
        <Box pb={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onCreateEmployeeClickHandler}
          >
            New
          </Button>
        </Box>
        <Paper>{workersTable}</Paper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    workers: state.worker.workers,
    loading: state.worker.loading,
    error: state.worker.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetWorkers: () => dispatch(actions.getAllWorkers())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workers);
