import React, { useState, useContext } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { loginAsSuperAdmin } from "../../../services/superAdminService";
import { AuthContext } from "../../../context/auth-context";

import axios from "../../../axios";

const updateAuthToken = (authContext, data) => {
  const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
  const { token, id } = data;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem("token", token);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem(
    "userData",
    JSON.stringify({ type: "ADMIN", userId: id })
  );

  authContext.login(null, "ADMIN", null, token, expirationDate, id);
};

const SuperAdminLoginScreen = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [fieldState, setFieldState] = useState({
    email: {
      value: "",
      error: null,
    },
    password: {
      value: "",
      error: null,
    },
  });
  const [loading, setLoading] = useState(false);

  const textChangeHandler = (event, id) => {
    const updatedState = {
      ...fieldState,
      [id]: {
        value: event.target.value,
      },
    };

    setFieldState(updatedState);
  };

  const onLoginClick = () => {
    const state = {
      ...fieldState,
    };
    let hasError = false;

    if (!state.email.value) {
      state.email.error = true;
      hasError = true;
    }

    if (!state.password.value) {
      state.password.error = true;
      hasError = true;
    }

    if (hasError) {
      setFieldState(state);
      return;
    }

    setLoading(true);

    loginAsSuperAdmin(state.email.value, state.password.value)
      .then((response) => {
        const { data } = response;

        updateAuthToken(authContext, data);
        history.push("accounts");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="stretch"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Paper>
            <Box p={2}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>Rebar Superadmin Login Area</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    label="email"
                    fullWidth
                    required
                    variant="outlined"
                    onChange={(event) => textChangeHandler(event, "email")}
                    value={fieldState.email.value}
                    error={fieldState.email.error}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="password"
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    onChange={(event) => textChangeHandler(event, "password")}
                    value={fieldState.password.value}
                    error={fieldState.password.error}
                  />
                </Grid>
                <Grid item>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onLoginClick}
                    >
                      Login As Superadmin
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SuperAdminLoginScreen;
