import React from "react";
import { Paper, Box, Grid, Typography, Link } from "@material-ui/core";

const CertificationDetails = ({
  certification,
  viewFileClick,
  ownerLinkClick
}) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption">Name</Typography>
            <Typography variant="h6">{certification.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Owner</Typography>
            <Typography variant="h6">
              {certification.owner ? (
                <Link href="#" onClick={ownerLinkClick}>
                  {certification.owner.name}
                </Link>
              ) : null}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Required</Typography>
            <Typography variant="h6">
              {certification.required ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Active</Typography>
            <Typography variant="h6">
              {certification.active ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Issued Date</Typography>
            <Typography variant="h6">{certification.issuedDate}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Expire Date</Typography>
            <Typography variant="h6">{certification.expireDate}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">File Name</Typography>
            <Typography variant="h6">
              <Link
                href="#"
                onClick={event =>
                  viewFileClick(
                    event,
                    certification.certificationUrl,
                    certification.filename
                  )
                }
              >
                {certification.filename}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CertificationDetails;
