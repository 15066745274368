import React, { useCallback, useMemo, useState } from "react";
import strings from "../strings";

/*
 *
 *  Handles network requests and state regarding the request
 *
 */
const useNetworkRequest = (initLoading = false) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(initLoading);
  const [error, setError] = useState(null);

  const handleRequest = useMemo(
    () => async (request) => {
      setLoading(true);
      setError(null);

      try {
        const response = await request();

        setData(response.data);
        setLoading(false);

        return response.data || true;
      } catch (err) {
        setError(strings.ERRORS.DEFAULT_ERROR);
        setLoading(false);
      }
      return null;
    },
    []
  );

  return { data, loading, error, handleRequest, setLoading };
};

export default useNetworkRequest;
