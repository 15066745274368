import React from "react";
import { Paper, Typography, Box, Link } from "@material-ui/core";
import strings from "../../../strings";
import { formatToDisplayDate } from "../../../utils/util";

const SafetyTalkDetails = props => {
  return (
    <Paper>
      <Box bgcolor="primary.main">
        <br />
      </Box>
      <Box mx={2}>
        <Typography variant="h5">Info</Typography>
      </Box>

      <Box m={2}>
        <Typography variant="h5">Name</Typography>
        <Typography>{props.safetyTalk.name}</Typography>
        <br />
        <Typography variant="h5">State</Typography>
        <Typography>
          {strings.SAFETY_TALK_STATES[props.safetyTalk.state]}
        </Typography>
        <br />
        <Typography variant="h5">Date Created</Typography>
        <Typography>{formatToDisplayDate(props.safetyTalk.createdDate)}</Typography>
        <br />
        <Typography variant="h5">Resource</Typography>
        <Typography>
          {" "}
          {props.safetyTalk.resource && <Link
            href="#"
            onClick={event =>
              props.resourceLinkClick(event,  props.safetyTalk.resource.id)
            }
          >
            {props.safetyTalk.resource.name}
          </Link>}
          
        </Typography>
      </Box>
    </Paper>
  );
};

export default SafetyTalkDetails;
