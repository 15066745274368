import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { formatToDisplayDate } from "../../../utils/util";
import {
  getIncidentById,
  updateIncidentById,
} from "../../../services/incidentService";
import { useHistory, useParams } from "react-router-dom";
import CenterBox from "../../../components/UI/CenterBox";
import SaveButton from "../../../components/UI/SaveButton";
import CustomOutlinedSelect from "../../../components/UI/CustomOutlinedSelect";

const IncidentForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [incident, setIncident] = useState();
  const [loading, setLoading] = useState(false);

  const onTextChangeHandler = (event, id) => {
    const updatedData = {
      ...incident,
      [id]: event.target.value,
    };

    setIncident(updatedData);
  };

  const onChangeStateHandler = (event) => {
    const updatedData = {
      ...incident,
      state: event.target.value,
    };

    setIncident(updatedData);
  };

  useEffect(() => {
    setLoading(true);
    getIncidentById(id)
      .then((response) => {
        setIncident(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const saveButtonClickHandler = () => {
    setLoading(true);

    const incidentData = {
      action: "ADDITIONAL_INFO",
      data: {
        additionalInfo: {
          hospitalAttended: incident.hospitalAttended,
          physicianName: incident.physicianName,
          notes: incident.notes,
          state: incident.state,
        },
      },
    };

    updateIncidentById({ id, incidentData })
      .then((response) => {
        history.replace(`/incidents/${id}`);
      })
      .catch((err) => {
        //
        setLoading(false);
      });
  };

  if (loading || !incident) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }
  return (
    <Container maxWidth="lg">
      <Box py={2}>
        <Typography variant="h4">
          <strong>Edit Incident</strong>
        </Typography>
        <Paper>
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="caption">Worker Involved</Typography>
                <Typography variant="h6">
                  {`${incident.workerInvolved.firstName} ${incident.workerInvolved.lastName}`}
                </Typography>
              </Grid>
              <Grid item lg={4} md={4}>
                <CustomOutlinedSelect
                  label="State"
                  fullWidth
                  onChange={onChangeStateHandler}
                  selected={incident.state || 0}
                  menuItems={[
                    <MenuItem value={0}>Reported</MenuItem>,
                    <MenuItem value={1}>Reviewed / Completed</MenuItem>,
                  ]}
                />
              </Grid>
              <Grid item>
                <Typography variant="caption">Reported By</Typography>
                <Typography variant="h6">{`${incident.reportedBy.firstName} ${incident.reportedBy.lastName}`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Date and Time of Incident
                </Typography>
                <Typography variant="h6">
                  {`${formatToDisplayDate(incident.incidentDate)}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Date and Time Last Worked
                </Typography>
                <Typography variant="h6">
                  {`${formatToDisplayDate(incident.lastWorkedDate)}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Date and Time Reported
                </Typography>
                <Typography variant="h6">
                  {`${formatToDisplayDate(incident.reportedDate)}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Were any sub-contractors involved
                </Typography>
                <Typography variant="h6">
                  {incident.subContractorsInvolved ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">MOL called</Typography>
                <Typography variant="h6">
                  {incident.MOLCalled ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">MOL Rep</Typography>
                <Typography variant="h6">{incident.MOLRep}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Location</Typography>
                <Typography variant="h6">{incident.location}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Cause of Injury</Typography>
                <Typography variant="h6">{incident.description}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Worker's Activities</Typography>
                <Typography variant="h6">
                  {incident.workerActivitiesAtTime}
                </Typography>
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Hospital Attended"
                  fullWidth
                  variant="outlined"
                  value={incident.hospitalAttended}
                  onChange={(event) =>
                    onTextChangeHandler(event, "hospitalAttended")
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Physician Name"
                  fullWidth
                  variant="outlined"
                  value={incident.physicianName}
                  onChange={(event) =>
                    onTextChangeHandler(event, "physicianName")
                  }
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  label="Notes"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="5"
                  value={incident.notes}
                  onChange={(event) => onTextChangeHandler(event, "notes")}
                />
              </Grid>
              <Grid item>
                <hr />
              </Grid>
              <Grid item>
                <SaveButton onClick={saveButtonClickHandler} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default IncidentForm;
