import React, { useState, useEffect } from "react";
import { getAllWorkers } from "../../services/workerService";
import SelectWorkersDialog from "./SelectWorkersDialog";

const SelectWorkers = props => {
  // const [showDialog, setShowDialog] = useState(false);
  const [workers, setWorkers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onWorkerSelectedHandler = (event, worker) => {
    const updatedWorkerState = [...workers];

    for (const wkr of updatedWorkerState) {
      if (wkr.id === worker.id) {
        wkr.isSelected = event.target.checked;
      }
    }

    setWorkers(updatedWorkerState);
  };

  const onSaveClickHandler = () => {
    const workerIds = [];

    for (const worker of workers) {
      if (worker.isSelected) {
        workerIds.push(worker.id);
      }
    }

    props.onSave(workerIds);
  };

  useEffect(() => {
    getAllWorkers()
      .then(response => {
        const projectWorkers = props.projectWorkers;
        const responseData = response.data.workers;

        // Ugly, but finds which workers are on the project currently to find the difference for checkboxes
        for (const worker of responseData) {
          worker.isSelected = false;

          for (const projectWorker of projectWorkers) {
            if (projectWorker.id === worker.id) {
              worker.isSelected = true;
            }
          }
        }
        setWorkers(responseData);
      })
      .catch(err => {});
  }, [props.open]);

  return (
    <SelectWorkersDialog
      workersList={workers}
      open={props.open}
      onClose={props.onClose}
      onWorkerSelected={onWorkerSelectedHandler}
      onSaveClick={onSaveClickHandler}
    />
  );
};

export default SelectWorkers;
