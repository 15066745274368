import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import CenterBox from "../../components/UI/CenterBox";
import NewButton from "../../components/UI/NewButton";
import MainViewContainer from "../../hoc/MainViewContainer";
import EquipmentTable from "./components/EquipmentTable";
import SearchBox from "./components/SearchBox";
import useEquipment from "./hooks/useEquipment";
import Strings from "../../strings";
import EquipmentFormDialog from "./components/EquipmentFormDialog";
import { useHistory } from "react-router-dom";

const Equipment = () => {
  const {
    equipment,
    loading,
    error,
    updateNameQuery,
    updateRowsPerPage,
    updatePage,
    updateDialogForm,
    dialogFormOpen,
    total,
    rowsPerPage,
    page,
  } = useEquipment();
  const history = useHistory();

  const equipmentSelectedHandler = (id) => {
    history.push(`/equipment/${id}`);
  };

  const searchBoxChangeHandler = (event) => {
    updateNameQuery(event.target.value);
  };

  const rowsPerPageChangeHandler = (event) => {
    updateRowsPerPage(parseInt(event.target.value), 10);
  };

  const changePageHandler = (event, newPage) => {
    updatePage(newPage);
  };

  const createEquipmentClickHandler = (event) => {
    updateDialogForm(true);
  };

  const dialogCloseHandler = (formResult) => {
    if (formResult) {
      history.push(`/equipment/${formResult.id}`);
      return;
    }
    updateDialogForm(false);
  };

  let paperBody;
  if (loading) {
    paperBody = (
      <CenterBox marginTop={4}>
        <CircularProgress />
      </CenterBox>
    );
  } else if (error) {
    paperBody = (
      <CenterBox marginTop={4}>
        <Typography>{Strings.ERRORS.DEFAULT_ERROR}</Typography>
      </CenterBox>
    );
  } else if (!loading && equipment.length === 0) {
    paperBody = (
      <CenterBox marginTop={4}>
        <Typography>No equipment to display.</Typography>
      </CenterBox>
    );
  }

  return (
    <MainViewContainer>
      {dialogFormOpen && (
        <EquipmentFormDialog
          open={dialogFormOpen}
          onCloseHandler={dialogCloseHandler}
        />
      )}
      <Grid container spacing={3} direction="column">
        <Grid item container alignItems="flex-end" justify="space-between">
          <Grid item>
            <Typography variant="h5">
              <strong>Equipment</strong>
            </Typography>
          </Grid>
          <Grid item>
            <NewButton onClick={createEquipmentClickHandler}>
              Create Equipment
            </NewButton>
          </Grid>
        </Grid>
        <Grid item>
          <Paper elevation={0}>
            <Box p={4}>
              <Grid container direction="column" spacing={3}>
                <Grid item lg={6}>
                  <SearchBox
                    onChange={searchBoxChangeHandler}
                    label="Search Equipment"
                    placeholder="Search Equipment by Name"
                  />
                </Grid>
                <Grid item>
                  <EquipmentTable
                    loading={loading}
                    equipment={equipment}
                    rowSelected={equipmentSelectedHandler}
                    changeRowsPerPageHandler={rowsPerPageChangeHandler}
                    changePageHandler={changePageHandler}
                    count={total}
                    page={page}
                    rowsPagePage={rowsPerPage}
                  />
                  {paperBody}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </MainViewContainer>
  );
};

export default Equipment;
