import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Contractors from "../Contractors";
import Contractor from "./Contractor";
import CreateContractorForm from "../../../components/Contractors/Contractor/CreateContractorForm";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import EditContractor from "../../../components/Contractors/Contractor/EditContractor";

const ContractorContainer = props => {
  const { path } = useRouteMatch();
  let contractorView = null;
  contractorView = (
    <Switch>
      <Route path={`${path}/create`} component={CreateContractorForm} />
      <Route path={`${path}/:id/edit`} component={EditContractor} />
      <Route path={`${path}/:id`} component={Contractor} />
      <Route exact path={path} component={Contractors} />
    </Switch>
  );

  return contractorView;
};

// const ViewContractor = props => {
//   return (
//     <Paper>
//       <Box>
//         <Grid container>
//           <Grid item>
//             <Typography>View Contractor</Typography>
//           </Grid>
//           <Grid item>
//               <Contractor {props.children}/>
//           </Grid>
//         </Grid>
//       </Box>
//     </Paper>
//   );
// };

export default ContractorContainer;
