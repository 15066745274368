import { Box, Tooltip, Typography } from "@material-ui/core";
import React from "react";

const RatingBox = ({ frequency, probability, severity }) => {
  const textStyle = {
    color: "white",
  };
  const riskRating =
    severity == null || frequency == null || probability == null
      ? "N / A"
      : probability * frequency * severity;

  let boxColor;
  if (!isNaN(riskRating)) {
    if (riskRating >= 0 && riskRating < 20) {
      boxColor = "green";
    } else if (riskRating >= 20 && riskRating < 40) {
      boxColor = "orange";
    } else {
      boxColor = "red";
    }
  }

  return (
    <Box
      onClick={() => {}}
      py={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundColor: boxColor,
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <Typography style={textStyle}>
        <strong>{riskRating}</strong>
      </Typography>
    </Box>
  );
};

export default RatingBox;
